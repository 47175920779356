import {
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  InputLabel,
  makeStyles,
  Snackbar,
  TextField,
  Typography,
  Grid
} from '@material-ui/core'
import Modal from '@material-ui/core/Modal'
import MUIDataTable from 'mui-datatables'
import { Alert } from '@material-ui/lab'
import { authActions } from 'auth'
import { generateToken, tokenList } from 'auth/actions'
import { UiActions } from 'globalUI'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import moment from 'moment'
import setAuthorizationToken from 'utils/axios-instance'
import { Link, useHistory } from 'react-router-dom'

// import { DataGrid } from "@material-ui/data-grid";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(2),
    textTransform: 'none'
  },
  darkText: {
    color: theme.palette.grey[700]
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,

    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}))

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  }
}

const API = (props) => {
  const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTableToolbar: {
          actions: {
            display: 'none'
          }
        },
        MuiTableCell: {
          root: {
            padding: '6px'
          }
        },
        MuiPaper: {
          root: {
            width: '100%',
            marginTop: '20px'
          }
        },
        MuiInputBase: {
          formControl: {
            '&:hover': {
              outline: 'none'
            }
          }
        },
        MuiButtonBase: {
          root: {
            backgroundColor: 'rgb(63, 81, 181)',

            fontWeight: '500',
            fontSize: '.875rem',

            lineHeight: '1'
          }
        },
        MuiButton: {
          root: {
            textTransform: 'capitalize',
            margin: '1rem 0',
            '&:hover': {
              backgroundColor: 'rgb(63, 81, 181)'
            }
          },

          text: {
            color: '#fff'
          }
        }
      }
    })

  const classes = useStyles()

  const [copySuccess, setCopySuccess] = useState('')
  const history = useHistory()

  // const [copied, setCopied] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false)
  const [successRevoke, setSuccessRevoke] = useState(false)
  const [deleteAlert, setDeleteAlert] = useState(false)
  const [apiGenerated, setApiGenerated] = useState(false)
  const [error, setError] = useState(false)
  const [tokenName, setTokenName] = useState('')
  const [open, setOpen] = useState(false)

  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle)
  const handleGenerate = (e) => {
    // e.preventDefault();
    if (tokenName === '' && tokenName === undefined) {
      alert('hello')
      setError(true)
    } else {
      // console.log(tokenName);
      props.generateToken({
        token_name: tokenName
      })
      setTimeout(() => {
        props.tokenListCall()
        setApiGenerated(false)
      }, 250)
      history.push('/token-lists')
    }
    // console.log(props.tokenList);
  }
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const body = (
    <div style={modalStyle} className={classes.paper}>
      <Grid container justify='center'>
        <Typography variant='h6' color='textPrimary'>
          Create your token here
        </Typography>
      </Grid>

      <Box my={4} width={'100%'} display='flex' flexDirection='column' style={{ gap: 10 }}>
        <InputLabel className={classes.darkText} htmlFor='Token Name'>
          <br />
          {/* Authorized IPs <small>(separated by Commas ",")</small> */}
          Token Name
        </InputLabel>
        <TextField
          size='small'
          variant='outlined'
          id='authorizedIp'
          name='tokenName'
          value={tokenName}
          error={!!error}
          onChange={(e) => {
            if (tokenName.length >= 1) {
              setError(false)
            }
            setTokenName(e.target.value)
          }}
          helperText={error && `Please fill the required field*`}
        />
        <MuiThemeProvider theme={getMuiTheme()}>
          <Link
            component={Button}
            // className={classes.button}

            onClick={handleGenerate}
          >
            Generate Token
          </Link>
        </MuiThemeProvider>
      </Box>
    </div>
  )

  // your function to copy here

  useEffect(() => {
    let ignore = false
    if (!props.tokenLists) {
      if (!ignore) {
        props.tokenListCall()
      }
    }
    return () => {
      if (props.tokenLists) {
        ignore = true
      }
    }
  }, [props.tokenLists])

  return (
    <>
      <Snackbar open={successAlert} autoHideDuration={3000} onClose={() => setSuccessAlert(false)}>
        <Alert onClose={() => setSuccessAlert(false)} severity='success'>
          Token Copied
        </Alert>
      </Snackbar>
      <Snackbar open={successRevoke} autoHideDuration={3000} onClose={() => setSuccessRevoke(false)}>
        <Alert onClose={() => setSuccessRevoke(false)} severity='success'>
          Revoke Token Successfully
        </Alert>
      </Snackbar>
      <Snackbar open={deleteAlert} autoHideDuration={3000} onClose={() => setDeleteAlert(false)}>
        <Alert onClose={() => setDeleteAlert(false)} severity='success'>
          Auth Token deleted successfully.
        </Alert>
      </Snackbar>
      <Box mt={3} mb={2}>
        <Typography variant='body2' color='textSecondary' gutterBottom>
          Generate and Update your API Settings here!
        </Typography>
      </Box>
      <Divider light={true} />

      <Button
        variant='contained'
        color='primary'
        className={classes.button}
        onClick={() => {
          setApiGenerated(true)
          handleOpen()
        }}
      >
        Generate
      </Button>

      <Modal open={open} onClose={handleClose} aria-labelledby='simple-modal-title' aria-describedby='simple-modal-description'>
        {body}
      </Modal>
      {/* <Collapse in={apiGenerated}>
        <>
          <Box
            my={4}
            width={"50%"}
            display="flex"
            flexDirection="column"
            style={{ gap: 10 }}
          >
            {/* <InputLabel className={classes.darkText} htmlFor="authToken">
              {" "}
              Auth Token
            </InputLabel>
            <TextField
              size="small"
              disabled
              variant="outlined"
              id="authToken"
              value={"9DLsy9w0pH4TD9hRwIB7PoL97O5rpjAH"}
            /> */}
      {/* <InputLabel className={classes.darkText} htmlFor="privateSalt">
              <br />
              Private Salt
            </InputLabel>
            <TextField
              size="small"
              disabled
              variant="outlined"
              id="privateSalt"
              value={"yIFY6q5QD9hVpi3cCVzgbd22CvSm66Fd"}
            /> */}
      {/* <InputLabel className={classes.darkText} htmlFor="Token Name">
              <br />
              {/* Authorized IPs <small>(separated by Commas ",")</small> */}
      {/* Token Name */}
      {/* </InputLabel> */}
      {/* <TextField
              size="small"
              variant="outlined"
              id="authorizedIp"
              name="tokenName"
              value={tokenName}
              error={!!error}
              onChange={(e) => {
                if (tokenName.length >= 1) {
                  setError(false);
                }
                setTokenName(e.target.value);
              }}
              helperText={error && `Please fill the required field*`}
            />
          </Box>
          <Divider />
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleGenerate}
          >
            Generate Token
          </Button> */}
      {/* <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => setSuccessAlert(true)}
          >
            Update
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={() => {
              setApiGenerated(false);
              setDeleteAlert(true);
            }}
          >
            Delete
          </Button> */}
    </>
    // </Collapse >

    // {props.tokenLists && (
    //   <MuiThemeProvider theme={getMuiTheme()}>
    //     <MUIDataTable
    //       title={`Token List`}
    //       data={rows}
    //       columns={columns}
    //       options={{
    //         selectableRows: false, // <===== will turn off checkboxes in rows
    //       }}
    //     />
    //   </MuiThemeProvider>
    // )}
  )
}

const mapStateToProps = (state) => ({
  createData: state.ui.createData,
  inviteeData: state.ui.inviteData,
  tokenLists: state.auth.tokenLists
})

const mapDispatchToProps = {
  setCreateData: UiActions.setCreateData,
  setInviteeData: UiActions.setInviteData,
  generateToken: authActions.generateToken,
  tokenListCall: authActions.tokenList,
  revokeToken: authActions.callRevokeToken
}

// export { API };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(API))
