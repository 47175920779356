const fixedValues = (name, dataArr) => {
  let data = dataArr

  const getUniqueName = (name, inc = 0) => {
    if (inc === 100) {
      return `${name} (${inc})`
    }
    for (let fldr of data) {
      if (!inc) {
        if (fldr.name === name) {
          return getUniqueName(name, inc + 1)
        }
      } else {
        if (fldr.name === `${name} (${inc})`) {
          return getUniqueName(name, inc + 1)
        }
      }
    }
    return inc ? `${name} (${inc})` : name
  }

  return getUniqueName(name)
}

export default fixedValues
