import { Box, ListItemIcon, makeStyles, Menu, MenuItem, Typography } from '@material-ui/core'
import { DeleteRounded, FolderRounded, TextFieldsRounded } from '@material-ui/icons'
import { withStyles } from '@material-ui/styles'
import { UiActions } from 'globalUI'
import moment from 'moment'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
    transition: 'transform 100ms ease-in-out',
    '&:not(:active):hover': {
      // border: `1px solid ${theme.palette.grey[500]}`,
      transform: 'translateY(-5px)',
      boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px'
    }
  },
  editBtn: {
    position: 'absolute',
    top: 4,
    right: 4,
    padding: '0.5rem'
  }
}))

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    borderRadius: 2
  }
})(Menu)

function FolderCard({ name, date, id, renameFolder, deleteFolder }) {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = React.useState(null)

  const openContextMenu = (evnt) => {
    evnt.preventDefault()
    setAnchorEl({
      mouseX: evnt.clientX - 2,
      mouseY: evnt.clientY - 4
    })
  }

  const handleRename = () => {
    setAnchorEl(null)
    setTimeout(() => {
      let resp = window.prompt('Enter Folder Name', name)
      if (!resp?.trim() || resp === name) {
        return
      }
      renameFolder(id, resp)
    }, 100)
  }
  const handleDelete = () => {
    deleteFolder(id)
    setAnchorEl(null)
  }

  return (
    <>
      <StyledMenu
        elevation={0}
        anchorReference='anchorPosition'
        anchorPosition={
          anchorEl?.mouseY !== null && anchorEl?.mouseX !== null ? { top: anchorEl?.mouseY, left: anchorEl?.mouseX } : undefined
        }
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={handleRename}>
          <ListItemIcon>
            <TextFieldsRounded fontSize='small' color='primary' />
          </ListItemIcon>
          Rename &nbsp;
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <DeleteRounded fontSize='small' color='secondary' />
          </ListItemIcon>
          Delete &nbsp;
        </MenuItem>
      </StyledMenu>
      <Box
        className={classes.root}
        component={Link}
        to={`/folders/${id}`}
        m={2}
        flexGrow={1}
        position='relative'
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        border={1}
        borderColor='grey.400'
        borderRadius={3}
        maxWidth={350}
        onContextMenu={openContextMenu}
      >
        {/* <IconButton size="small" className={classes.editBtn}>
                <EditRounded fontSize="small" />
            </IconButton> */}
        <Box display='flex' alignItems='center'>
          <Box m={2}>
            <FolderRounded color='action' />
          </Box>
          <Box my={3} mx={1}>
            <Typography variant='body1' color='textPrimary' gutterBottom>
              {' '}
              {name}{' '}
            </Typography>
            <Typography variant='body2' color='textSecondary'>
              {' '}
              ID: {id}{' '}
            </Typography>
          </Box>
        </Box>
        <Box m={1} mt={'auto'}>
          <Typography variant='caption' color='textSecondary'>
            {moment(date).format('ll')}
          </Typography>
        </Box>
      </Box>
    </>
  )
}

const mapStateToProps = (state) => ({
  createData: state.ui.createData,
  inviteeData: state.ui.inviteData
})

const mapDispatchToProps = {
  setCreateData: UiActions.setCreateData,
  setInviteeData: UiActions.setInviteData
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FolderCard))
