import {
  Backdrop,
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  Paper,
  TextField,
  Typography,
  Avatar
} from '@material-ui/core'
import { CloseRounded, Edit, PermIdentity, VpnKeyRounded } from '@material-ui/icons'

import React, { useState, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import ProfileImage from 'assets/profile.svg'
import { connect, useDispatch } from 'react-redux'
import { withRouter } from 'react-router'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone'
import profile from '../../../assets/profile.svg'
import { clientActions } from 'getDetails'
import AvatarCom from 'views/containers/header/avatar'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer - 1,
    color: '#fff'
  },
  button: {
    marginRight: theme.spacing(2),
    textTransform: 'none'
  },
  darkText: {
    marginTop: theme.spacing(1),
    color: theme.palette.grey[700]
  },
  contentBox: {
    background: theme.palette.background.paper,
    padding: '50px',
    boxShadow: '0px 0px 50px -20px #ccc',
    // marginTop: "50px",
    borderRadius: '10px',
    marginBottom: '1rem',
    position: 'relative'
  }
}))

function Profile(props) {
  const classes = useStyles()
  const { userName } = props

  const [changePasswordDrawer, setChangePasswordDrawer] = useState(false)
  const [editProfileDrawer, setEditProfileDrawer] = useState(false)
  const [changePassViaOtp, setChangePassViaOtp] = useState(false)

  const {
    handleSubmit: passHandleSubmit,
    control: passControl,
    errors: passErrors
  } = useForm({
    defaultValues: {},
    mode: 'onBlur'
  })

  const [user, setUser] = useState([])

  const { handleSubmit, control, errors } = useForm({
    defaultValues: {},
    mode: 'onBlur'
  })
  const closeDrawers = () => {
    setChangePasswordDrawer(false)
    setEditProfileDrawer(false)
  }

  const changePasswordRequest = (data) => {
    // console.log(data);
  }

  const submitEditProfile = (data) => {
    // console.log(data);
  }

  // let user = {
  //   name: "Simranjeet Singh",
  //   email: "smrnjeet222@gmail.com",
  //   mobile: "+91 0000000000",
  // };
  useEffect(() => {
    props.getProfileDetails()
    // console.log("User", props.profileDetail);
    setUser(props.profileDetail)
  }, [])
  // console.log("User Data", user);
  return (
    <>
      <Backdrop className={classes.backdrop} open={changePasswordDrawer || editProfileDrawer} onClick={closeDrawers} />
      <Drawer
        anchor='right'
        variant='persistent'
        classes={{ paperAnchorDockedRight: 'no-border-drawer' }}
        open={changePasswordDrawer}
        onClose={() => setChangePasswordDrawer(false)}
      >
        <Box width={500} role='presentation'>
          <Box
            position='sticky'
            top={0}
            zIndex={10}
            display='flex'
            py={1}
            px={2}
            justifyContent='space-between'
            alignItems='center'
            bgcolor='primary.main'
            color='primary.contrastText'
          >
            <Typography variant='h5'>Change Password</Typography>
            <IconButton onClick={() => setChangePasswordDrawer(false)} style={{ color: 'white' }}>
              <CloseRounded />
            </IconButton>
          </Box>
          <Divider />
          <Box p={2} display='flex' flexDirection='column' style={{ gap: 10 }}>
            {changePassViaOtp ? (
              <>
                <InputLabel className={classes.darkText} htmlFor='otp'>
                  One Time Password
                </InputLabel>
                <Controller
                  render={({ onChange, onBlur, value, ref }) => (
                    <TextField
                      size='small'
                      variant='outlined'
                      fullWidth
                      onBlur={onBlur}
                      id='otp'
                      error={!!passErrors.otp}
                      onChange={(e) => onChange(e.target.value)}
                      value={value}
                      inputRef={ref}
                    />
                  )}
                  control={passControl}
                  name='otp'
                  rules={{ required: true }}
                  defaultValue={''}
                />
              </>
            ) : (
              <>
                <InputLabel className={classes.darkText} htmlFor='oldPass'>
                  Old Password
                </InputLabel>
                <Controller
                  render={({ onChange, onBlur, value, ref }) => (
                    <TextField
                      size='small'
                      variant='outlined'
                      fullWidth
                      onBlur={onBlur}
                      id='oldPass'
                      error={!!passErrors.oldPassword}
                      onChange={(e) => onChange(e.target.value)}
                      value={value}
                      inputRef={ref}
                    />
                  )}
                  control={passControl}
                  name='oldPassword'
                  rules={{ required: true }}
                  defaultValue={''}
                />
              </>
            )}
            <InputLabel className={classes.darkText} htmlFor='newPass'>
              New Password
            </InputLabel>
            <Controller
              render={({ onChange, onBlur, value, ref }) => (
                <TextField
                  size='small'
                  variant='outlined'
                  fullWidth
                  onBlur={onBlur}
                  id='newPass'
                  error={!!passErrors.newPassword}
                  onChange={(e) => onChange(e.target.value)}
                  value={value}
                  inputRef={ref}
                />
              )}
              control={passControl}
              name='newPassword'
              rules={{ required: true }}
              defaultValue={''}
            />
            <InputLabel className={classes.darkText} htmlFor='confirmPass'>
              Confirm Password
            </InputLabel>
            <Controller
              render={({ onChange, onBlur, value, ref }) => (
                <TextField
                  size='small'
                  variant='outlined'
                  fullWidth
                  onBlur={onBlur}
                  id='confirmPass'
                  error={!!passErrors.confirmPassword}
                  onChange={(e) => onChange(e.target.value)}
                  value={value}
                  inputRef={ref}
                />
              )}
              control={passControl}
              name='confirmPassword'
              rules={{ required: true }}
              defaultValue={''}
            />
            <Box my={2}>
              <Button className={classes.button} variant='contained' color='primary' onClick={passHandleSubmit(changePasswordRequest)}>
                Update
              </Button>
              {changePassViaOtp && (
                <Button className={classes.button} color='primary'>
                  Get Otp
                </Button>
              )}
            </Box>

            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <Divider style={{ flex: 1 }} />
              <Typography variant='caption'>&nbsp; OR &nbsp;</Typography>
              <Divider style={{ flex: 1 }} />
            </Box>

            <Box my={2} ml={'auto'}>
              {!changePassViaOtp ? (
                <Button className={classes.button} size='large' color='primary' onClick={() => setChangePassViaOtp(true)}>
                  Via OTP
                </Button>
              ) : (
                <Button className={classes.button} size='large' color='primary' onClick={() => setChangePassViaOtp(false)}>
                  Via Old Password
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Drawer>

      <Drawer
        anchor='right'
        variant='persistent'
        classes={{ paperAnchorDockedRight: 'no-border-drawer' }}
        open={editProfileDrawer}
        onClose={() => setEditProfileDrawer(false)}
      >
        <Box width={500} role='presentation'>
          <Box
            position='sticky'
            top={0}
            zIndex={10}
            display='flex'
            py={1}
            px={2}
            justifyContent='space-between'
            alignItems='center'
            bgcolor='primary.main'
            color='primary.contrastText'
          >
            <Typography variant='h5'>Edit Profile</Typography>
            <IconButton onClick={() => setEditProfileDrawer(false)} style={{ color: 'white' }}>
              <CloseRounded />
            </IconButton>
          </Box>
          <Divider />
          <Box p={2} display='flex' flexDirection='column' style={{ gap: 10 }}>
            {/* <InputLabel className={classes.darkText} htmlFor="fullname">
              Full Name
            </InputLabel>
            <Controller
              render={({ onChange, onBlur, value, ref }) => (
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  onBlur={onBlur}
                  id="fullname"
                  error={!!errors.fullname}
                  onChange={(e) => onChange(e.target.value)}
                  value={value}
                  inputRef={ref}
                />
              )}
              control={control}
              name="fullname"
              defaultValue={""}
            /> */}
            <InputLabel className={classes.darkText} htmlFor='username'>
              Username
            </InputLabel>
            <Controller
              render={({ onChange, onBlur, value, ref }) => (
                <TextField
                  size='small'
                  variant='outlined'
                  fullWidth
                  onBlur={onBlur}
                  id='username'
                  error={!!errors.username}
                  onChange={(e) => onChange(e.target.value)}
                  value={value}
                  inputRef={ref}
                />
              )}
              control={control}
              name='username'
              defaultValue={''}
            />
            {/* <InputLabel className={classes.darkText} htmlFor="email">
              Email
            </InputLabel>
            <Controller
              render={({ onChange, onBlur, value, ref }) => (
                <TextField
                  size="small"
                  variant="outlined"
                  fullWidth
                  onBlur={onBlur}
                  id="email"
                  error={!!errors.email}
                  onChange={(e) => onChange(e.target.value)}
                  value={value}
                  inputRef={ref}
                />
              )}
              control={control}
              name="email"
              defaultValue={""}
            /> */}
            <InputLabel className={classes.darkText} htmlFor='phoneNo'>
              Phone No.
            </InputLabel>
            <Controller
              render={({ onChange, onBlur, value, ref }) => (
                <TextField
                  size='small'
                  variant='outlined'
                  fullWidth
                  onBlur={onBlur}
                  id='phoneNo'
                  error={!!errors.phoneNo}
                  onChange={(e) => onChange(e.target.value)}
                  value={value}
                  inputRef={ref}
                />
              )}
              control={control}
              name='phoneNo'
              defaultValue={''}
            />
            <Box my={3}>
              <Button className={classes.button} size='large' color='primary' variant='contained' onClick={handleSubmit(submitEditProfile)}>
                Update
              </Button>
            </Box>
          </Box>
        </Box>
      </Drawer>

      <Grid container justifyContent='center'>
        <Grid item xs={11} md={8}>
          <Box my={4} display='flex' alignItems='flex-end'>
            <PermIdentity /> &nbsp;&nbsp;&nbsp;
            <Typography variant='caption'>Update your Profile here!</Typography>
          </Box>
        </Grid>
        <Grid item xs={11} md={8}>
          <Paper variant='outlined' className={classes.contentBox}>
            {/* <IconButton
              style={{ position: "absolute", right: 0, top: 0, margin: 20 }}
              onClick={() => setEditProfileDrawer(true)}
            >
              <Edit />
            </IconButton> */}
            <Grid container justifyContent={'space-between'}>
              <Grid item xs={12} md={3}>
                <Box>
                  <AvatarCom width='150px' height='150px' fontSize='4rem' />
                  <br />
                  {/*    <Button size="small" variant="outlined" color="primary"*/}
                  {/*        style={{ textTransform: "none", margin: "0.6rem 0" }}*/}
                  {/*    >*/}
                  {/*        Change Picture*/}
                  {/*</Button>*/}
                </Box>
              </Grid>
              <Grid item xs={12} md={8}>
                <Box>
                  <Typography variant='h4'>{user?.data?.name}</Typography>
                  {/*<Typography variant="body2" color="textSecondary">{user.company}</Typography>*/}
                  <br />
                  <Grid container justifyContent={'flex-start'}>
                    <MailOutlineIcon color={'secondary'} fontSize={'large'} />
                    <div style={{ marginLeft: 10 }}>
                      <Typography variant='subtitle1' color='textSecondary'>
                        Email
                      </Typography>
                      <Typography variant='body1'>{user?.data?.user_email || '-'}</Typography>
                    </div>
                  </Grid>
                  <br />
                  <Grid container justifyContent={'flex-start'}>
                    <PhoneIphoneIcon color={'primary'} fontSize={'large'} />
                    <div style={{ marginLeft: 10 }}>
                      <Typography variant='subtitle1' color='textSecondary'>
                        Phone No.
                      </Typography>
                      <Typography variant='body1'>{user?.data?.user_mobile || '-'}</Typography>
                    </div>
                  </Grid>
                  <br />
                  {/*<Typography variant="subtitle1" color="textSecondary">Default Payment </Typography>*/}
                  {/*<Typography variant="body1">State Bank of India, Debit Card *****1301</Typography>*/}
                  {/*<br />*/}
                  <Divider />
                </Box>
                {/*<Box display="flex" justifyContent="flex-end" mt={1} ml={"auto"}>*/}
                {/*    <Button size="small" color="primary" startIcon={<VpnKeyRounded fontSize="small" />}*/}
                {/*        onClick={() => setChangePasswordDrawer(true)}*/}
                {/*    >*/}
                {/*        Change Password*/}
                {/*</Button>*/}
                {/*</Box>*/}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

const mapStateToProps = (state) => ({
  profileDetail: state.clientData.profileData,
  userName: state.clientData.userName
})

const mapDispatchToProps = {
  getProfileDetails: clientActions.getProfileDetails
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile))
