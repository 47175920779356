import React, { useState, useEffect } from 'react'
import { Grid, Button, Typography, Modal, CircularProgress, makeStyles, Snackbar } from '@material-ui/core'

import { useDispatch, useSelector } from 'react-redux'
import { callRevokeToken, tokenList } from 'auth/actions'

import { cancelSubscriptionPlan, getCurrentPlanDetails, removeCancelSchedule } from 'getDetails/actions'

import { CANCEL_SCHEDULE_MODAL } from 'auth'
import { Alert } from '@material-ui/lab'
import { REMOVE_CANCEL_SCHEDULE } from 'getDetails'
import setAuthorizationToken from 'utils/axios-instance'

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  }
}

{
  /* <div>
    <ModalBody
      classes={classes}
      handleClose={handleClose}
      handleCancelation={handleCancelation}
    />
  </div> */
}
{
  /* <Body openModal={openModal} closeModal={this.closeModal} /> */
}

const useStyles = makeStyles((theme) => ({
  paper1: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    outline: 'none',
    boxShadow: theme.shadows[5],
    borderRadius: '10px',
    padding: '24px 45px 20px'
  },
  modal: {
    backdropFilter: 'blur(1px)'
  },
  paper: {
    position: 'absolute',
    width: 450,
    backgroundColor: '#ffff',
    overflowY: 'auto',
    outline: 'none',
    borderRadius: '10px',

    padding: '24px 30px 20px',
    boxShadow: theme.shadows[5]
    // "& .focus-visible": {
    //   outline: "none",
    // },

    // padding: "1px 19px 1px",
  },
  text: {
    paddingBottom: '32px',
    fontSize: '25px',
    fontWeight: '600',
    lineHeight: '1.5'
  },
  danger: {
    background: 'red',
    color: 'rgb(255, 255, 255)',
    marginRight: '10px',
    fontSize: '12px',
    fontWeight: '600',
    '&:hover': {
      background: 'red'
    }
  }
}))

const DeleteTokenModal = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  //   const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState('')

  const [modalStyle] = useState(getModalStyle)
  const { cancelModalState, cancelSchedule } = useSelector((state) => state.modalRed)
  const { tokenLists, accessToken } = useSelector((state) => state.auth)

  //   const [cancelSchedule, setCancelScheduleModal] = useState(false);
  // console.log("CurrentPlan", currentPlan);
  const handleRemove = (e) => {
    e.preventDefault()
    setOpen(true)
    // setAuthorizationToken(props.tokenValue)
    dispatch(callRevokeToken(props.apiKey))
    // props.revokeToken();
    props.setShow(true)
    // console.log("accessToken", props.accessToken === data?.token);
    // setAuthorizationToken(accessToken)
setTimeout(()=>dispatch(tokenList()),800)


    // setShow(true)

    props.setSuccessRevoke(true)

    props.setOpenModal(false)
  }

  const handleClose = () => {
    props.setOpenModal(false)
    // props.setOpen(false);
  }

  //   const handleModal = (e) => {
  //     e.preventDefault();
  //     props.setOpenModal(true);
  //     // dispatch(cancelModal(true));
  //   };

  return (
    <>
      <Modal
        open={props.openModal}
        onClose={(event, reason) => handleClose(event, reason)}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
      >
        <div style={modalStyle} className={classes.paper}>
          <Grid container>
            <Grid container item justifyContent='center'>
              <Typography variant='subtitle1' color='textPrimary' align='center' className={classes.text}>
                Do you really want to remove this token?
              </Typography>
            </Grid>
            <Grid container item justifyContent='center'>
              <Button
                variant='text'
                style={{
                  marginRight: '10px',
                  color: '#111',
                  /* font-size: 12px; */
                  fontWeight: '600'
                }}
                onClick={handleClose}
              >
                Dismiss
              </Button>
              <Button variant='contained' className={classes.danger} onClick={(e) => handleRemove(e)}>
                Remove
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </>
  )
}

export default DeleteTokenModal
