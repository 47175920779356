import {
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  TextField,
  Tooltip,
  Typography,
  useTheme
} from '@material-ui/core'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import {
  ArrowDropDownCircleOutlined,
  BlurCircularOutlined,
  CloseRounded,
  DeleteOutlineRounded,
  GetAppRounded,
  LaunchRounded,
  MoreHorizRounded,
  VisibilityOutlined
} from '@material-ui/icons'
import MUIDataTable from 'mui-datatables'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { DatePicker } from 'views/components/date-picker/DatePicker'
import { debounceSearchRender } from 'views/components/DebounceSearchRender'
import FileInput from 'views/components/drag-drop'

const styles = {
  actionBtns: {
    display: 'flex',
    justifyContent: 'flex-start',
    textTransform: 'none'
  }
}

function MyStamps({ ...props }) {
  const theme = useTheme()

  const [data, setData] = useState([{ id: 1412414, seriesNo: 'TwIBjJBOjIFDLaQ', createdAt: '11-31-2019' }])
  const [createStamp, setCreateStamp] = useState(false)

  const { register, handleSubmit, watch, control, errors, setError, clearErrors, getValues } = useForm({
    defaultValues: {},
    mode: 'onBlur'
  })

  const changeTableStyle = () =>
    createMuiTheme({
      palette: theme.palette,
      overrides: {
        MUIDataTableBodyCell: {
          root: {
            color: theme.palette.primary.main,
            fontSize: '14px'
          }
        },
        MUIDataTableHeadCell: {
          root: {
            marginLeft: 'auto',
            borderTop: `1px solid ${theme.palette.grey[300]}`,
            backgroundColor: '#f7f8fb !important',
            color: theme.palette.primary.main,
            fontSize: '14px',
            fontWeight: 600
          }
        },
        MuiTableRow: {
          root: {
            '&:nth-child(even)': {
              backgroundColor: '#fafbfe !important'
            },
            '&:nth-child(odd)': {
              backgroundColor: '#ffffff !important'
            }
          },
          hover: {
            '&:hover': {
              backgroundColor: 'rgba(122, 139, 237, 0.1)!important'
            }
          }
        },
        MuiPaper: {
          root: {
            border: `1px solid ${theme.palette.grey[300]}`
          }
        },
        MuiToolbar: {
          root: {
            position: 'static',
            paddingLeft: '15px !important'
          }
        },
        MuiTableCell: {
          root: {
            '&:not(:nth-last-child(1))': {
              // borderRight: `1px solid ${theme.palette.grey[300]}`,
            },
            padding: '10px !important'
          },
          head: {
            padding: '10px !important'
          }
        },
        MUIDataTablePagination: {
          tableCellContainer: {
            paddingTop: '0 !important',
            paddingBottom: '0 !important',
            border: 0
          }
        }
      }
    })

  const TooltipTheme = () =>
    createMuiTheme({
      palette: theme.palette,
      overrides: {
        MuiTooltip: {
          tooltip: {
            backgroundColor: '#fff',
            color: '#222',
            boxShadow: 'rgba(62, 62, 119, 0.25) 0px 13px 27px -5px,rgba(0, 0, 0, 0.3) 0px 8px 16px -8px !important'
          },
          arrow: {
            color: '#fff'
          }
        }
      }
    })

  const options = {
    rowsPerPage: 6,
    rowsPerPageOptions: [6, 10, 20, 50],
    jumpToPage: true,
    pagination: true,
    elevation: 0,
    filter: false,
    selectableRows: 'none',
    responsive: 'simple',
    sort: true,
    print: false,
    search: true,
    viewColumns: true,
    download: false,
    customSearchRender: debounceSearchRender(100),
    customToolbar: (data) => (
      <Box display='inline' ml={2}>
        <Button variant='contained' size='small' color='primary' onClick={() => setCreateStamp(true)}>
          New Stamp Series
        </Button>
      </Box>
    )
  }

  const columns = [
    {
      name: 'id',
      label: '#',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (d, tableMeta) => (
          <Typography variant='subtitle2' color='textPrimary'>
            {tableMeta.rowIndex + 1}.{' '}
          </Typography>
        )
      },
      customHeadLabelRender: (cm) => (
        <Box display='flex' justifyContent='center'>
          {cm.label}
        </Box>
      )
    },
    {
      name: 'seriesNo',
      label: 'Series Number',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (d) => (
          <>
            <Typography variant='body1' color='textPrimary'>
              {d || '-'}
            </Typography>
          </>
        )
      }
    },
    {
      name: 'denomination',
      label: 'Denomination',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (d) => (
          <>
            <Typography variant='body1' color='textPrimary'>
              {d || '-'}
            </Typography>
          </>
        )
      }
    },
    {
      name: 'state',
      label: 'State',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (d) => (
          <>
            <Typography variant='body1' color='textPrimary'>
              {d || '-'}
            </Typography>
          </>
        )
      }
    },
    {
      name: 'total',
      label: 'Total',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (d) => (
          <>
            <Typography variant='body1' color='textPrimary'>
              {d || '-'}
            </Typography>
          </>
        )
      }
    },
    {
      name: 'reserved',
      label: 'Reserved',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (d) => (
          <>
            <Typography variant='body1' color='textPrimary'>
              {d || '-'}
            </Typography>
          </>
        )
      }
    },
    {
      name: 'used',
      label: 'Used',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (d) => (
          <>
            <Typography variant='body1' color='textPrimary'>
              {d || '-'}
            </Typography>
          </>
        )
      }
    },
    {
      name: 'unused',
      label: 'Unused',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (d) => (
          <>
            <Typography variant='body1' color='textPrimary'>
              {d || '-'}
            </Typography>
          </>
        )
      }
    },
    {
      name: 'blocked',
      label: 'Blocked',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (d) => (
          <>
            <Typography variant='body1' color='textPrimary'>
              {d || '-'}
            </Typography>
          </>
        )
      }
    },
    {
      name: 'process',
      label: 'Processed',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (d) => (
          <>
            <Typography variant='body1' color='textPrimary'>
              {d || '-'}
            </Typography>
          </>
        )
      }
    },
    {
      name: 'id',
      label: 'Actions',
      options: {
        sort: false,
        filter: false,
        searchable: false,
        customBodyRender: (value) => (
          <Box display='flex' justifyContent='center'>
            <MuiThemeProvider theme={TooltipTheme()}>
              <Tooltip
                arrow={true}
                placement='left'
                enterTouchDelay={0}
                leaveTouchDelay={3000}
                leaveDelay={100}
                interactive
                title={<ActionDrawer id={value} />}
              >
                <IconButton color='primary' size='medium'>
                  <MoreHorizRounded />
                </IconButton>
              </Tooltip>
            </MuiThemeProvider>
          </Box>
        ),
        customHeadLabelRender: (cm) => (
          <Box display='flex' justifyContent='center'>
            {cm.label}
          </Box>
        )
      }
    }
  ]

  const onSubmit = (data) => {
    // console.log(data);
    setCreateStamp(false)
  }

  const ActionDrawer = ({ id }) => (
    <List dense>
      <ListItem button>
        <ListItemIcon>
          <VisibilityOutlined fontSize='small' />
        </ListItemIcon>
        <ListItemText primary='Preview' />
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          <LaunchRounded fontSize='small' />
        </ListItemIcon>
        <ListItemText primary='Open' />
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          <GetAppRounded fontSize='small' />
        </ListItemIcon>
        <ListItemText primary='Download' />
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          <DeleteOutlineRounded fontSize='small' />
        </ListItemIcon>
        <ListItemText primary='Delete' />
      </ListItem>
    </List>
  )

  return (
    <Grid container justifyContent='center'>
      <Grid item xs={12} sm={11}>
        <Box my={3}>
          <DatePicker />
        </Box>
      </Grid>
      <Grid item sm={11} xs={12}>
        <MuiThemeProvider theme={changeTableStyle()}>
          <MUIDataTable
            title={
              <Box display='flex' alignItems='center'>
                <BlurCircularOutlined fontSize='small' />
                <Typography variant='inherit'>&nbsp;&nbsp;&nbsp; Stamps</Typography>
              </Box>
            }
            data={data}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Grid>
      <Drawer anchor='right' open={createStamp}>
        <Box minWidth={500} role='presentation' display='flex' flexDirection='column' height='100%'>
          <Box
            position='sticky'
            top={0}
            zIndex={10}
            display='flex'
            flexGrow={'auto'}
            py={1}
            px={2}
            justifyContent='space-between'
            alignItems='center'
            bgcolor='primary.main'
            color='primary.contrastText'
          >
            <Typography variant='h5'>Stamp Request Form</Typography>
            <IconButton onClick={() => setCreateStamp(false)} style={{ color: 'white' }}>
              <CloseRounded />
            </IconButton>
          </Box>
          <Box display='flex' flexDirection='column' flexGrow={1} p={2} style={{ gap: '1.5rem' }}>
            <Controller
              render={({ onChange, onBlur, value, name, ref }, { invalid, isTouched, isDirty }) => (
                <TextField
                  size='small'
                  variant='outlined'
                  fullWidth
                  error={!!errors?.state}
                  onBlur={onBlur}
                  label='State'
                  onChange={(e) => onChange(e.target.value)}
                  value={value}
                  inputRef={ref}
                  placeholder='Select State'
                />
              )}
              control={control}
              name='state'
              rules={{ required: true }}
              defaultValue={''}
            />
            <Controller
              render={({ onChange, onBlur, value, name, ref }, { invalid, isTouched, isDirty }) => (
                <TextField
                  size='small'
                  variant='outlined'
                  fullWidth
                  error={!!errors?.denomination}
                  onBlur={onBlur}
                  label='Denomination'
                  onChange={(e) => onChange(e.target.value)}
                  value={value}
                  inputRef={ref}
                  placeholder='Denomination'
                />
              )}
              control={control}
              name='denomination'
              rules={{ required: true }}
              defaultValue={''}
            />
            <Controller
              render={({ onChange, onBlur, value, name, ref }, { invalid, isTouched, isDirty }) => (
                <TextField
                  size='small'
                  variant='outlined'
                  fullWidth
                  error={!!errors?.firstPartyName}
                  onBlur={onBlur}
                  label="First Party's Name"
                  onChange={(e) => onChange(e.target.value)}
                  value={value}
                  inputRef={ref}
                  placeholder="First Party's Name"
                />
              )}
              control={control}
              name='firstPartyName'
              rules={{ required: true }}
              defaultValue={''}
            />
            <Controller
              render={({ onChange, onBlur, value, name, ref }, { invalid, isTouched, isDirty }) => (
                <TextField
                  size='small'
                  variant='outlined'
                  fullWidth
                  error={!!errors?.firstPartyAuthorisedSignatory}
                  onBlur={onBlur}
                  label='First Party Authorised Signatory'
                  onChange={(e) => onChange(e.target.value)}
                  value={value}
                  inputRef={ref}
                  placeholder='First Party Authorised Signatory'
                />
              )}
              control={control}
              name='firstPartyAuthorisedSignatory'
              rules={{ required: true }}
              defaultValue={''}
            />
            <div>
              <Typography variant='body2' gutterBottom>
                {' '}
                Id proof of authorised signatory :{' '}
              </Typography>
              <FileInput name='idProof' control={control} required={true} small={true} error={!!errors?.idProof} />
            </div>
            <Controller
              render={({ onChange, onBlur, value, name, ref }, { invalid, isTouched, isDirty }) => (
                <TextField
                  size='small'
                  variant='outlined'
                  fullWidth
                  error={!!errors?.secondPartyName}
                  onBlur={onBlur}
                  label='Second Party Name'
                  onChange={(e) => onChange(e.target.value)}
                  value={value}
                  inputRef={ref}
                  placeholder='Second Party Name'
                />
              )}
              control={control}
              name='secondPartyName'
              defaultValue={''}
            />
            <Controller
              render={({ onChange, onBlur, value, name, ref }, { invalid, isTouched, isDirty }) => (
                <TextField
                  size='small'
                  variant='outlined'
                  fullWidth
                  error={!!errors?.purpose}
                  onBlur={onBlur}
                  label='Purpose'
                  onChange={(e) => onChange(e.target.value)}
                  value={value}
                  inputRef={ref}
                  placeholder='Purpose'
                  multiline
                  rows={3}
                />
              )}
              control={control}
              name='purpose'
              rules={{ required: true }}
              defaultValue={''}
            />
            <Controller
              render={({ onChange, onBlur, value, name, ref }, { invalid, isTouched, isDirty }) => (
                <TextField
                  size='small'
                  variant='outlined'
                  fullWidth
                  error={!!errors?.legend}
                  onBlur={onBlur}
                  label='Legend (To be printed on the stamp paper)'
                  onChange={(e) => onChange(e.target.value)}
                  value={value}
                  inputRef={ref}
                  placeholder='This Stamp Paper forms an integral part of the agreement bearing unique ID No. <Doc. ID.,> , executed between and the Second Party.'
                  multiline
                  rows={3}
                />
              )}
              control={control}
              name='legend'
              rules={{ required: true }}
              defaultValue={''}
            />
            <Controller
              render={({ onChange, onBlur, value, name, ref }, { invalid, isTouched, isDirty }) => (
                <TextField
                  size='small'
                  variant='outlined'
                  fullWidth
                  error={!!errors?.phoneNo}
                  onBlur={onBlur}
                  label='Phone Number (for clarifications)'
                  onChange={(e) => onChange(e.target.value)}
                  value={value}
                  inputRef={ref}
                  placeholder='Phone Number (for clarifications)'
                />
              )}
              control={control}
              name='phoneNo'
              rules={{ required: true }}
              defaultValue={''}
            />
            <Typography gutterBottom>Address (for delivery of original)</Typography>
            <Box display='flex' flexDirection='column' mx={2} style={{ gap: '1.5rem 1.5rem' }}>
              <Controller
                render={({ onChange, onBlur, value, name, ref }, { invalid, isTouched, isDirty }) => (
                  <TextField
                    size='small'
                    variant='outlined'
                    fullWidth
                    error={!!errors?.address?.name}
                    onBlur={onBlur}
                    label='Name'
                    onChange={(e) => onChange(e.target.value)}
                    value={value}
                    inputRef={ref}
                    placeholder='Name'
                  />
                )}
                control={control}
                name='address.name'
                rules={{ required: true }}
                defaultValue={''}
              />
              <Controller
                render={({ onChange, onBlur, value, name, ref }, { invalid, isTouched, isDirty }) => (
                  <TextField
                    size='small'
                    variant='outlined'
                    fullWidth
                    error={!!errors?.address?.fullAddress}
                    onBlur={onBlur}
                    label='Street address'
                    onChange={(e) => onChange(e.target.value)}
                    value={value}
                    inputRef={ref}
                    placeholder='Address'
                    multiline
                    rows={3}
                  />
                )}
                control={control}
                name='address.fullAddress'
                rules={{ required: true }}
                defaultValue={''}
              />
              <Box display='flex' justifyContent='space-between'>
                <Controller
                  render={({ onChange, onBlur, value, name, ref }, { invalid, isTouched, isDirty }) => (
                    <TextField
                      size='small'
                      variant='outlined'
                      error={!!errors?.address?.city}
                      onBlur={onBlur}
                      label='City'
                      onChange={(e) => onChange(e.target.value)}
                      value={value}
                      inputRef={ref}
                      placeholder='City'
                    />
                  )}
                  control={control}
                  name='address.city'
                  rules={{ required: true }}
                  defaultValue={''}
                />
                <Controller
                  render={({ onChange, onBlur, value, name, ref }, { invalid, isTouched, isDirty }) => (
                    <TextField
                      size='small'
                      variant='outlined'
                      error={!!errors?.address?.pinCode}
                      onBlur={onBlur}
                      label='Pin Code'
                      onChange={(e) => onChange(e.target.value)}
                      value={value}
                      inputRef={ref}
                      placeholder='Pin Code'
                    />
                  )}
                  control={control}
                  name='address.pinCode'
                  rules={{ required: true }}
                  defaultValue={''}
                />
              </Box>
              <Controller
                render={({ onChange, onBlur, value, name, ref }, { invalid, isTouched, isDirty }) => (
                  <TextField
                    size='small'
                    variant='outlined'
                    error={!!errors?.address?.state}
                    onBlur={onBlur}
                    label='State'
                    onChange={(e) => onChange(e.target.value)}
                    value={value}
                    inputRef={ref}
                    placeholder='State'
                  />
                )}
                control={control}
                name='address.state'
                rules={{ required: true }}
                defaultValue={''}
              />
            </Box>
          </Box>
          <div className='padding-for-firefox'>{/* Provide padding required for firefox browser for fixed footer */}</div>
          <Box
            component={Paper}
            square
            variant='outlined'
            position='sticky'
            mt={'auto'}
            bottom={0}
            zIndex={10}
            display='flex'
            flexGrow={'auto'}
            py={2}
            px={3}
            justifyContent='flex-end'
            alignItems='center'
          >
            <Button color='primary' onClick={() => setCreateStamp(false)}>
              Cancel
            </Button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Button color='primary' variant='contained' onClick={handleSubmit(onSubmit)}>
              Save
            </Button>
          </Box>
        </Box>
      </Drawer>
    </Grid>
  )
}

//=====================================
//  CONNECT
//-------------------------------------

const mapDispatchToProps = {}

const mapStateToProps = (state) => {
  return {}
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(MyStamps)
