import {
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import {
  ArrowDropDownCircleOutlined,
  DashboardOutlined,
  DeleteOutlineRounded,
  FileCopyOutlined,
  GetAppRounded,
  LaunchRounded,
  VisibilityOutlined,
} from "@material-ui/icons";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { compose } from "redux";
import { DatePicker } from "views/components/date-picker/DatePicker";
import { debounceSearchRender } from "../../components/DebounceSearchRender";
import {
  purple,
  red,
  green,
  teal,
  indigo,
  orange,
  brown,
} from "@material-ui/core/colors";

const styles = {
  actionBtns: {
    display: "flex",
    justifyContent: "flex-start",
    textTransform: "none",
  },
};

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    padding: "0.5rem",
  },
}))(Tooltip);

class MyTemplates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [
        { id: 1412414, name: "Template-IFDLaQ", createdAt: "11-31-2019" },
        { id: 1412414, name: "Template-IFDLaQ", createdAt: "11-31-2019" },
        { id: 1412414, name: "Template-IFDLaQ", createdAt: "11-31-2019" },
        { id: 1412414, name: "Template-IFDLaQ", createdAt: "11-31-2019" },
        { id: 1412414, name: "Template-IFDLaQ", createdAt: "11-31-2019" },
        { id: 1412414, name: "Template-IFDLaQ", createdAt: "11-31-2019" },
        { id: 1412414, name: "Template-IFDLaQ", createdAt: "11-31-2019" },
      ],
    };
  }

  changeTableStyle = () =>
    createMuiTheme({
      palette: this.props.theme.palette,
      overrides: {
        MUIDataTableBodyCell: {
          root: {
            color: this.props.theme.palette.primary.main,
            fontSize: "14px",
          },
        },
        MUIDataTableHeadCell: {
          root: {
            borderTop: `1px solid ${this.props.theme.palette.grey[300]}`,
            backgroundColor: "#f7f8fb !important",
            color: this.props.theme.palette.primary.main,
            fontSize: "14px",
            fontWeight: 600,
          },
          sortAction: {
            color: this.props.theme.palette.primary.main,
            fontWeight: 700,
          },
        },
        MuiTableRow: {
          root: {
            "&:nth-child(even)": {
              backgroundColor: "#fafbfe !important",
            },
            "&:nth-child(odd)": {
              backgroundColor: "#ffffff !important",
            },
          },
          hover: {
            "&:hover": {
              backgroundColor: "rgba(122, 139, 237, 0.1)!important",
            },
          },
        },
        MuiPaper: {
          root: {
            border: `1px solid ${this.props.theme.palette.grey[300]}`,
          },
        },
        MuiToolbar: {
          root: {
            position: "static",
            paddingLeft: "15px !important",
          },
        },
        MuiTableCell: {
          root: {
            "&:not(:nth-last-child(1))": {
              // borderRight: `1px solid ${this.props.theme.palette.grey[300]}`,
            },
            "&:nth-child(2)": {
              width: "35%",
            },
            padding: "10px !important",
          },
          head: {
            padding: "10px !important",
          },
        },
        MUIDataTablePagination: {
          tableCellContainer: {
            paddingTop: "0 !important",
            paddingBottom: "0 !important",
            border: 0,
          },
        },
      },
    });

  render() {
    const { classes } = this.props;

    const options = {
      rowsPerPage: 6,
      rowsPerPageOptions: [6, 10, 20, 50],
      jumpToPage: true,
      pagination: true,
      elevation: 0,
      filter: false,
      selectableRows: "none",
      filterType: "multiselect",
      responsive: "simple",
      sort: true,
      print: false,
      search: true,
      viewColumns: true,
      download: false,
      customSearchRender: debounceSearchRender(100),
      customToolbar: (data) => (
        <Box display="inline" ml={2}>
          <Button
            component={Link}
            to="/document/create-template"
            variant="contained"
            size="small"
            color="primary"
          >
            New Template
          </Button>
        </Box>
      ),
    };

    const columns = [
      {
        name: "id",
        label: "#",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (d, tableMeta) => (
            <Box display="flex" justifyContent="center">
              <Typography variant="subtitle2" color="textPrimary">
                {tableMeta.rowIndex + 1}.{" "}
              </Typography>
            </Box>
          ),
          customHeadLabelRender: (cm) => (
            <Box display="flex" justifyContent="center">
              {cm.label}
            </Box>
          ),
        },
      },
      {
        name: "name",
        label: "Name",
        options: {
          filter: false,
          sort: true,
          sortThirdClickReset: true,
          customBodyRender: (d, tableMeta) => {
            // console.log(tableMeta);
            return (
              <>
                <Typography variant="body1" color="textPrimary" gutterBottom>
                  {d}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  ID : {tableMeta.rowData[0]}
                </Typography>
              </>
            );
          },
        },
      },
      {
        name: "id",
        label: "Template Id",
        options: {
          sort: false,
          filter: false,
          display: false,
          customBodyRender: (d) => (
            <Box display="flex" alignItems="center">
              <FileCopyOutlined
                fontSize="small"
                style={{ color: `${brown[500]}` }}
              />{" "}
              &nbsp;&nbsp;&nbsp;
              <Typography variant="body2" color="textPrimary">
                {d}
              </Typography>
            </Box>
          ),
        },
      },
      {
        name: "createdAt",
        label: "Created At",
        options: {
          sort: true,
          filter: false,
          searchable: false,
          sortThirdClickReset: true,
          customBodyRender: (d) => (
            <>
              <Typography variant="subtitle2" color="textPrimary" gutterBottom>
                {moment(d).format("LL")}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {moment(d).format("LTS")}
              </Typography>
            </>
          ),
        },
      },
      {
        name: "id",
        label: "Actions",
        options: {
          sort: false,
          filter: false,
          searchable: false,
          customBodyRender: (value) => (
            <Box display="flex" justifyContent="center" style={{ gap: "1ch" }}>
              <LightTooltip title="Preview" aria-label="Identity">
                <IconButton style={{ color: `${teal[500]}` }}>
                  <VisibilityOutlined fontSize="small" />
                </IconButton>
              </LightTooltip>
              <LightTooltip title="Open" aria-label="Open">
                <IconButton style={{ color: `${purple[500]}` }}>
                  <LaunchRounded fontSize="small" />
                </IconButton>
              </LightTooltip>
              <LightTooltip title="Download" aria-label="Download">
                <IconButton style={{ color: `${green[500]}` }}>
                  <GetAppRounded fontSize="small" />
                </IconButton>
              </LightTooltip>
              <LightTooltip title="Delete" aria-label="Delete">
                <IconButton style={{ color: `${red[500]}` }}>
                  <DeleteOutlineRounded fontSize="small" />
                </IconButton>
              </LightTooltip>
            </Box>
          ),
          customHeadLabelRender: (cm) => (
            <Box display="flex" justifyContent="center">
              {cm.label}
            </Box>
          ),
        },
      },
    ];

    return (
      <Grid container justify="center">
        <Grid item xs={12} sm={11}>
          <Box my={3}>
            <DatePicker />
          </Box>
        </Grid>
        <Grid item sm={11} xs={12}>
          <MuiThemeProvider theme={this.changeTableStyle()}>
            <MUIDataTable
              title={
                <Box display="flex" alignItems="center">
                  <DashboardOutlined fontSize="small" />
                  <Typography variant="inherit">
                    &nbsp;&nbsp;&nbsp; Templates
                  </Typography>
                </Box>
              }
              data={this.state.data}
              columns={columns}
              options={options}
            />
          </MuiThemeProvider>
        </Grid>
      </Grid>
    );
  }
}

//=====================================
//  CONNECT
//-------------------------------------

const mapDispatchToProps = {};

const mapStateToProps = (state) => {
  return {};
};

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(MyTemplates);
