import { Box, Button, Divider, FormControlLabel, makeStyles, Snackbar, Switch, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { UiActions } from 'globalUI'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(2),
    textTransform: 'none'
  }
}))

const Stamp = () => {
  const classes = useStyles()
  const [successAlert, setSuccessAlert] = useState(false)

  const { register, handleSubmit, watch, control, errors, setError, clearErrors, getValues } = useForm({
    defaultValues: {},
    mode: 'onBlur'
  })

  const onSubmit = (data) => {
    // console.log(data);
    setSuccessAlert(true)
  }

  return (
    <>
      <Snackbar open={successAlert} autoHideDuration={3000} onClose={() => setSuccessAlert(false)}>
        <Alert onClose={() => setSuccessAlert(false)} severity='success'>
          User info updated successfully.
        </Alert>
      </Snackbar>
      <Box mt={3} mb={2}>
        <Typography variant='body2' color='textSecondary' gutterBottom>
          Activate Stamp access here!
        </Typography>
      </Box>
      <Divider />
      <Box my={3} mx={1} width={'50%'} display='flex' flexDirection='column' style={{ gap: 10 }}>
        <Controller
          render={({ onChange, onBlur, value, name, ref }, { invalid, isTouched, isDirty }) => (
            <FormControlLabel
              control={<Switch onBlur={onBlur} onChange={(e) => onChange(e.target.checked)} checked={value} inputRef={ref} />}
              label=' Activate Stamping'
            />
          )}
          control={control}
          name={`activateStamping`}
          defaultValue={false}
        />
        <Controller
          render={({ onChange, onBlur, value, name, ref }, { invalid, isTouched, isDirty }) => (
            <FormControlLabel
              control={<Switch onBlur={onBlur} onChange={(e) => onChange(e.target.checked)} checked={value} inputRef={ref} />}
              label=' Activate Self-Upload (Series 00)'
            />
          )}
          control={control}
          name={`activateSelfUpload`}
          defaultValue={false}
        />
      </Box>
      <Divider />
      <Button variant='contained' color='primary' className={classes.button} onClick={handleSubmit(onSubmit)}>
        Update
      </Button>
    </>
  )
}

const mapStateToProps = (state) => ({
  createData: state.ui.createData,
  inviteeData: state.ui.inviteData
})

const mapDispatchToProps = {
  setCreateData: UiActions.setCreateData,
  setInviteeData: UiActions.setInviteData
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Stamp))
