import {
  CANCEL_MODAL,
  CANCEL_SCHEDULE_MODAL,
  GENERATE_TOKEN_FAILURE,
  MODAL_STATE,
  REVOKE_TOKEN_FAIURE,
  SUCCESS_MODAL_STATE,
  TOKEN_LIST_FAILURE,
  CANCEL_CHANGE_MODAL,
  CHANGE_PLAN_MODAL,
    CLEAR_OTP,
  SET_DEMO_API_REQUEST_INITIATED,
  SET_DEMO_API_REQUEST_FAILURE,
  SET_DEMO_API_REQUEST_SUCCESS, SET_RESPONSE, SET_RESPONSE_FAILURE, SET_RESPONSE_BODY
} from 'auth'
import {
  CALL_STARTED,
  FIRST_TIME,
  GET_DETAILS,
  INIT_AUTH,
  INIT_TOKEN,
  INIT_TOKEN_FAILURE,
  INIT_TOKEN_SUCCESS,
  INNER_TOGGLE_DRAWER,
  OTP_SENT_FAILED,
  OTP_SENT_TRUE,
  OTP_SUBMIT_FAILED,
  OTP_SUBMIT_SUCCESS,
  OTP_VERIFIED_TRUE,
  OUTER_TOGGLE_DRAWER,
  REFRESH_TOKEN_FAILED,
  REFRESH_TOKEN_SUCCESS,
  SET_EMAIL,
  SET_FULL_NAME,
  SET_PHONE_NUMBER,
  SIGN_IN_SUCCESS,
  SIGN_OUT_SUCCESS,
  SUBMIT_DETAILS,
  TOKEN_LIST,
  IS_LOADER,
  CLEAR_ERROR_MESSAGE,
  CLEAR_STATUS_CODE
} from './action-types'

export function modalState() {
  return {
    openModal: false,
    successModal: false,
    cancelModalState: false,
    cancelSchedule: false
  }
}

export function modalReducer(state = new modalState(), { payload, type }) {
  switch (type) {
    case MODAL_STATE:
      return {
        ...state,
        openModal: payload
      }
    case SUCCESS_MODAL_STATE:
      return {
        ...state,
        successModalOpen: payload
      }
    case CANCEL_MODAL:
      return {
        ...state,
        cancelModalState: payload
      }
    case CANCEL_SCHEDULE_MODAL:
      return {
        ...state,
        cancelSchedule: payload
      }
    case CHANGE_PLAN_MODAL:
      return {
        ...state,
        changePlanModal: payload
      }
    case CANCEL_CHANGE_MODAL: {
      return {
        ...state,
        cancelChangeModal: payload
      }
    }
    case SIGN_OUT_SUCCESS:
      return new modalState()

    default:
      return state
  }
}

export function AuthState() {
  return {
    authenticated: false,
    onboarded:false,
    id: null,
    clientId: '',
    otpSent: false,
    otpVerified: false,
    statusCode: '',
    onboardRequired: false, // onboard = is new user (i.e sign in required?)
    phone_number: '',
    email: '',
    fullname: '',
    errorMsg: '',
    firstTime: false,
    accessToken: '',
    mca_credentials: false,
    innerToggleDrawer: false,
    outerToggleDrawer: true,
    initLoading: false,
    sendOtpLoader:false,
    submitOtpLoader:false,
    generatedLink: '',
    tokenLists: null,
    loader: false,
    responseFromDemoAPI: {},
    demoAPILoading: false,
    responseBody:null
  }
}

export function authReducer(state = new AuthState(), { payload, type }) {
  switch (type) {
    case IS_LOADER:
      return {
        ...state,
        loader: payload
      }

    case SET_DEMO_API_REQUEST_INITIATED:
      return {
        ...state,
        demoAPILoading: true,
        responseFromAPI: {},
        errorMsg: '',
        statusCode: ''
      }
    case SET_RESPONSE_FAILURE:
      return {
        ...state,
        demoAPILoading: false,
        responseFromAPI: {},
        statusCode: payload ? payload.data.status_code : false,
        errorMsg: payload ? payload.data.message : ''
      }
    case SET_RESPONSE_BODY:return{
      ...state,
      responseBody: payload
    }

    case SET_RESPONSE:
      console.log("payload",payload)
      return {
        ...state,
        demoAPILoading: false,
        responseFromAPI: payload?payload : {},

        statusCode: '',
        errorMsg: ''
      }
    case CLEAR_STATUS_CODE:
      return {
        ...state,

      }
    case CLEAR_OTP:return {
      ...state,
      otpSent: false
    }
    case CLEAR_ERROR_MESSAGE:
      return {
        ...state,
        errorMsg: '',
        statusCode: '',
      }

    case INIT_AUTH:
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        authenticated: !payload.data.onboard_required,
        sendOtpLoader: false,
        // authenticated: true,
        // id: payload ? payload.uid : null
      }
    case GET_DETAILS:
      console.log("Payload",payload)
      return {
        ...state,
        fullname: payload ? payload.data.name : null,
        email: payload ? payload.data.email : null,
        phone_number: payload ? payload.data.mobile : null,

        onboardRequired:payload.data?.onboard_required,
        authenticated:payload?.data?.onboarded,
        onboarded:payload.data.onboarded

      }
    case SIGN_OUT_SUCCESS:
      return new AuthState()

    case OTP_SENT_TRUE:
      return {
        ...state,
        otpSent: payload ? payload.data.otp_sent : false,
        clientId: payload ? payload.data.client_id : '',
        sendOtpLoader: false,
        errorMsg: '',
        statusCode: false
      }
    case OTP_SENT_FAILED:
      return {
        ...state,
        statusCode: payload ? payload.data.status_code : false,
        errorMsg: payload ? payload.data.message : '',
        sendOtpLoader: false,
      }
    case OTP_SUBMIT_SUCCESS:
      console.log("Payload",payload)
      return {
        ...state,
        otpVerified: payload ? payload.data.verified : false,
        onboardRequired: payload ? payload.data.onboard_required : false,
        accessToken: payload ? payload.data.access_token : '',
        sendOtpLoader: false,
        errorMsg: '',
        statusCode: false
      }
    case CALL_STARTED:
      return {
        ...state,
        sendOtpLoader: true,
        errorMsg: '',
        statusCode: false
      }
    case OTP_SUBMIT_FAILED:
      return {
        ...state,
        otpVerified: false,
        statusCode: payload ? payload.data.status_code : false,
        errorMsg: payload ? payload.data.message : '',
        sendOtpLoader: false,
      }
    case OTP_VERIFIED_TRUE:
      return {
        ...state,
        otpVerified: payload ? payload.otpVerified : ''
      }
    case SUBMIT_DETAILS:
      return {
        ...state,
        authenticated: payload ? payload.data.success : '',
        firstTime: payload ? payload.firstTime : false,
        errorMsg: '',
        statusCode: false
      }

    case REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        accessToken: payload ? payload.data.access_token : '',
        errorMsg: '',
        statusCode: false
      }
    case REFRESH_TOKEN_FAILED:
      return {
        ...state,
        // accessToken : payload ? payload.data.data.access_token : "",
        statusCode: payload ? payload.data.status_code : false,
        errorMsg: payload ? payload.data.message : '',
        authenticated: false
      }
    case GENERATE_TOKEN_FAILURE:
      return {
        ...state,
        statusCode: payload ? payload.data.status_code : false,
        errorMsg: payload ? payload.data.message : ''
      }
    case SET_PHONE_NUMBER:
      return {
        ...state,
        phone_number: payload
      }

    case FIRST_TIME:
      return {
        ...state,
        firstTime: payload
      }
    case SET_FULL_NAME:
      return {
        ...state,
        fullname: payload
      }
    case SET_EMAIL:
      return {
        ...state,
        email: payload
      }

    case INNER_TOGGLE_DRAWER:
      return {
        ...state,
        innerToggleDrawer: payload
        // outerToggleDrawer:!payload
      }
    case OUTER_TOGGLE_DRAWER:
      return {
        ...state,
        outerToggleDrawer: payload
      }
    case INIT_TOKEN:
      return {
        ...state,
        initLoading: true,
        generatedLink: '',
        errorMsg: ''
      }
    case INIT_TOKEN_SUCCESS:
      return {
        ...state,
        initLoading: false,
        generatedLink: payload.data.url,
        errorMsg: ''
      }
    case INIT_TOKEN_FAILURE:
      return {
        ...state,
        initLoading: false,
        generatedLink: '',
        errorMsg: payload.data.message
      }
    case TOKEN_LIST:
      return {
        ...state,
        tokenLists: payload.data
      }
    case TOKEN_LIST_FAILURE:
      return {
        ...state,
        errorMsg: payload ? payload.data.message : '',
        statusCode: payload ? payload.data.status_code : false
      }
    case REVOKE_TOKEN_FAIURE:
      return {
        ...state,
        errorMsg: payload ? payload.data.message : '',
        statusCode: payload ? payload.data.status_code : false
      }
    case GENERATE_TOKEN_FAILURE:
      return {
        ...state,
        errorMsg: payload ? payload.data.message : '',
        statusCode: payload ? payload.data.status_code : false
      }
    default:
      return state
  }
}
