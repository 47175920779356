import React from 'react'
import { Redirect, Route } from 'react-router-dom'

const RequireAuthRoute = ({ component: Component, authenticated, ...rest }) => {
  // console.log("Component",Component)

  return (
    <Route
      {...rest}
      render={(props) => {
        return authenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location }
            }}
          />
        )
      }}
    />
  )
}

export default RequireAuthRoute
