import React, { useEffect, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { compose } from 'redux'

import {
  Grid,
  Typography,
  Button,
  Container,
  Grow,
  Box,
  Paper,
  Divider,
  IconButton,
  Snackbar,
  CircularProgress,
  Modal,
  Chip,
  Tabs,
  Tab,
  AppBar,
  TextField,
  InputAdornment,
  Collapse,
  InputLabel,
  FormControlLabel,
  Checkbox,
  ListItem
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import Generator from '../../containers/code-generator'
import AddIcon from '@material-ui/icons/Add'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { useDispatch, useSelector } from 'react-redux'
import LinearProgressBar from './ProgressBar'
import moment from 'moment'
import {
  cancelSubscriptionPlan,
  checkOut,
  getApiUsageDetail,
  getCurrentPlanDetails,
  getPlanID,
  getProfileDetails,
  setInitialCheckout
} from 'getDetails/actions'
import { cancelModal, clearError, successModal, tokenList, changeModal } from 'auth/actions'
import { Check, Clear, ExpandLess, ExpandMore, Lock } from '@material-ui/icons'
import Plan from '../account/Plan'

import LineChart from './Charts/charts'
import array from './data'

import { FileCopyOutlined } from '@material-ui/icons'
import ModalBody from '../modalPage/cancelModal'
import { CANCEL_SCHEDULE_MODAL } from 'auth'
import ScheduleCancelModal from '../modalPage/cancelSchedule'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import { CheckCircle } from '@material-ui/icons'
import classNames from 'classnames'
import NotificationBar from './NotificationBar'
import Body from 'views/containers/sidenav/Body'
import { setDemoAPIToken } from '../../../utils/axios-instance'
const useStyles = makeStyles((theme) => ({
  main: {
    padding: theme.spacing(2)
  },
  title: {
    fontWeight: '800',
    fontSize: '1.2rem',
    paddingBottom: theme.spacing(0.5),
    fontFamily: 'Baloo Chettan 2, cursive'
  },
  linkContainer: {
    backgroundColor: '#f3f3f329',
    padding: '0 .25rem',
    borderRadius: '10px'
  },
  box: {
    // padding: theme.spacing(1),
    // paddingTop: theme.spacing(2),
    // paddingLeft: theme.spacing(3.5),
    padding: '1px 0px 0px 20px'
  },
  button: {
    backgroundColor: '#00D97C',
    color: '#fff',

    textTransform: 'capitalize',
    fontWeight: '600',
    transition: 'all .2s ease-in',

    '&:hover': {
      backgroundColor: '#00D97C',
      transform: 'scale(1.02)'
    }
  },
  addHeaderBtn:{
    "&:hover":{
      color:"#221c53",
      fontWeight:"600",
      backgroundColor:"#fff",
      border:'1px solid #84838e'
    }
  },
  span: {
    color: '#abb2bf !important',
    fontSize: '16px',
    fontFamily: 'Consolas, monaco, monospace !important'
  },
  span2: {
    color: '#94e15c !important',
    fontSize: '16px',
    fontFamily: 'Consolas, monaco, monospace !important'
  },
  span1_generator:{
    color: '#fff !important',
    fontSize: '16px',
    fontFamily: 'Consolas, monaco, monospace !important'
  },
  btn1: {
    padding: '10px',
    width: '80%',
    maxWidth: '150px',
    boxShadow: '0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)',
    marginTop: '8px',
    borderRadius: '8px',
    textTransform: 'capitalize',
    textAlign: 'justify',
    // borderColor: "beige",
    fontWeight: '600',

    transition: 'all .2s ease',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: '#fff',
      borderColor: 'transparent'
    }
  },
  btn2: {
    padding: '10px',
    width: '80%',
    maxWidth: '150px',
    boxShadow: '0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)',
    marginTop: '8px',
    borderRadius: '8px',
    textTransform: 'capitalize',
    textAlign: 'justify',
    // borderColor: "beige",
    fontWeight: '600',
    color: '#DF2E2E',
    border: '1px solid #DF2E2E',
    backgroundColor: '#fff',
    transition: 'all .2s ease',
    '&:hover': {
      backgroundColor: '#DF2E2E',
      color: '#fff',
      borderColor: 'transparent'
    }
  },
  btn3: {
    padding: '10px',
    width: '80%',
    boxShadow: '0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)',
    marginTop: '8px',
    borderRadius: '8px',
    textTransform: 'capitalize',
    textAlign: 'justify',
    // borderColor: "beige",
    color: '#111',
    fontWeight: '600',

    transition: 'all .2s ease',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      borderColor: 'transparent'
    }
  },
  copy: {
    color: '#abb2bf !important',
    marginRight: '1rem',

    '&:hover': {
      cursor: 'pointer'
    }
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,

    boxShadow: theme.shadows[5],
    padding: '24px 0px 24px 0px'
  },
  paper1: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    outline: 'none',
    boxShadow: theme.shadows[5],
    padding: '24px 45px 20px'
  },
  modal: {
    backdropFilter: 'blur(1px)'
  },
  paper2: {
    position: 'absolute',
    width: 500,
    backgroundColor: theme.palette.background.paper,

    boxShadow: theme.shadows[5],
    height: '50%'
  },
  checkCircleIcon: {
    color: '#00D97C',
    marginRight: '10px'
  },
  activeSubs: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontWeight: 'bold',
    color: theme.palette.primary.main
  },
  codeHeader: {
    marginTop: '12px',
    marginLeft: '4px',
    marginBottom: '10px'
  },
  point: {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    marginLeft: '6px'
  },
  yellowPoint: {
    backgroundColor: '#ffb700'
  },
  redPoint: {
    backgroundColor: '#ff4040'
  },
  greenPoint: {
    backgroundColor: '#00ae55'
  },
  linkButton: {
    color: '#111',
    // textTransform: 'capitalize'
    // letterSpacing: "1px",
  },
  link: {
    textDecoration: 'underline',
    textTransform: 'capitalize',
    color: '#4263eb',
    fontSize: '.975rem',
    fontWeight: '600',
    '&:hover': {
      textDecoration: 'underline',
      background: 'rgba(66, 99, 235, 0.09)'
    }
    // letterSpacing: "1px",
  },
  pdfLink: {
    textDecoration: 'underline',
    width: '80%',
    textOverflow: 'ellipsis',
    color: '#4263eb',
    fontSize: '.975rem',
    fontWeight: '600',
    '&:hover': {
      textDecoration: 'underline',
      background: 'rgba(66, 99, 235, 0.09)'
    }
  },

  shadowBackground: {
    background: '#fff',
    margin: '10px 20px',
    padding: '20px',
    boxShadow: '0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)',
    borderRadius: '8px',
    border: '1px solid #ccc',
    overFlow: 'auto'
  },
  apiKeyShowBackground:{
    margin: '30px 0px',
    borderRadius: '8px',
    // border: '1px solid #ccc',
    overFlow: 'auto'
  },
  formLabel: {
    color: '#000000d1',
    fontWeight: 'normal',
    fontSize: '15px',
    marginBottom: 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  infoIcon: {
    fontSize: '16px',
    marginLeft: '3px'
  },
  response: {
    // overflow:"scroll",
    height: 'auto'
  },
  demoHead: {
    borderRadius: '4px',
    padding: '10px 20px',
    '&:hover': {
      background: '#cccccc59'
    },
    cursor: 'pointer'
  },
  demoActiveHead: {
    borderRadius: '4px',
    padding: '10px 20px',
    background: theme.palette.primary.main,
    color: '#fff',
    cursor: 'pointer'
  },
  urlBox: {
    '&>.MuiOutlinedInput-adornedStart': {
      paddingLeft: 0
    }
  },
  customTab: {
    '&>pre': {
      backgroundColor: '#fff !important',
      color: '#111 !important',
      maxHeight:"400px"

    },
    '&>pre code':{

    }
  },
  tableCell: {},
  inputTag: {
    // width:"100%"
  },
  sendBtn:{
    "&.MuiButton-containedPrimary:hover":{
      color:"#221c53",
      backgroundColor:"#fff",
      border:"1px solid #221c53",
      fontWeight:"600"

    }
  }
}))
function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  }
}

const Dashboard = ({ props, handleOpenModal }) => {
  const dispatch = useDispatch()

  const {
    apiUsage,
    currentPlan,

    planDetail,

    errormsg,
    statusCode
  } = useSelector((state) => state.clientData)
  const { successModalOpen } = useSelector((state) => state.modalRed)
  const { cancelSubscription } = useSelector((state) => state.planReducer)
  // console.log(cancelSubscription);
  const [modalStyle] = useState(getModalStyle)

  const [id, setId] = useState(0)
  const [langValue, setLangValue] = useState(0)
  const { accessToken, tokenLists, authenticated } = useSelector((state) => state.auth)
  const { cancelModalState } = useSelector((state) => state.modalRed)
  const [successAlert, setSuccessAlert] = useState(false)

  const [openCancelSchedule, setOpenCancelSchedule] = useState(false)
  const { openModal } = useSelector((state) => state.modalRed)

  const [tryDemoOn, setTryDemoOn] = useState(false)
  const [waitInMs, setWaitInMs] = useState('')
  const [scrollCount, setScrollCount] = useState('')
  const [cookie, setCookie] = useState('')
  const [customHeaders, setCustomHeaders] = useState({})
  const [jsScroll, setJsScroll] = useState(false)
  const [jsRendering, setJsRendering] = useState(false)
  const [returnPageSource, setReturnPageSource] = useState(false)

  const curlUrl = `curl --location --request POST '${process.env.REACT_APP_API_BASE_URL}api/v1/templates/render' \
--header 'Authorization: Bearer ${tokenLists?.[0]?.token}' \
--header 'Content-Type: application/json' \
--data-raw '{
    "type": "generate_output",
    "url": "https://example.com",
    "pdf_render_options": {
        "output": "pdf",
        "full_page": false,
        "format": "A4",
        "margin_top": 10,
        "margin_bottom": 10,
        "margin_left": 10,
        "margin_right": 10
    }
}'`

  // const [openModal, setOpenModal] = useState(false);
  const [generateModal, setGenerateModal] = useState(false)
  const [errorMesg, setErrorMesg] = useState('')
  const [urlForTesting, setUrlForTesting] = useState('')
  function truncate(str, n) {
    return str?.length > n ? str.substr(0, n - 1) + '...' : str
  }

  // console.log(
  //   `APi-Usage: ${apiUsage?.data}, CurrentPlan: ${currentPlan?.data}`
  // );

  const classes = useStyles()
  // const removeCancellationScheduled = () => {
  //   // dispatch({
  //   //   type: CANCEL_SCHEDULE_MODAL,
  //   //   payload: true,
  //   // });

  //   setOpenCancelSchedule(true);
  // };

  const handleClose = () => {
    // setOpenModal(false);
    // setOpenCancelSchedule(false);
  }
  const handleCloseModal = () => {
    setGenerateModal(false)
  }

  const CustomTabs = withStyles({
    root: {
      // borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
      background: 'none'
    }
  })(Tabs)

  const CustomItemTab = withStyles((theme) => ({
    root: {
      textTransform: 'none',

      minWidth: 72,
      fontWeight: theme.typography.fontWeightBold,
      marginRight: theme.spacing(2),
      color: theme.palette.primary.main,
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(','),
      '&:hover': {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightBold,
        opacity: 1,
        background: '#edf2fe',
        borderRadius: '18px'
      },
      '&$selected': {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightBold,
        background: '#edf2fe',
        borderRadius: '18px'
      },
      '&:focus': {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightBold,
        background: '#edf2fe',
        borderRadius: '18px'
      }
    },
    selected: {}
  }))((props) => <Tab {...props} />)

  const copyToClipBoard = async (copyMe, a) => {
    // console.log("CopyMe", copyMe);
    try {
      await navigator.clipboard.writeText(copyMe)
      setSuccessAlert(true)
    } catch (err) {
      setSuccessAlert(false)
    }
  }

  const handleCancelation = (e) => {
    e.preventDefault()
    dispatch(cancelModal(true))
    dispatch(cancelSubscriptionPlan())

    // setOpenModal(false);
    setTimeout(() => {
      dispatch(getCurrentPlanDetails())
    }, 400)
  }

  const handleModal = (e) => {
    e.preventDefault()
    // setOpenModal(true);
    // dispatch(cancelModal(true));
  }
  const handleGenerateTokenModal = () => {
    // setGenerateModal(true);
    dispatch(changeModal(true))
  }
  useEffect(() => {
    setErrorMesg(errormsg)
  }, [errormsg])

  useEffect(() => {
    let ignore = false

    if (!ignore) {
      // console.log("SuccessMOdal", successModalOpen);
      if (successModalOpen) {
        if (!currentPlan?.data?.plan_id) setTimeout(() => dispatch(getCurrentPlanDetails()), 3000)
      }
    }

    return () => {
      if (currentPlan?.data?.plan_id !== null) {
        // console.log("Ignore", ignore);
        dispatch(successModal(false))
        ignore = true
        // console.log("Current ", currentPlan?.data);
      }
    }
  }, [currentPlan])

  const handleCodeChange = (event, newValue) => {
    setLangValue(newValue)
    console.log(newValue)
  }
  const TabPanel = (props) => {
    const { children, value, index, ...other } = props

    return (
      <div role='tabpanel' hidden={value !== index} id={`wrapped-tabpanel-${index}`} aria-labelledby={`wrapped-tab-${index}`} {...other}>
        {value === index && (
          <Paper
            variant='elevation'
            style={{
              padding: '1px',
              marginTop: '20px',
              width: '100%',
              background: '#0e141a',
              borderRadius: '6px',
              color: '#ffff'
            }}
          >
            <CodeHeader />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {children}
              {tokenLists && (
                <FileCopyOutlined
                  onClick={() => {
                    let a = array.map((datas) => {
                      Object.keys(datas).map((data) => {
                        console.log('langValue', typeof langValue)
                        console.log('data', typeof data)
                        if (Number(data) === langValue) {
                          // console.log(datas[0]);
                          copyToClipBoard(datas[data])
                        }
                      })
                    })

                    setId(2)
                  }}
                  className={classes.copy}
                />
              )}
            </div>
          </Paper>
        )}
      </div>
    )
  }

  const CodeHeader = () => (
    <Grid container className={classes.codeHeader}>
      <Grid item className={classNames(classes.point, classes.redPoint)} />
      <Grid item className={classNames(classes.point, classes.yellowPoint)} />
      <Grid item className={classNames(classes.point, classes.greenPoint)} />
    </Grid>
  )

  useEffect(() => {
    let ignore = false
    // dispatch(getCurrentPlanDetails());
    if (!ignore) {
      if (cancelModalState) {
        if (!currentPlan?.data?.cancelled) {
          setTimeout(() => dispatch(getCurrentPlanDetails()), 2000)
        }
      }
      return () => {
        if (currentPlan?.data?.cancelled === true) {
          // console.log("CurrentPlan", ignore);

          ignore = true
        }
      }
    }
  }, [currentPlan])

  const sendDemoAPIRequest = (e) => {
    if (urlForTesting !== '' && urlForTesting !== null) {
      let obj = {
        type: 'generate_output',
        url: urlForTesting,
        pdf_render_options: {
          output: 'pdf',
          full_page: false,
          format: 'A4',
          margin_top: 10,
          margin_left: 10,
          margin_right: 10
        }
      }
      console.log(obj)
      console.log(tokenLists)
      setDemoAPIToken(tokenLists?.[0]?.token)
      props.sendDemoApiRequest(obj)
    }
  }

  const onChangeValue = (e) => {
    let obj = {
      [e.target.name]: e.target.value
    }
    let newObj = Object.assign(customHeaders, obj)
  }

  return (
    <>
      <Snackbar
        open={!!errorMesg}
        autoHideDuration={3000}
        onClose={() => {
          setErrorMesg(null)
          dispatch(clearError())
        }}
      >
        <Alert onClose={() => setErrorMesg(null)} severity='error'>
          {statusCode}&nbsp;
          {errorMesg}
        </Alert>
      </Snackbar>
      <Snackbar open={successAlert} autoHideDuration={3000} onClose={() => setSuccessAlert(null)}>
        <Alert onClose={() => setSuccessAlert(null)} severity='success'>
          {id === 1 && `Token Copied`}
          {id === 2 && `Curl Copied`}
        </Alert>
      </Snackbar>

      {currentPlan?.data?.change_scheduled === true || currentPlan?.data?.cancelled === true ? <NotificationBar /> : null}

      <Grid container className={classes.main}>
        <Generator props={props} classes={classes} />
        <br />
        <Grid
          container
          item
          xs={12}
          style={{
            marginLeft: '.3rem',
            boxShadow: '0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)',
            padding: '20px 10px',
            borderRadius: '8px',
            background: '#fff',
            border: '1px solid #ccc'
          }}
        >
          <Grid container item xs={12} style={{ marginLeft: '20px', marginBottom: '20px' }}>
            <Grid container alignItems={'center'}>
              <Grid item>
                <Typography style={{ color: '#000' }} variant={'body1'}>
                  Active Subscription
                </Typography>
                {currentPlan?.data?.plan_id && (
                  <Typography variant={'caption'}>
                    {`Your plan will renew at `}
                    {currentPlan?.data?.expiry && moment(currentPlan?.data?.expiry).format('DD MMM YYYY')}
                  </Typography>
                )}
              </Grid>
              <Grid item style={{ marginLeft: 20 }}>
                <Chip
                  icon={<Check />}
                  color={'primary'}
                  label={currentPlan?.data?.plan_id === null ? `No Active Plan` : currentPlan?.data?.plan_id.replaceAll('-', ' ')}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} justifyContent='space-between' alignItems='center' style={{ marginTop: '1rem' }}>
              <Grid item>
                <Typography
                  variant='h6'
                  style={{
                    fontWeight: '600',
                    fontSize: '1.2rem',
                    paddingTop: '.2rem'
                  }}
                  color='textPrimary'
                >
                  Credits
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='caption' color='textPrimary' style={{ paddingTop: '.3rem', fontSize: '.875rem' }}>
                  Used &nbsp;
                  {currentPlan?.data && (
                    <span style={{ fontWeight: '600', fontSize: '.875rem' }}>
                      {currentPlan?.data?.credits === 0
                        ? currentPlan?.data?.total_credits
                        : currentPlan?.data?.total_credits - currentPlan?.data?.credits}
                      &nbsp; of {currentPlan?.data?.total_credits}
                    </span>
                  )}
                  {currentPlan?.data?.length === 0 && `0 of 0`}
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={12} style={{ paddingTop: '.2rem' }}>
              <LinearProgressBar
                co
                value={
                  currentPlan?.data?.credits === 0
                    ? currentPlan?.data?.credits
                    : ((currentPlan?.data?.total_credits - currentPlan?.data?.credits) / currentPlan?.data?.total_credits) * 100
                }
              />
            </Grid>
          </Grid>
          <br />
          <LineChart apiUsage={apiUsage} />
        </Grid>
      </Grid>

      <Box>
        <Grid container direction='column'>
          <Grid item xs={12} className={classes.shadowBackground}>
            <Typography
              variant='body1'
              component='div'
              style={{
                // paddingTop: "1rem",
                // paddingLeft: "1rem",
                color: '#111',
                fontSize: '2rem',
                fontWeight: '400'
              }}
              gutterBottom
            >
              API Key
            </Typography>
            {tokenLists?.length > 0 && (
              <Paper
                variant='elevation'
                style={{
                  width: '100%',
                  borderRadius: '6px',
                  background: '#0e141a',
                  color: '#ffff',
                  paddingTop: 1
                }}
              >
                <CodeHeader />
                <Grid container>
                  <div
                    style={{
                      marginRight: '20px',
                      textAlign: 'right',
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Typography
                      container='div'
                      style={{
                        overflowX: 'auto',
                        fontSize: '14px',
                        padding: '.5rem',
                        display: 'flex',
                        justifyContent: 'space-between',
                        fontFamily: 'Consolas, monaco, monospace'
                      }}
                      className={classes.span2}
                    >
                      {truncate(tokenLists?.[0]?.token, 70)}&nbsp;{' '}
                    </Typography>
                    {tokenLists && (
                      <FileCopyOutlined
                        onClick={() => {
                          copyToClipBoard(tokenLists?.[0]?.token)
                          setId(1)
                        }}
                        className={classes.copy}
                      />
                    )}
                  </div>
                </Grid>
              </Paper>
            )}

            {tokenLists?.length === 0 && (
              <Grid container className={classes.linkContainer}>
                <Typography variant='body1' className={classes.linkButton}>
                  Sorry, you don't have any API key right now&nbsp;
                  <Button variant='text' className={classes.link} onClick={handleGenerateTokenModal}>
                    Click to generate
                  </Button>
                </Typography>
              </Grid>
            )}
          </Grid>

          {generateModal && (
            <Modal open={openModal} onCLose={() => handleCloseModal()}>
              <Body
                open={openModal}
                // setGenerateModal={setGenerateModal}
                handleCloseModal={handleCloseModal}
              />
            </Modal>
          )}
        </Grid>
      </Box>
    </>
  )
}

export default React.memo(Dashboard)
