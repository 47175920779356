import React from 'react'
import { Grid, makeStyles, Typography, Container, Paper, Button } from '@material-ui/core'
import errorIcon from './assets/errorIcon.png'
const useStyles = makeStyles((theme) => ({
  billing: {
    height: '60%'
  },

  errorBiling: {
    fontSize: '1rem',

    padding: '4px 0px'
  },
  errorMessage: {
    margin: '10px auto'
  },
  errorLogo: {
    display: 'block',
    margin: '0 auto'
  }
}))
const ErrorPage = ({ errorCode, message }) => {
  const classes = useStyles()
  return (
    <Container>
      <Paper variant='elevation'>
        <Grid container>
          <Grid container item>
            <Typography variant='textSecondary'>404</Typography>
            <Grid item>
              <Typography variant='h5'>Error</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Typography>Sorry we're unable to find the page you're looking for</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Button container='variant' color='primary' onClick={() => {}}>
            Go to Dashboard
          </Button>
        </Grid>
      </Paper>
    </Container>
  )
}

export default ErrorPage
