import { AppBar, Box, Grid, isWidthUp, Tab, Tabs, Typography, withWidth } from "@material-ui/core";
import { CancelScheduleSendRounded, MoreHoriz, TrackChangesRounded, TrendingDownRounded } from '@material-ui/icons';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import DatePicker from 'views/components/date-picker';
import DocTable from 'views/components/docTable';



function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
        style: { textTransform: "none", marginBottom: "-4px" },
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Box display="flex" overflow="hidden"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
        >
            {value === index && (<>{children}</>)}
        </Box>
    );
}

function Title(props) {
    const { tabValue, handleChangeIndex, ...other } = props;

    return (
        <SwipeableViews
            // axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            axis={'x'}
            index={tabValue}
            onChangeIndex={handleChangeIndex}
        >
            {/* <> */}
            <TabPanel value={tabValue} index={0}>
                <>Action Required</>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <>Wating for Others</>
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
                <>Expiring Soon</>
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
                <>Failed</>
            </TabPanel>
            {/* </> */}
        </SwipeableViews>
    )
}


class MyDesk extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 0,
            data: [
                { id: "341512", name: "myDoc.pdf", state: "draft", folder: null, owner: "Me", createdAt: "2020-06-18T18:07:01.560000" },
                { id: "341513", name: "myDoc.pdf", state: "sent", folder: null, owner: "Me", createdAt: "2020-01-15T18:08:01.560000" },
                { id: "341514", name: "myDoc.pdf", state: "completed", folder: null, owner: "Me", createdAt: "2019-11-28T18:09:01.560000" },
                { id: "341515", name: "myDoc.pdf", state: "recieved", folder: null, owner: "Me", createdAt: "2020-12-18T18:07:01.560000" },
                { id: "341516", name: "myDoc.pdf", state: "draft", folder: null, owner: "Me", createdAt: "2020-07-15T18:07:01.560000" },
                { id: "341517", name: "myDoc.pdf", state: "expired", folder: null, owner: "Me", createdAt: "2010-02-18T18:07:01.560000" },
                { id: "341518", name: "myDoc.pdf", state: "draft", folder: null, owner: "Me", createdAt: "2020-11-18T18:07:01.560000" },
                { id: "341519", name: "myDoc.pdf", state: "completed", folder: null, owner: "Me", createdAt: "2020-10-18T20:07:01.560000" },
                { id: "341510", name: "myDoc.pdf", state: "draft", folder: null, owner: "Me", createdAt: "2020-11-18T18:02:01.560000" },
                { id: "341521", name: "myDoc.pdf", state: "completed", folder: null, owner: "Me", createdAt: "2020-11-12T18:28:01.560000" },
                { id: "341522", name: "myDoc.pdf", state: "expired", folder: null, owner: "Me", createdAt: "2020-11-18T04:07:01.560000" },
                { id: "341523", name: "myDoc.pdf", state: "draft", folder: null, owner: "Me", createdAt: "2020-01-23T18:07:01.560000" },
                { id: "341524", name: "myDoc.pdf", state: "completed", folder: null, owner: "Me", createdAt: "2020-06-18T18:02:01.560000" },
                { id: "341525", name: "myDoc.pdf", state: "sent", folder: null, owner: "Me", createdAt: "2020-11-11T18:07:01.560000" },
            ],
        };

    }

    handleChange = (_, newValue) => {
        // don't remove _
        this.setState({ tabValue: newValue });
    };
    // differnt handler don't change
    handleChangeIndex = (index) => {
        this.setState({ tabValue: index });
    };


    render() {
        const { tabValue, data } = this.state;
        const { classes, width } = this.props;

        return (
            <div>
                {/* <Paper variant="outlined" square > */}
                <AppBar position="static" color="inherit" elevation={0} variant="outlined">
                    <Tabs
                        value={tabValue}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        scrollButtons="auto"
                        variant={isWidthUp('md', width) ? "fullWidth" : "scrollable"}
                        style={{ margin: "-1px" }}
                    >
                        <Tab label={
                            <Box display="flex" p={1} >
                                <TrackChangesRounded color="inherit" /> &nbsp;&nbsp;&nbsp;
                                <Typography variant="body1">Action Required</Typography>
                            </Box>
                        }  {...a11yProps(0)} />
                        <Tab label={
                            <Box display="flex" p={1} >
                                <MoreHoriz /> &nbsp;&nbsp;&nbsp;
                                <Typography variant="body1" >Wating for Others</Typography>
                            </Box>
                        }  {...a11yProps(1)} />
                        <Tab label={
                            <Box display="flex" p={1} >
                                <TrendingDownRounded /> &nbsp;&nbsp;&nbsp;
                                <Typography variant="body1" >Expiring Soon</Typography>
                            </Box>
                        } {...a11yProps(2)} />
                        <Tab label={
                            <Box display="flex" p={1} >
                                <CancelScheduleSendRounded /> &nbsp;&nbsp;&nbsp;
                                <Typography variant="body1" >Failed</Typography>
                            </Box>
                        }  {...a11yProps(3)} />
                    </Tabs>
                </AppBar>

                {/* </Paper> */}

                <Grid container justify="center" alignItems="center">
                    <Grid container item xs={11}>
                        <Grid item xs={12}>
                            <Box my={2.5} >
                                <DatePicker />
                            </Box>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={12}>
                                <DocTable
                                    title={<Title tabValue={tabValue} handleChangeIndex={this.handleChangeIndex} />}
                                    data={data}
                                    mydesk={true}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}



//=====================================
//  CONNECT
//-------------------------------------

const mapDispatchToProps = {};

const mapStateToProps = (state) => {
    return {}
}


export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withWidth()(MyDesk))
);

