import axios from 'axios'
import { axiosinstance, demoAPIRequest } from '../utils/axios-instance'
import { digilockerInstance } from '../utils/digilocker-instance'

export function sentOtpCall(obj) {
  return axiosinstance.post('api/v1/users/send-otp', obj)
}

export function refreshTokenCall() {
  return axiosinstance.post('api/v1/users/refresh-token')
}

export function googleLoginCall(obj) {
  return axiosinstance.post('api/v1/users/login', obj)
}

export function submitOtpCall(obj) {
  return axiosinstance.post('api/v1/users/submit-otp', obj)
}

export function sendDemoApiRequestCall(obj) {
  return demoAPIRequest.post('/api/v1/templates/render', obj)
}

export function initTokenCall(obj) {
  return digilockerInstance.post('/api/v1/digilocker/initialize', obj)
}

export function verifyOtpCall(otp) {
  return axiosinstance.post('/api/v1/verifyOtp', {
    otp
  })
}

export function verifyDetailsCall(obj) {
  return axiosinstance.post('/api/v1/verifyDetails', obj)
}

export function submitUserDetailsCall(obj) {
  return axiosinstance.post('api/v1/users/onboard', obj)
}

// export function getDetailsCall(client_id) {
//   return axiosinstance.get(`api/v1/esign/realm/details/${client_id}`)
// }

export function logoutCall() {
  return axiosinstance.post('api/v1/users/logout')
}

export const razorpayAxiosInstance = axios.create({
  baseURL: 'https://api.razorpay.com',
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json'
  }
})

export function signIn(username, password) {
  return axiosinstance.post('/manager/tokens/login', {
    email: username,
    password: password
  })
}

export function register(name, email, password) {
  return axiosinstance.post('/manager/tokens/confirm-user', {
    name: name,
    email: email,
    password: password,
    'redirect-uri': 'https://dashboard.erascan.com/confirm-user'
  })
}

export function forget(email) {
  return axiosinstance.post('/manager/tokens/forgot-password', {
    email: email,
    'redirect-uri': 'https://dashboard.erascan.com/confirm-password'
  })
}

export function updateEmailCall(currentEmail, newEmail) {
  return axiosinstance.post('/manager/tokens/change-email', {
    'new-email': newEmail,
    'current-email': currentEmail,
    'redirect-uri': 'https://dashboard.erascan.com/confirm-email'
  })
}

export function confirmUserCall(token) {
  return axiosinstance.post('/manager/user', {
    token: token
  })
}

export function confirmEmailCall(token) {
  return axiosinstance.post('/manager/user/email', {
    token: token
  })
}

export function confirmPasswordCall(password, token) {
  return axiosinstance.post('/manager/user/password', {
    token: token,
    password: password
  })
}

export function generateServerToken(value) {
  return axiosinstance.post('/api/v1/users/create-server-token', value)
}
export function getListToken() {
  return axiosinstance.get('/api/v1/users/list-tokens')
}
export function revokeToken(value) {
  return axiosinstance.post(`/api/v1/users/revoke-token?api_key=${value}`)
}
export function chargeebeeUrlApi(){
  return axiosinstance.get("/api/v1/users/chargebee-frontend-data");
}