import {
  CANCEL_CHANGE_PLAN,
  CANCEL_CHANGE_PLAN_FAILURE,
  CANCEL_SUBSCRIPTION,
  CANCEL_SUBSCRIPTIon,
  CHANGE_PLAN,
  CHANGE_PLAN_FAILURE,
  CHECKOUT_DETAIL_FAILURE,
  FIRST_POPUP,
  REMOVE_CANCEL_SCHEDULE,
  REMOVE_CANCEL_SCHEDULE_FAILURE,
  ESTIMATE_REQ_COST_PRICE, ESTIMATE_REQ_COST_PRICE_FAILURE, SET_RESPONSE
} from 'getDetails'
import { SIGN_OUT_SUCCESS, CLEAR_ERROR_MESSAGE, CLEAR_STATUS_CODE ,CLEAR_CHECKOUT_DATA} from '../auth/action-types'
import {
  BILLING_DETAIL,
  BILLING_DETAIL_FAILURE,
  CHECKOUT_DETAIL,
  CLIENTS_LIST,
  CLIENT_DETAILS,
  INITIAL_CHECKOUT,
  PLANDETAIL,
  PLAN_ID,
  PROFILE_DETAIL,
  AUTH_LOG,
  AUTH_LOG_FAILURE,
  API_USAGE,
  API_USAGE_FAILURE,
  CURRENT_PLAN_FAILURE,
  PLANDETAIL_FAILURE,
  PROFILE_DETAIL_FAILURE,
  CURRENT_PLAN
} from './action-types'

export function planState() {
  return {
    cancelSubscription: null,
    cancelRemoveSchedule: null,
    changePlanData: null,
    cancelChangePlan: null,
    errormesg: '',
    status_code: ''
  }
}

export function clientState() {
  return {
    clientsList: null,
    clientDetails: null,
    profileData: null,
    userName: '',
    planDetail: [],
    checkout: null,
    initialCheckOut: false,
    planId: null,
    billingDetail: null,
    authLogs: null,
    // billingDetailFailure: null,
    authLogFailure: null,
    apiUsage: null,
    currentPlan: null,
    errormsg: '',
    statusCode: '',
    requestCostPrice:null,
    responseData:null,
  }
}

export function planReducer(state = new planState(), { payload, type }) {
  switch (type) {
    case CANCEL_SUBSCRIPTION:
      return {
        ...state,
        cancelSubscription: payload
      }
    case CHANGE_PLAN:
      // console.log("Payload",payload)
      return {
        ...state,
        changePlanData: payload
      }
    case CANCEL_CHANGE_PLAN:
      return {
        ...state,
        cancelChangePlan: payload
      }
    case REMOVE_CANCEL_SCHEDULE:
      return {
        ...state,
        cancelRemoveSchedule: payload
      }

    case REMOVE_CANCEL_SCHEDULE_FAILURE:
      return {
        ...state,
        status_code: payload ? payload.data.status_code : false,
        errormesg: payload ? payload.data.message : ''
      }
    case CHANGE_PLAN_FAILURE:
      return {
        ...state,
        status_code: payload ? payload.data.status_code : false,
        errormesg: payload ? payload.data.message : ''
      }
    case CANCEL_CHANGE_PLAN_FAILURE:
      return {
        ...state,
        status_code: payload ? payload.data.status_code : false,
        errormesg: payload ? payload.data.message : ''
      }
    case CLEAR_ERROR_MESSAGE:
      return {
        ...state,
        errormesg: '',
        status_code: ''
      }


    case ESTIMATE_REQ_COST_PRICE_FAILURE:{
      return {
        ...state,
        status_code: payload ? payload.data.status_code : false,
        errormesg: payload ? payload.data.message : ''
      }
    }
    case SIGN_OUT_SUCCESS:
      return new planState()

    default:
      return state
  }
}

export function planPopState() {
  return {
    first: false
  }
}

export function planPopReducer(state = new planPopState(), { payload, type }) {
  switch (type) {
    case FIRST_POPUP:
      return {
        ...state,
        first: payload
      }
    case SIGN_OUT_SUCCESS:
      return new planPopState()
    default:
      return state
  }
}

export function clientsReducer(state = new clientState(), { payload, type }) {
  switch (type) {
    case SIGN_OUT_SUCCESS:
      // console.log("Called");
      return new clientState()
    case CLEAR_ERROR_MESSAGE:
      return {
        ...state,
        errormsg: payload,
        statusCode: payload
      }
    case CLIENTS_LIST:
      return {
        ...state,
        clientsList: payload.data
      }

    case SET_RESPONSE:return {
      ...state,
      responseData: payload
    }
    case CLIENT_DETAILS:
      return {
        ...state,
        clientDetails: payload.data
      }
    case PLANDETAIL:
      return {
        ...state,

        planDetail: payload
      }

    case ESTIMATE_REQ_COST_PRICE:
      console.log("payload",payload)
      return {
        ...state,
        requestCostPrice:payload.data?.balance_required
      }
    case CHECKOUT_DETAIL:
      // console.log("Payload", payload);
      return {
        ...state,
        checkout: payload
        // initialCheckOut: !state.initialCheckOut,
      }
    case CLEAR_CHECKOUT_DATA:return {
      ...state,
      checkout: null
    }

    case CHECKOUT_DETAIL_FAILURE:
      return {
        ...state,
        statusCode: payload ? payload.data.status_code : false,
        errormsg: payload ? payload.data.message : ''
      }
    case API_USAGE_FAILURE:
      return {
        ...state,
        statusCode: payload ? payload.data.status_code : false,
        errormsg: payload ? payload.data.message : ''
      }
    case PROFILE_DETAIL_FAILURE:
      return {
        ...state,
        statusCode: payload ? payload.data.status_code : false,
        errormsg: payload ? payload.data.message : ''
      }
    case INITIAL_CHECKOUT:
      // console.log("Initial reducerr", payload);
      return {
        ...state,
        initialCheckOut: payload
      }
    case PLAN_ID:
      // console.log("Plan-Id in reducer", payload);

      return {
        ...state,
        planId: payload
      }
    case BILLING_DETAIL:
      return {
        ...state,
        billingDetail: payload
      }
    case BILLING_DETAIL_FAILURE:
      return {
        ...state,
        statusCode: payload ? payload.data.status_code : false,
        errormsg: payload ? payload.data.message : ''
      }
    case PLANDETAIL_FAILURE:
      return {
        ...state,
        statusCode: payload ? payload.data.status_code : false,
        errormsg: payload ? payload.data.message : ''
      }

    case CURRENT_PLAN_FAILURE:
      return {
        ...state,
        statusCode: payload ? payload.data.status_code : false,
        errormsg: payload ? payload.data.message : ''
      }
    case AUTH_LOG:
      return {
        ...state,
        authLogs: payload
      }
    case AUTH_LOG_FAILURE:
      return {
        ...state,
        authLogFailure: payload
      }
    // case BILLING_DETAIL_FAILURE:
    //   return {
    //     ...state,
    //     billingDetailFailure: payload,
    //   };
    case PROFILE_DETAIL:
      const name = payload?.data?.name?.split(' ')
      // console.log("Name", name);
      let firstName
      let middleName
      let lastName
      if (name?.length < 1) {
        firstName = name?.[0]?.slice(0, 1)
        // console.log("FirstName", firstName);
      } else if (name?.length < 2) {
        firstName = name?.[0]?.slice(0, 1)
        lastName = name?.[1]?.slice(0, 1)
        // console.log("Username", firstName, lastName);
      } else {
        firstName = name?.[0]?.slice(0, 1)

        let lastIndex = name?.slice(-1)
        lastName = lastIndex?.[0]?.slice(0, 1)
        // console.log("Username", firstName, lastName);
      }

      return {
        ...state,
        profileData: payload,
        userName: lastName ? `${firstName}${lastName}` : firstName
      }
    case API_USAGE:
      // console.log("API_USAGE", payload);
      return {
        ...state,
        apiUsage: payload
      }
    case CURRENT_PLAN:
      // console.log("CurrentPlan", payload);
      return {
        ...state,
        currentPlan: payload
      }

    case AUTH_LOG_FAILURE:
      return {
        ...state,
        statusCode: payload ? payload.data.status_code : false,
        errormsg: payload ? payload.data.message : ''
      }
    case SIGN_OUT_SUCCESS:
      return new clientState()
    default:
      return state
  }
}
