import axios from 'axios'

const token =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE1ODE5MzU5ODAsImp0aSI6ImYyYTdlNDg0LWRiNzYtNGNjMy1iODFlLTQ5ZWRlNzUyNTZkMSIsInR5cGUiOiJhY2Nlc3MiLCJmcmVzaCI6ZmFsc2UsImV4cCI6MTg5NzI5NTk4MCwiaWRlbnRpdHkiOiJkZXYueW9nZXNoQGFhZGhhYXJhcGkuaW8iLCJuYmYiOjE1ODE5MzU5ODAsInVzZXJfY2xhaW1zIjp7InNjb3BlcyI6WyJyZWFkIl19fQ.665hz5EuAG57OHdtpOrcbQxP6MYfr2sDy4TLuAsLxPo'

export const digilockerInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 15000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  }
})
