export const CLIENTS_LIST = 'CLIENTS_LIST'
export const CLIENT_DETAILS = 'CLIENT_DETAILS'
export const PLANDETAIL = 'PLANDETAIL'
export const PLANDETAIL_FAILURE = 'PLANDETAIL_FAILURE'
export const CHECKOUT_DETAIL = 'CHECKOUT_DETAIL'
export const CHECKOUT_DETAIL_FAILURE = 'CHECKOUT_DETAIL_FAILURE'
export const INITIAL_CHECKOUT = 'INITIAL_CHECKOUT'
export const PLAN_ID = 'PLAN_ID'
export const BILLING_DETAIL = 'BILLING_DETAIL'

export const AUTH_LOG = 'AUTH_LOG'
export const AUTH_LOG_FAILURE = 'AUTH_LOG_FAILURE'
export const BILLING_DETAIL_FAILURE = 'BILLING_DETAIL_FAILURE'
export const PROFILE_DETAIL = 'PROFILE_DETAIL'
export const PROFILE_DETAIL_FAILURE = 'PROFILE_DETAIL_FAILURE'
export const CURRENT_PLAN = 'CURRENT_PLAN'
export const CURRENT_PLAN_FAILURE = 'CURRENT_PLAN_FAILURE'
export const API_USAGE = 'API_USAGE'
export const API_USAGE_FAILURE = 'API_USAGE_FAILURE'
export const CANCEL_SUBSCRIPTION_FAILURE = 'CANCEL_SUBSCRIPTION_FAILURE'
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION'
export const FIRST_POPUP = 'FIRST_POPUP'

export const REMOVE_CANCEL_SCHEDULE = 'REMOVE_CANCE_SCHEDULE'
export const REMOVE_CANCEL_SCHEDULE_FAILURE = 'REMOVE_CANCE_SCHEDULE_FAILURE'

export const CHANGE_PLAN = 'CHANGE_PLAN'
export const CHANGE_PLAN_FAILURE = 'CHANGE_PLAN_FAILURE'

export const CANCEL_CHANGE_PLAN = 'CANCEL_CHANGE_PLAN'
export const CANCEL_CHANGE_PLAN_FAILURE = 'CANCEL_CHANGE_PLAN_FAILURE'

export const ESTIMATE_REQ_COST_PRICE="ESTIMATE_REQ_COST_PRICE"
export const ESTIMATE_REQ_COST_PRICE_FAILURE="ESTIMATE_REQ_COST_PRICE_FAILURE"

export const SET_RESPONSE="SET_RESPONSE"

export const DELETE_DATA = 'DELETE_DATA'
