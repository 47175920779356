import { Box, Button, Collapse, Fade, Grow, isWidthUp, Paper, Popover, Tooltip, withWidth, Zoom } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { TodayRounded } from '@material-ui/icons'
import { withStyles } from '@material-ui/styles'
import moment from 'moment'
import React, { useRef, useState } from 'react'
import { createStaticRanges, DateRangePicker, defaultInputRanges, defaultStaticRanges } from 'react-date-range'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import useOutsideClickHandler from 'utils/useOutsideClick'
import './DateStyle.scss'

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    // boxShadow: theme.shadows[1],
    border: `1px solid ${theme.palette.grey[100]}`,
    fontSize: 11
  }
}))(Tooltip)

const defaultDate = [
  {
    startDate: moment().subtract(1, 'months').toDate(),
    endDate: moment().endOf('day').toDate(),
    key: 'selection'
  }
]

export function DatePicker({ rightAlign = false }) {
  const theme = useTheme()

  const [date, setDate] = useState(defaultDate)

  const [anchorEl, setAnchorEl] = useState(null)
  const [openDatePicker, setOpenDatePicker] = useState(false)

  const toogleDatePicker = (e) => {
    setAnchorEl(anchorEl ? null : e?.currentTarget)
    setOpenDatePicker((prev) => !prev)
  }

  const closeDatePicker = () => {
    setOpenDatePicker(false)
    setAnchorEl(null)
  }

  // sidebar shorthands in date-picker
  const staticRanges = createStaticRanges([
    ...defaultStaticRanges.filter((d) => d.label !== 'Yesterday'),
    {
      label: 'This Year',
      range: () => ({
        startDate: moment().startOf('year').toDate(),
        endDate: moment().endOf('day').toDate()
      })
    },
    {
      label: 'Last Year',
      range: () => ({
        startDate: moment().subtract(1, 'years').startOf('year').toDate(),
        endDate: moment().subtract(1, 'years').endOf('year').toDate()
      })
    },
    {
      label: 'All Time',
      range: () => ({
        startDate: moment().subtract(20, 'years').startOf('year').toDate(),
        endDate: moment().endOf('day').toDate()
      })
    }
  ])

  return (
    <>
      <LightTooltip title='Filter by date' placement={rightAlign ? 'left' : 'right'} enterDelay={500} enterNextDelay={1000}>
        <Button color='primary' size='large' onClick={toogleDatePicker} style={{ textTransform: 'none' }} startIcon={<TodayRounded />}>
          &nbsp;{moment(date[0].startDate).format('ll')} - {moment(date[0].endDate).format('ll')}
        </Button>
      </LightTooltip>
      <Popover
        open={openDatePicker}
        anchorEl={anchorEl}
        onClose={toogleDatePicker}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: rightAlign ? 'right' : 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: rightAlign ? 'right' : 'left'
        }}
        transitionDuration={{ enter: 250, exit: 200 }}
        classes={{ paper: 'datePickerContainer' }}
      >
        <DateRangePicker
          startDatePlaceholder='Start Date'
          endDatePlaceholder='End Date'
          rangeColors={[theme.palette.primary.main]}
          onChange={(item) => setDate([item.selection])}
          showSelectionPreview={false}
          moveRangeOnFirstSelection={false}
          showMonthAndYearPickers={true}
          showDateDisplay={false}
          showPreview={true}
          fixedHeight={true}
          editableDateInputs={false}
          maxDate={new Date()}
          months={2}
          ranges={date}
          staticRanges={staticRanges}
          inputRanges={[defaultInputRanges[0]]}
          direction='horizontal'
        />
        <Box pb={2} px={2} display='flex' justifyContent='flex-end'>
          <Button
            size='small'
            onClick={() => {
              closeDatePicker()
              setDate(defaultDate)
            }}
          >
            Cancel
          </Button>{' '}
          &nbsp;&nbsp;&nbsp;
          <Button size='small' variant='contained' color='primary' onClick={closeDatePicker}>
            Apply
          </Button>
        </Box>
      </Popover>
    </>
  )
}

export default DatePicker
