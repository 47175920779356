import React, { useEffect, useState,useRef } from 'react'
import axios from "axios"
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Divider,
  FormControlLabel,
  Input,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  MuiThemeProvider,
  Paper,
  Snackbar,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core'
import {ExpandLess, ExpandMore, Lock, Info, FileCopy, FileCopyOutlined, Add, DeleteOutline} from '@material-ui/icons'
import SyntaxHighlighter from 'react-syntax-highlighter'

// import { Light as SyntaxHighlighterCode } from 'react-syntax-highlighter';
import { dracula, qtcreatorLight, lightfair, a11yLight, defaultStyle } from 'react-syntax-highlighter/dist/cjs/styles/hljs'
// import prettier from "prettier"
// import parserHtml from "prettier/parser-html"
// import parserBabel from "prettier/parser-babel"

import MUIDataTable from "mui-datatables";
import formatter from "code-formatter";
import { setDemoAPIToken } from '../../../utils/axios-instance'
import { useSelector, useDispatch } from 'react-redux'

import { withStyles } from '@material-ui/styles'
import { createMuiTheme, createTheme } from '@material-ui/core/styles'
import array from '../../pages/home/data'
import classNames from 'classnames'
import { Alert } from '@material-ui/lab'
import {
  changeModal,
  outerToggleDrawer,
  sendApiRequest,

  setOuterToggleDrawer
} from '../../../auth/actions'
import {getReqCostPrice} from "../../../getDetails/actions";
import {clientsReducer} from "../../../getDetails";
import {SET_RESPONSE_BODY,SET_RESPONSE} from "../../../auth";


const CustomizeToolTip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9'
  }
}))(Tooltip)

function truncate(str, n) {
  return str?.length > n ? str.substr(0, n - 1) + '...' : str
}

const ToolTipContainer = ({ classes, label, tooltipText }) => (
  <Typography className={classes.formLabel}>
    {label}
    <CustomizeToolTip
      style={{ fontSize: '16px', color: 'gray' }}
      title={<Typography variant={'caption'}>{tooltipText}</Typography>}
      placement={'top'}
    >
      <Info className={classes.infoIcon} />
    </CustomizeToolTip>
  </Typography>
)

const Generator = ({ props, classes }) => {
  const { accessToken, tokenLists, authenticated, outerToggleDrawer,responseFromAPI,demoAPILoading,responseBody } = useSelector((state) => state.auth)
  // console.log("Access",accessToken)
  const{requestCostPrice}=useSelector(state=>state.clientData)
// console.log("props",props.tokenLists?.[0]?.token)
  const dispatch = useDispatch()

  const [urlForTesting, setUrlForTesting] = useState('https://example.com')
  const [tryDemoOn, setTryDemoOn] = useState(false)
  const [waitInMs, setWaitInMs] = useState('')
  const [jsonResponse, setJsonResponse] = useState(false)
  const [scrollCount, setScrollCount] = useState('')
  const [cookie, setCookie] = useState('')
  let cookie_regex=/^((?:.+)=(?:.+);)$/
  const [customHeaders, setCustomHeaders] = useState({
    header_one: '',
    header_one_value: '',
    header_two: '',
    header_two_value: '',
    header_three: '',
    header_three_value: ''
  })
  const [headers, setHeaders] = useState([
    {
      key: '',
      value: ''
    }
  ])
  const [contentType,setContentType]=useState()
  const [responseCode, setResponseCode] = useState(200)
  const [responseStatus, setResponseStatus] = useState('')
  const [jsScroll, setJsScroll] = useState(false)
  const [screenshot, setScreenshot] = useState(false)
  const [transparentMode, setTransparentMode] = useState(false)
  const [pdf, setPdf] = useState(false)
  const [forwardHeader, setForwardHeader] = useState(false)


  const [jsRendering, setJsRendering] = useState(true)
  const [returnPageSource, setReturnPageSource] = useState(false)
  const [langValue, setLangValue] = useState(0)
  const [responseValue, setResponseValue] = useState(0)
  const [copy, setCopy] = useState(null)
  const [headerData,setHeaderData]=useState({})
  const[cookieError,setCookieError]=useState(false)
  const deviceValue = {
    Desktop: 'desktop',
    Mobile: 'mobile'
  }
  const [device, setDevice] = useState('Desktop')
  const [headerIndex,setHeaderIndex]=useState()
  const proxyTypeValue = {
    '-':'none',
    Auto: 'auto',
    'Data Center': 'data_center',
    Residential: 'residential'
  }
  const [proxyValue, setProxyValue] = useState("-")
  const [bodyData,setBodyData]=useState(``)
const [message,setMessage]=useState(false)
  const [tempResponse, setTempResponse] = useState(false)
  const [showInput,setShowInput]=useState(false)
  const [error,setError]=useState(false);

  const [costPrice, setCostPrice] = useState(0)
  const [indexData, setIndexData] = useState()
const [windowWidth,setWindowWidth]=useState(1920)
  const[windowWidthError,setWindowWidthError]=useState(false)
  const[windowHeightError,setWindowHeightError]=useState(false)
  const [windowHeight,setWindowHeight]=useState(1080)
  const[forwardUserAgent,setForwardUserAgent]=useState(false)
  const methodArray = ['GET', 'POST', 'PUT', 'PATCH', 'DELETE', 'HEAD']
  const [method, setMethod] = useState(methodArray[0])
  const [currentIndex,setCurrentIndex]=useState()
  const [joinArray,setJoinArray]=useState(false)
  // console.log("API",tokenLists?.[0]?.token)




  function filterEmptyParams(data){
// console.log("Data",data)
let arr=[]
    let a=Object.entries(data).filter(([key,value])=>{

if(data[key]!=='' || data[key]===false ){
  return data
}})
    return a
  }

  const sendDemoAPIRequest = (e) => {
    setTempResponse(false)
    dispatch({
      type:SET_RESPONSE,
      payload:{}
    })
    dispatch({
      type:SET_RESPONSE_BODY,
      payload:null
    })
    setResponseStatus('')

    let params={
      'api_key':tokenLists?.[0]?.token,
      'cookies':cookie,
      'method':method,
      'url':urlForTesting,
      'proxy_type':proxyTypeValue[proxyValue],
      'json':jsonResponse,
      'js_scroll':jsScroll,
      'js_rendering':jsRendering,
      'forward_headers':forwardHeader,
      'js_wait':waitInMs,
      'transparent_mode':transparentMode,
      'screenshot':screenshot,
      'pdf':pdf,
      'window_height':windowHeight,
      'window_width':windowWidth,
      'forward_user_agent':forwardUserAgent,
      'device':deviceValue[device]
    }
    let filterParamData=filterEmptyParams(params)


    // console.log("filter",filterParamData)
    let arr=filterParamData.map(data=>{
      let key=data[0]
      if(langValue==0){
        return {
          [key]:encodeUrl(data[1])
        }
      }
      else{
        return {
          [key]:data[1]
        }
      }

    })
    // console.log(arr)
let obj=Object.assign({},...arr)
    let headersObj=Object.assign({},...headers)
    // console.log(obj)

    let query=Object.keys(obj)
        .map(k => k + '=' + obj[k])
        .join('&');

    const baseUrl=`${process.env.REACT_APP_API_BASE_URL}/api/v1/scrapingpass/proxy?`+query

  const bearer_token="Bearer "+accessToken
  // console.log("Beares",bearer_token)

    dispatch(sendApiRequest(baseUrl,method,bearer_token))





  }


  const onClickDemoDiv = () => {
    // setTryDemoOn(!tryDemoOn)
    console.log("Plan",props.currentPlan?.data?.plan_id)
    console.log("Token ",props.tokenList)
    if(props.currentPlan?.data?.plan_id){
      if(props.tokenList?.length>0){
        setTryDemoOn(!tryDemoOn)
        // console.log("TryDeomOn",tryDemoOn)
      //   if(tryDemoOn===true){
      //     // alert("DrawerClose")
      //     dispatch(setOuterToggleDrawer(true))
      //   }
      // else{
      //   // alert("DrawerOpen")
      //     dispatch(setOuterToggleDrawer(false))
      //   }

      }
      else{
        // console.log(" first  if else")
        setTryDemoOn(false)
        setMessage("Please generate an API Key before using our API")
        // dispatch(setOuterToggleDrawer(false))
      }
    }
    else if(props.tokenList?.length>0 && (props.currentPlan?.data?.plan_id===null||props.currentPlan?.data?.plan_id===''||props.currentPlan?.data?.plan_id===undefined)){
      // console.log("second Esle if")
      setMessage("Please subscribe to a plan before using our API")

        setTryDemoOn(false)
        // dispatch(setOuterToggleDrawer(false))


    }



  }

  const onChangeValue = (e, index) => {
    const { name, value } = e.target

    let list = [...headers]

    if (name.includes('key')) {
      list[index]['key'] = value
    } else if (name.includes('value')) {
      list[index]['value'] = value
    }
    setHeaders(list)

  }

  const addFields = () => {
    let newField = {
      key: '',
      value: ''
    }
    let oldList = [...headers, newField]
    setHeaders(oldList)
  }
  const rowData = [
    {
      name: 'Refresh Token',
      value: tokenLists?.[0]?.token,
      domain: process.env.REACT_APP_API_BASE_URL,
      path: '/',
      expires: 'Tue, 07 Dec 2021',
      httpOnly: true,
      secure: true,
      expires1: 'Tue, 07 Dec 2021',
      httpOnly1: true,
      secure1: true

    },
    {
      name: 'Refresh Token',
      value: tokenLists?.[0]?.token,
      domain: process.env.REACT_APP_API_BASE_URL,
      path: '/',
      expires: 'Tue, 07 Dec 2021',
      httpOnly: true,
      secure: true,
      expires1: 'Tue, 07 Dec 2021',
      httpOnly1: true,
      secure1: true

    },
    {
      name: 'Refresh Token',
      value: tokenLists?.[0]?.token,
      domain: process.env.REACT_APP_API_BASE_URL,
      path: '/',
      expires: 'Tue, 07 Dec 2021',
      httpOnly: true,
      secure: true,
      expires1: 'Tue, 07 Dec 2021',
      httpOnly1: true,
      secure1: true

    },
  ]
  const rowHeading = ['Name', 'Value', 'Domain', 'Path', 'Expires', 'HttpOnly', 'Secure']

  const rowHeaderHeading = ['Key', 'Value']
  const rowHeaderData = {}

  const handleCodeChange = (event, newValue) => {

    setLangValue(newValue)
    // console.log(newValue)
  }

  const handleBlur=(event)=>{

event.persist()
    // console.log(event)
    if (!event.currentTarget.contains(event.relatedTarget)) {
      // console.log("Cuurent",event.currentTarget,event.relatedTarget)
      // console.log(event.relatedTarget?.value)
    }
else{
  if(event.currentTarget!==null){
    // console.log(event.currentTarget)
    setShowInput(false)}
    }
setShowInput(false)



  }
  const handleResponseChange = (event,newValue) => {
    console.log(event)
event.persist()

    event.preventDefault()

// console.log(event)
    // event.stopPropagation()
      setResponseValue(newValue)



    // console.log(newValue);
  }
  const TabPanel1 = (props) => {
    const { children, value, index, ...other } = props

    return (
      <div role='tabpanel' hidden={value !== index} id={`wrapped-tabpanel-${index}`} aria-labelledby={`wrapped-tab-${index}`} {...other}>
        {value === index && (
          <Paper
            variant='elevation'
            style={{
              padding: '1px',
              marginTop: '20px',
              width: '100%',
              background: '#0e141a',
              borderRadius: '6px',
              color: '#ffff'
            }}
          >
            <CodeHeader />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>{children}</div>
          </Paper>
        )}
      </div>
    )
  }
  const TabPanel2 = (props) => {
    const { children, value, index, ...other } = props

    return (
      <div role='tabpanel' hidden={value !== index} id={`wrapped-tabpanel-${index}`} aria-labelledby={`wrapped-tab-${index}`} {...other}>
        {value === index && (
          <Paper
            variant='elevation'
            style={{
              padding: '10px',
              marginTop: '20px',
              width: '100%',
              background: '#ffff',
              borderRadius: '6px',

              border: '1px solid rgba(224, 224, 224, 1)'
            }}
          >
            {/*<CodeHeader />*/}

            <div style={{ display: 'flex', justifyContent: 'space-between' }} className={classes.customTab}>
              {children}
            </div>
          </Paper>
        )}
      </div>
    )
  }
  const CodeHeader = () => (
    <Grid container className={classes.codeHeader}>
      <Grid item className={classNames(classes.point, classes.redPoint)} />
      <Grid item className={classNames(classes.point, classes.yellowPoint)} />
      <Grid item className={classNames(classes.point, classes.greenPoint)} />
    </Grid>
  )

  let headerOneFlag = customHeaders['header_one'] !== '' || customHeaders['header_one_value'] !== ''
  let headerTwoFlag = customHeaders['header_two'] !== '' || customHeaders['header_two_value'] !== ''
  let headerThreeFlag = customHeaders['header_three'] !== '' || customHeaders['header_three_value'] !== ''
// console.log("Type", Object.values(headers))
  function checkLengthOfHeader(){
    if(headers.length>0){
      // console.log("headerLength",Object.values(headers).filter((item) => item.key !== '' && item.value!=='').length)
      return Object.values(headers).filter((item) => item.key !== '' && item.value!=='').length > 0
    }
    else{
      return false
    }
  }
  let checkIfHeaderObjectHasValues =checkLengthOfHeader()

  const copyToClipboard = async () => {
    let copyText = getAPICode(langValue)
    try {
      await navigator.clipboard.writeText(copyText)
      setCopy(true)
    } catch (err) {
      setCopy(false)
    }
  }
  function objToString (obj) {

    return Object.entries(obj).reduce((str, [p, val]) => {
      return `${str}${p}::${val}\n`;
    }, '');
  }

  const copyResponseToClipboard = async () => {
    // console.log("CopyText",typeof bodyData)
    let copyText = objToString(bodyData)
    console.log("Copytext ",copyText)

    console.log('CopyText', copyText)
    if(typeof bodyData===Object){
      console.log("String Conversion",JSON.stringify(JSON.stringify(copyText)),copyText.toString())
      console.log("Data in If",typeof copyText)

      try {
        await navigator.clipboard.writeText(copyText)
        setCopy(true)
      } catch (err) {
        setCopy(false)
      }
    }
    else{
      console.log("Data in If",typeof bodyData)
      try {
        await navigator.clipboard.writeText(copyText)
        setCopy(true)
      } catch (err) {
        setCopy(false)
      }
    }

  }

  const CustomTabs = withStyles({
    root: {
      // borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
      background: 'none'
    }
  })(Tabs)

  const CustomItemTab = withStyles((theme) => ({
    root: {
      textTransform: 'none',

      minWidth: 72,
      fontWeight: theme.typography.fontWeightBold,
      marginRight: theme.spacing(2),
      color: theme.palette.primary.main,
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(','),
      '&:hover': {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightBold,
        opacity: 1,
        background: '#edf2fe',
        borderRadius: '18px'
      },
      '&$selected': {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightBold,
        background: '#edf2fe',
        borderRadius: '18px'
      },
      '&:focus': {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightBold,
        background: '#edf2fe',
        borderRadius: '18px'
      }
    },
    selected: {}
  }))((props) => <Tab {...props} />)

  const handleMultiline = (index) => {
    setIndexData(index)
  }
const convertIntoPythonBoolValue=(bool)=>{
    let a=bool.toString()
  let pythonString=a[0].toUpperCase()+a.substring(1)
  return pythonString
}

  const getMuiTheme = () =>
      createMuiTheme({
        overrides: {
          MuiTableContainer: {
            root: {
              width: '100%',
              margin: 'auto',
              border: '1px solid #cecece'
            }
          },
          MuiTableRow: {
            root: {
              verticalAlign: 'super'
            }
          },
          MuiTableCell: {
            root: {
              padding: '10px',
              fontWeight: '500',
              fontFamily: 'AvertaRegular !important'

              // borderRight: "1px solid  rgba(224, 224, 224, 1)",
              // "&:last-child": {
              //     borderRight: "none"
              // }
            },
            body: {
              textAlign: 'left'
            }
          },

          MuiInputBase: {
            formControl: {
              '&:hover': {
                outline: 'none'
              }
            },
            input: {
              textOverflow: 'ellipsis',
              fontSize: '13px'
            }
          },
          MuiPaper: {
            elevation4: {
              boxShadow: 'none'
            },
            outlined: {
              // border: "1px solid #ccc",
              border: 'none',
              borderRight: '1px solid rgba(224, 224, 224, 1)',
              borderLeft: '1px solid rgba(224, 224, 224, 1)',
              borderTop: '1px solid rgba(224, 224, 224, 1)'
            }
          },
          MuiPopover:{
            paper:{
              // top
              top:"300px"
            }
          }

        }
      })



  const getResponseCode = (lang, bodyData) => {

    // console.log('Lang', lang)
    switch (lang) {
      case 0:
        return bodyData


      case 1:
        return (
          <MuiThemeProvider theme={getMuiTheme()}>

            <TableContainer>
              <Table onBlur={(e)=>{handleBlur(e)}}>
                <TableHead>
                  <TableRow >
                    {rowHeading?.map((data, index) => (
                      <TableCell style={{ fontWeight: '600' }} key={index} align={'left'}>
                        {data}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowData?.map((data, index) => (
                    <TableRow key={index} >
                      <TableCell className={classes.tableCell}>
                        <TextField
                            autoFocus={0 == indexData && currentIndex==index && showInput }
                          multiline={0 == indexData && currentIndex==index && showInput}
                          style={{ textOverflow: 'ellipsis', border: 'none' }}
                          className={classes.inputTag}
                          value={data.name}
                          onMouseDown={(e) => {
// console.log("MouseDownEventCalled")
                            e.preventDefault()
                            handleMultiline(0)
                            setCurrentIndex(index)
                            setShowInput(true)

                            // console.log(currentRef.current)
// currentRef.current.focus()
                          }}


                            // onBlur={(e)=>{handleBlur(e)}}
                        />
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <TextField
                            autoFocus={1 == indexData && currentIndex==index && showInput }

                          multiline={1 == indexData && currentIndex==index && showInput}
                          className={classes.inputTag}
                          value={data.value}
                            onMouseDown={(e) => {
                              // alert("hello")
                              e.preventDefault()
                              // console.log("Current",currentIndex,indexData)
                              handleMultiline(1)
                              setCurrentIndex(index)
                              setShowInput(true)

                            }}

                            // onBlur={(e)=>{handleBlur(e)}}
                        />
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <TextField
                            autoFocus={2 == indexData && currentIndex==index && showInput }

                          multiline={2 == indexData && currentIndex==index && showInput }
                          style={{ textOverflow: 'ellipsis', border: 'none' }}
                          className={classes.inputTag}
                          value={data.domain}
                            onMouseDown={(e) => {
e.preventDefault()

                              handleMultiline(2)
                              setCurrentIndex(index)
                              setShowInput(true)

                            }}
//                           onFocus={() => {handleMultiline(2)
//                             setCurrentIndex(index)
// setShowInput(true)
//
//                           }}

                            // onBlur={(e)=>{handleBlur(e)}}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                            autoFocus={3 == indexData && currentIndex==index && showInput }
                          multiline={3 == indexData && currentIndex==index && showInput}
                          className={classes.inputTag}

                          value={data.path}
                            onMouseDown={(e) => {
e.preventDefault()
                              // console.log("hello")
                              handleMultiline(3)
                              setCurrentIndex(index)
                              setShowInput(true)
                            }}

                            // onBlur={(e)=>{handleBlur(e)}}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
autoFocus={4 == indexData && currentIndex==index && showInput }
                          multiline={4 == indexData && currentIndex==index && showInput }
                          className={classes.inputTag}
                          value={data.expires}
onMouseDown={(e) =>{
  e.preventDefault()
  // console.log("hello")
  handleMultiline(4)
  setCurrentIndex(index)
  setShowInput(true)
}}

                        />
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <TextField
                            autoFocus={5 == indexData && currentIndex==index && showInput }
                          multiline={5 == indexData && currentIndex==index && showInput }
                          className={classes.inputTag}
                          value={data.httpOnly}
                            onMouseDown={(e) => {
e.preventDefault()

                              handleMultiline(5)

                              setCurrentIndex(index)
                              setShowInput(true)

                            }}

                        />
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <TextField

                          multiline={6 == indexData && currentIndex==index  && showInput}
                          className={classes.inputTag}
                          value={data.secure}
                          onMouseDown={(e) => {
e.preventDefault()
                            handleMultiline(6)
                            // alert("hello")
                            setCurrentIndex(index)
                            setShowInput(true)
                          }
                          }
//                           onFocus={() => {handleMultiline(6)
//                             setCurrentIndex(index)
// setShowInput(true)
//                           }
//                           }
                          // onFocus={()=>{
                          //   setShowInput(true)
                          // }}
                          autoFocus={6 == indexData && currentIndex==index  && showInput}
                          // onBlur={(e)=>{handleBlur(e)}}

                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </MuiThemeProvider>
        )

      case 2:
        return (
          <MuiThemeProvider theme={getMuiTheme()}>

            <TableContainer>
              <Table onBlur={(e)=>{handleBlur(e)}}>
                <TableHead>
                  <TableRow>
                    {rowHeaderHeading?.map((data, index) => (
                      <TableCell style={{ fontWeight: '600' }} key={index} align={'left'}>
                        {data}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object?.entries(headerData)?.map(([data, value], index) => (
                    <TableRow key={index}>
                      <TableCell style={{fontWeight:"bold"}} align={"left"}>
                        {data}
                      </TableCell>
                      <TableCell align={"left"}>
                        <TextField
                            autoFocus={index==headerIndex && showInput}
multiline={index==headerIndex && showInput}
                            className={classes.inputTag}
                            value={value}
                            onMouseDown={(e) => {
                              e.preventDefault()

setHeaderIndex(index)
                            setShowInput(true)}}

                        />

                        </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </MuiThemeProvider>
        )
    }
  }
  const showHeader=(data,lang,index)=>{
    // console.log("Data",data,lang,index,headers.length)
    // console.log("ArrayLength",headers.length-1)
    if(data.key!=='' ) {
      // setJoinArray(true)
      if (lang == "curl") {
        if (index === headers.length - 1) {
          return `     -H "Sp-${data.key}:${data.value}"`
        } else {
          return `     -H "Sp-${data.key}:${data.value}" \\\n`
        }
      } else if (lang == "python" || lang == "node" || lang == "php") {

        if (index === headers.length - 1) {
          return `\n\t   'Sp-${data.key}':'${data.value}'`
        } else {
          return `\n\t   'Sp-${data.key}':'${data.value}',`
        }
      } else if (lang == "java") {
        if (index === headers.length - 1) {
          return `.addHeader("Sp-${data.key}","${data.value}")`
        }
        else {
          return `.addHeader("Sp-${data.key}","${data.value}") `
        }
      }

    }
    else{
      // setJoinArray(false)
    }
  }
  function encodeUrl(value){

    return encodeURIComponent(value)
  }

  const getAPICode = (lang) => {
    switch (lang) {
      case 0:
        // console.log("ProxyType", proxyValue!=='-')
        //   console.log("Proxy",proxyValue)
        //   console.log("ProxyType",proxyTypeValue[proxyValue])


        return `curl -k -X ${method} "https://api.scrapingpass.com/?api_key=${encodeUrl(tokenLists?.[0]?.token)}&method=${method}&url=${encodeUrl(urlForTesting)}&js_rendering=${encodeUrl(jsRendering)}&js_scroll=${encodeUrl(jsScroll)}&pdf=${encodeUrl(pdf)}&transparent_mode=${encodeUrl(transparentMode)}&forward_user_agent=${forwardUserAgent}${windowHeight?`&window_height=${windowHeight}`:''}${windowWidth?`&window_width=${windowWidth}`:''}&screenshot=${encodeUrl(screenshot)}${
             proxyValue!=='' ? `&proxy_type=${encodeUrl(proxyTypeValue[proxyValue])}` : ''
        }${waitInMs ? `&js_wait=${encodeUrl(waitInMs)}` : ''}${
            cookie_regex.test(cookie) ? '&cookies=' +encodeUrl(cookie) : ''
        }&forward_headers=${encodeUrl(forwardHeader)}&json=${encodeUrl(jsonResponse)}&device=${encodeUrl(deviceValue[device])}"${checkIfHeaderObjectHasValues ? ` \\` : ''}
${checkIfHeaderObjectHasValues ? headers.map((item,index) => showHeader(item,"curl",index)).join(joinArray?`\\\n`:''): ''}`
      case 1:

        return `// request Axios
const axios = require('axios');

axios.${method?.toLowerCase()}('https://api.scrapingpass.com/', {
    params: {
        'api_key': '${tokenLists?.[0]?.token}',
        'method': '${method?.toLowerCase()}',
        'url': '${urlForTesting}', 
        'js_scroll': ${jsScroll},
        'forward_user_agent': ${forwardUserAgent},${windowWidth? `\n\t'window_width': ${windowWidth},`:''}${windowHeight? `\n\t'window_height': ${windowHeight},`:''}        
        'js_rendering': ${jsRendering},${waitInMs ? `\n\t'js_wait': ${waitInMs},` : ''}${cookie_regex.test(cookie) ? `\n\t'cookies': '${cookie}',` : ''}
        'forward_headers': ${forwardHeader},
        'device': '${deviceValue[device]}',
        'json': ${jsonResponse},
        'pdf': ${pdf},
        'screenshot': ${screenshot},${proxyValue!==''? `\n\t'proxy_type': '${proxyTypeValue[proxyValue]}',` : ''}
        'transparent_mode': ${transparentMode},
    } ,
    ${
      checkIfHeaderObjectHasValues
        ? `headers: {${headers.map((item,index) =>  showHeader(item,"node",index) ).join(joinArray?',':'')}
    }`
        : ''
    }
}).then(function (response) {
    // handle success
    console.log(response);
})`
      case 2:

        return `#  Install the Python Requests library:
# \`pip install requests\`
import requests

def send_request():
    response = requests.${method?.toLowerCase()}(
        url='https://api.scrapingpass.com/',
        params={
            'api_key': '${tokenLists?.[0]?.token}',
            'method': '${method?.toLowerCase()}',
            'url': '${urlForTesting}',
            'forward_user_agent': ${convertIntoPythonBoolValue(forwardUserAgent)},
            'js_rendering': ${convertIntoPythonBoolValue(jsRendering)},${windowWidth? `\n\t    'window_width': ${windowWidth},`:''}${windowHeight? `\n\t    'window_height': ${windowHeight},`:''}
            'js_scroll': ${convertIntoPythonBoolValue(jsScroll)},${waitInMs ? `\n\t     'js_wait':${waitInMs},` : ''}${
           cookie_regex.test(cookie) ? `\n\t    'cookies': '${cookie}',` : ''
        }
            'device': '${deviceValue[device]}',
            'json': ${convertIntoPythonBoolValue(jsonResponse)},
            'pdf': ${convertIntoPythonBoolValue(pdf)},
            'screenshot': ${convertIntoPythonBoolValue(screenshot)},${proxyValue!=='' ? `\n\t    'proxy_type': '${proxyTypeValue[proxyValue]}',` : ''}
            'transparent_mode': ${convertIntoPythonBoolValue(transparentMode)},
            'forward_headers': ${convertIntoPythonBoolValue(forwardHeader)},     
        },
        ${
          checkIfHeaderObjectHasValues
            ? `headers={${headers.map((item,index) => showHeader(item,"python",index) ).join(joinArray?',':'')}
        }`
            : ''
        }
    )
    print('Response HTTP Status Code: ', response.status_code)
    print('Response HTTP Response Body: ', response.content)
    
send_request()`
//       case 3:
//         return `<?php
//
// // get cURL resource
// $ch = curl_init();
//
// // set url
// curl_setopt($ch, CURLOPT_URL, "https://api.scrapingpass.com?api_key=${
//           tokenLists?.[0]?.token
//         }&url=${urlForTesting}&method=${method}&forward_user_agent=${forwardUserAgent}${windowHeight?`&window_height=${windowHeight}`:''}${windowWidth?`&window_width=${windowWidth}`:''}&js_rendering=${jsRendering}&js_scroll=${jsScroll}&pdf=${pdf}&transparent_mode=${transparentMode}&screenshot=${screenshot}${
//           proxyValue!==''||proxyValue!==null || proxyValue!=='-' ? `&proxy_type='${proxyTypeValue[proxyValue]}',` : ''
//         }${waitInMs ? `&js_wait=${waitInMs}` : ''}${
//           cookie_regex.test(cookie)? '&cookies=' + cookie : ''
//         }&forward_headers=${forwardHeader}&json=${jsonResponse}&device=${deviceValue[device]}");
//
// // set method
// curl_setopt($ch, CURLOPT_CUSTOMREQUEST, ${method});
//
// // return the transfer as a string
// curl_setopt($ch, CURLOPT_RETURNTRANSFER, 1);
//
// ${
//   checkIfHeaderObjectHasValues
//     ? `// set headers
// curl_setopt($ch, CURLOPT_HTTPHEADER, [${headers.map((item,index) =>  showHeader(item,"php",index) ).join(joinArray?',':'')}
//    ]);`
//     : ''
// }
//
// // send the request and save response to $response
// $response = curl_exec($ch);
//
// // stop if fails
// if (!$response) {
//   die('Error: "' . curl_error($ch) . '" - Code: ' . curl_errno($ch));
// }
//
// echo 'HTTP Status Code: ' . curl_getinfo($ch, CURLINFO_HTTP_CODE) . PHP_EOL;
// echo 'Response Body: ' . $response . PHP_EOL;
//
// // close curl resource to free up system resources
// curl_close($ch);
// echo $response; `
      //             case 4:
      //                 return `require 'net/http'
      // require 'net/https'
      //
      // # Classic (GET )
      // def send_request
      //     uri = URI('https://api.scrapingpass.com/api/v1/?api_key=${tokenLists?.[0]?.token}&url=${urlForTesting}&js_rendering=${jsRendering}&js_scroll=${jsScroll}&pdf=${pdf}&transparent_mode=${transparentMode}&forward_headers=${forwardHeader}&screenshot=${screenshot}&with_proxy=${proxyValue!==''||proxyValue!==null}&proxy_type=${proxyValue!==''||proxyValue!==null?proxyValue:`''`}&js_wait=${waitInMs}${cookie.length > 0?'&cookies='+cookie : ''}&json=${jsonResponse}&device=${device}')
      //
      //     # Create client
      //     http = Net::HTTP.new(uri.host, uri.port)
      //     http.use_ssl = true
      //     http.verify_mode = OpenSSL::SSL::VERIFY_PEER
      //
      //     # Create Request
      //     req =  Net::HTTP::Get.new(uri)
      //     ${checkIfHeaderObjectHasValues ? `# Add headers ${headers.map((item )=>(`\n    req.add_field "Sp-${item.key}","${item.value}"`)).join("")}
      //                     `:''}
      //
      //     # Fetch Request
      //     res = http.request(${method})
      //     puts "Response HTTP Status Code: #{ res.code }"
      //     puts "Response HTTP Response Body: #{ res.body }"
      // rescue StandardError => e
      //     puts "HTTP Request failed (#{ e.message })"
      // end
      //
      // send_request()`

      case 3:
        return `import java.io.IOException;
import org.apache.http.client.fluent.*;

public class SendRequest
{
  public static void main(String[] args) {
    sendRequest();
  }

  private static void sendRequest() {

    // Classic (GET )

    try {

      // Create request
      Content content = Request.${method}("https://api.scrapingpass.com/?api_key=${
          tokenLists?.[0]?.token
        }&url=${urlForTesting}&method=${method}&js_rendering=${jsRendering}&js_scroll=${jsScroll}&forward_user_agent=${forwardUserAgent}${windowHeight?`&window_height=${windowHeight}`:''}${windowWidth?`&window_width=${windowWidth}`:''}&pdf=${pdf}&transparent_mode=${transparentMode}&screenshot=${screenshot}${
          proxyValue!==''||proxyValue!==null ? `&proxy_type=${proxyTypeValue[proxyValue]},` : ''
        }${waitInMs ? `&js_wait=${waitInMs}` : ''}${
          cookie_regex.test(cookie) ? '&cookies=' + cookie : ''
        }&forward_headers=${forwardHeader}&json=${jsonResponse}&device=${deviceValue[device]}")

      ${
        checkIfHeaderObjectHasValues
          ? `// Add headers\n      ${headers.map((item,index) =>  showHeader(item,"java",index) ).join(joinArray?',':'')}
      `
          : ''
      } 
      // Fetch request and return content
      .execute().returnContent();

      // Print content
      System.out.println(content);
    }
    catch (IOException e) { System.out.println(e); }
  }
}`
      case 4:
        return `\n{                
   "http": "http://${
          tokenLists?.[0]?.token
        }:scrapingpass&js_rendering=${jsRendering}&js_scroll=${jsScroll}&forward_user_agent=${forwardUserAgent}${windowHeight?`&window_height=${windowHeight}`:''}${windowWidth?`&window_width=${windowWidth}`:''}&pdf=${pdf}&transparent_mode=${transparentMode}&screenshot=${screenshot}${
            proxyValue!==''||proxyValue!==null ? `&proxy_type=${proxyTypeValue[proxyValue]}` : ''
        }${waitInMs ? `&js_wait=${waitInMs}` : ''}${
             cookie_regex.test(cookie)  ? '&cookies=' + cookie : ''
        }&forward_headers=${forwardHeader}&json=${jsonResponse}&device=${deviceValue[device]}@proxy.scrapingpass.com:8080",
   "https": "http://${
          tokenLists?.[0]?.token
        }:scrapingpass&js_rendering=${jsRendering}&forward_user_agent=${forwardUserAgent}${windowHeight?`&window_height=${windowHeight}`:''}${windowWidth?`&window_width=${windowWidth}`:''}&js_scroll=${jsScroll}&pdf=${pdf}&transparent_mode=${transparentMode}&screenshot=${screenshot}${
            proxyValue!==''||proxyValue!==null ? `&proxy_type=${proxyTypeValue[proxyValue]}` : ''
        }${waitInMs ? `&js_wait=${waitInMs}` : ''}${
             cookie_regex.test(cookie)  ? '&cookies=' + cookie : ''
        }&forward_headers=${forwardHeader}&json=${jsonResponse}&device=${deviceValue[device]}@proxy.scrapingpass.com:8080"
}
`
    }
  }




  useEffect(() => {


    if (jsScroll == true || pdf == true || screenshot==true) {
      setJsRendering(true)
    //   if(pdf && jsRendering){
    //     if(jsRendering){
    //       dispatch(getReqCostPrice({
    //         "url":urlForTesting,
    //         "js_rendering":jsRendering,
    //         "pdf":pdf
    //       }))
    //     }
    //     else{
    //       dispatch(getReqCostPrice({
    //         "url":urlForTesting,
    //         "pdf":pdf
    //       }))
    //     }
    //
    //
    //   }
    // else if(jsScroll && jsRendering){
    //
    //   }
    //
    // }
    //
    //  else if(screenshot && jsRendering){
    //     if(jsRendering){
    //       dispatch(getReqCostPrice({
    //         "url":urlForTesting,
    //         "js_rendering":jsRendering,
    //         "screenshot":screenshot
    //       }))
    //     }
    //     else{
    //       dispatch(getReqCostPrice({
    //         "url":urlForTesting,
    //         "screenshot":screenshot
    //       }))
    //     }
    //
    //   }
    }


  }, [jsScroll, pdf,screenshot])

useEffect(()=>{
  if(tryDemoOn){
    console.log("Try Demo On UseEffect",tryDemoOn)
    dispatch(setOuterToggleDrawer(false))

  }
//   else{
//     console.log("Else in TryDemo",tryDemoOn,props.currentPlan?.data?.plan_id,props.tokenList?.length>0)
//     if(props.currentPlan?.data?.plan_id){
//       console.log("Else in TryDemo1",tryDemoOn)
//       if(props.tokenList?.length>0){
//         console.log("Else in TryDemo2",tryDemoOn)
//         setTryDemoOn(true)
//         console.log("TryDeomOn",tryDemoOn)
// }
//     }
    else{
      dispatch(setOuterToggleDrawer(true))
    }
},[tryDemoOn])

useEffect(()=>{
  console.log("Try Demo On UseEffect 2",tryDemoOn)
      console.log("Else in TryDemo",tryDemoOn,props.currentPlan?.data?.plan_id,props.tokenList?.length>0)
  if(props.currentPlan?.data?.plan_id) {
    console.log("Else in TryDemo1", tryDemoOn)
    if (props.tokenList?.length > 0) {
      console.log("Else in TryDemo2", tryDemoOn)
      setTryDemoOn(true)
      dispatch(setOuterToggleDrawer(false))
      console.log("TryDeomOn", tryDemoOn)
    }
  }
},[props.currentPlan,props.tokenList])
  useEffect(() => {
    if (responseCode == 200) {
      setResponseStatus('Ok')
    } else if (responseCode == 201) {
      setResponseStatus('Created')
    } else if (responseCode == 202) {
      setResponseStatus('Accepted')
    } else if (responseCode == 400) {
      setResponseStatus('Bad Request')
    } else if (responseCode == 422) {
      setResponseStatus('Unprocessable Entity')
    } else if (responseCode == 401) {
      setResponseStatus('Unauthorized')
    } else if (responseCode == 403) {
      setResponseStatus('Forbidden')
    } else if (responseCode == 404) {
      setResponseStatus('Not Found')
    } else if (responseCode == 405) {
      setResponseStatus('Method Not Allowed')
    } else if (responseCode == 403) {
      setResponseStatus('Forbidden')
    } else if (responseCode == 500) {
      setResponseStatus('Internal Server Error')
    }
  }, [responseCode])
  useEffect(()=>{
setCostPrice(requestCostPrice)
  },[requestCostPrice])

  useEffect(()=>{

    if(responseBody && responseBody){
      // console.log(responseFromAPI,responseBody)

      setContentType(responseFromAPI?.headers?.get('Content-type'))
// console.log("Response",responseFromAPI?.status)
      setResponseCode(responseFromAPI?.status)
      // setResponseStatus(responseFromAPI?.statusText)
      setBodyData(responseBody)
      let obj1
      let a=responseFromAPI?.headers?.forEach(function(key, val) {
        // console.log(`Key ${val} -> Value ${key}`)
        obj1=Object.assign(rowHeaderData,{[val]:key})
      });
      setHeaderData(obj1)
      setTempResponse(true)
    }


  },[responseFromAPI,responseBody])


  function downloadData(data) {
    // var data_ = new Blob(data);

let filename="file"
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(data);
    a.href = url;
    a.download = filename;
    a.click();
    // var URL = window.URL.createObjectURL(data_);
    // let tempLink = document.createElement('a');
    // tempLink.href = URL;
    // tempLink.setAttribute('download', 'file');
    // tempLink.click();


  }

  const showBody = (responseValue) => {
    // let data=headerData?.['sp-content-type']
    // console.log("Header",headerData?.['sp-content-type'])


    if (contentType == 'application/json') {
      console.log("bodyData",bodyData)
      let data=formatter(bodyData,{
        method:'json'
      })
      return (
        <SyntaxHighlighter language={'json'} style={a11yLight}>
          {getResponseCode(responseValue,data)}
        </SyntaxHighlighter>
      )
    } else if (contentType == 'text/html; charset=utf-8'||contentType == 'text/html; charset=UTF-8') {
      let data=formatter(bodyData,{
        method:'html'
      })

      return (
        <SyntaxHighlighter language={'htmlbars'} style={a11yLight}>
          {getResponseCode(responseValue, data)}
        </SyntaxHighlighter>
      )
    } else if (contentType == 'text/xml'||contentType == 'xml') {
      let data=formatter(bodyData,{
        method:'xml'
      })
      return (
        <SyntaxHighlighter language={'xml'} style={a11yLight}>
          {getResponseCode(responseValue, data)}
        </SyntaxHighlighter>
      )
    }
    else if (contentType== 'plaintext') {
      // let data=formatter(bodyData,{
      //   method:'xml'
      // })
      return (
          <SyntaxHighlighter style={a11yLight}>
            {getResponseCode(responseValue, bodyData)}
          </SyntaxHighlighter>
      )
    }
    else {
      return (
        <Button variant={'contained'} color={'primary'} className={classes.sendBtn} onClick={() => downloadData(bodyData)}>
          Download
        </Button>
      )
    }
  }
const handleDelete=(index)=>{
    console.log("Index",index)
  console.log("Header",headers)

  let copyArray=[...headers]

    let a=copyArray.splice(index,1)

    setHeaders(copyArray)
  }
  const copyToClipBoard = async (copyMe, a) => {
    // console.log("CopyMe", copyMe);
    try {
      await navigator.clipboard.writeText(copyMe)

      setCopy(true)
    } catch (err) {
      setCopy(false)
      // setSuccessAlert(false)
    }
  }

  const handleGenerateTokenModal = () => {
    // setGenerateModal(true);
    dispatch(changeModal(true))
  }
const handleCloseError=()=>{
    setError(false)
}



  return (
<>
  <Snackbar open={error} autoHideDuration={3000} onClose={handleCloseError} >
    <Alert severity={"error"  } onClose={handleCloseError}>
      {error}
    </Alert>
  </Snackbar>
  <Grid
      container
      item
      xs={12}
      style={{
        marginLeft: '.3rem',
        boxShadow: '0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)',
        // padding: "20px 10px",
        borderRadius: '8px',
        background: '#fff',
        border: '1px solid #ccc'
      }}
      // onClick={()=>setIndexData()}
  >
    <Snackbar open={copy} autoHideDuration={3000} onClose={() => setCopy(null)}>
      <Alert onClose={() => setCopy(null)} severity='success'>
        Copied
      </Alert>
    </Snackbar>

    <Snackbar open={message} autoHideDuration={3000} onClose={() => setMessage(null)}>
      <Alert onClose={() => setMessage(null)} severity='info'>
        {message}
      </Alert>

    </Snackbar>
    <Grid container item xs={12}>
      <Grid container alignItems={'center'}>
        <Grid item xs={12} onClick={onClickDemoDiv} className={!tryDemoOn ? classes.demoHead : classes.demoActiveHead}>
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <div>
              <Typography style={{ color: tryDemoOn ? '#fff' : '#000', fontWeight: 'bold' }} variant={'h6'}>
                Try Our API
              </Typography>
              <Divider
                  style={{
                    background: tryDemoOn ? '#fff' : '#000',
                    width: '100px',
                    margin: '10px 0px 20px 0px',
                    height: '2px'
                  }}
              />
            </div>
            <IconButton onClick={onClickDemoDiv}>
              {tryDemoOn ? <ExpandLess style={{ color: '#fff' }} /> : <ExpandMore />}
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Collapse in={tryDemoOn} unmountOnExit style={{ margin: 20 }}>
            <Grid container>

              <Grid item xs={6}>
                <Grid item xs={12} className={classes.apiKeyShowBackground}>
                  <Typography
                      variant='body1'
                      component='div'
                      style={{
                        // paddingTop: "1rem",
                        // paddingLeft: "1rem",
                        color: '#111',
                        fontSize: '24px',
                        fontWeight: '400'
                      }}
                      gutterBottom
                  >
                    API Key
                  </Typography>
                  {tokenLists?.length > 0 && (
                      <Paper
                          variant='elevation'
                          style={{
                            width: '100%',
                            borderRadius: '6px',
                            background: '#0e141a',
                            color: '#ffff',
                            paddingTop: 1
                          }}
                      >
                        <CodeHeader />
                        <Grid container>
                          <div
                              style={{
                                marginRight: '20px',
                                textAlign: 'right',
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between'
                              }}
                          >
                            <Typography
                                container='div'
                                style={{
                                  overflowX: 'auto',
                                  fontSize: '14px',
                                  padding: '.5rem',
                                  display: 'flex',
                                  justifyContent: 'space-between',


                                }}
                                className={classes.span1_generator}
                                gutterBottom
                            >
                              {truncate(tokenLists?.[0]?.token, 50)}&nbsp;{' '}
                            </Typography>
                            {tokenLists && (
                                <FileCopyOutlined
                                    onClick={() => {
                                      copyToClipBoard(tokenLists?.[0]?.token)

                                    }}
                                    className={classes.copy}
                                />
                            )}
                          </div>
                        </Grid>
                      </Paper>
                  )}

                  {tokenLists?.length === 0 && (
                      <Grid container className={classes.linkContainer}>
                        <Typography variant='body1' className={classes.linkButton}>
                          Sorry, you don't have any API Key right now&nbsp;
                          <Button variant='text' className={classes.link} onClick={handleGenerateTokenModal}>
                            Click to generate
                          </Button>
                        </Typography>
                      </Grid>
                  )}
                </Grid>
                <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>


                  <ToolTipContainer label={'URL (required)'} tooltipText={'Url to scrape. Must be correctly encoded'} classes={classes} />
                  <TextField
                      className={classes.urlBox}
                      name={"url"}
                      value={urlForTesting}
                      variant={'outlined'}
                      disabled={props.demoAPILoading}
                      onFocus={()=>{
                        if(jsRendering){
                          dispatch(getReqCostPrice({
                            "url":urlForTesting,
                            "js_rendering":jsRendering,
                          }))
                        }
                        else{
                          dispatch(getReqCostPrice({
                            "url":urlForTesting,
                          }))
                        }

                      }}
                      onChange={(e) => {
                        e.persist()
                        let{name,value}=e.target

                        setUrlForTesting(e.target.value)

                      }}
                      placeholder={'ex: https://example.com'}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                            <InputAdornment position={'start'} style={{ color: '#616161' }}>
                              <TextField
                                  select
                                  variant='outlined'
                                  size='small'
                                  SelectProps={{
                                    native: true
                                  }}
                                  value={method}
                                  onChange={(e) => setMethod(e.target.value)}
                                  fullWidth
                              >
                                {methodArray.map((option, index) => (
                                    <option key={index} value={option}>
                                      {option}
                                    </option>
                                ))}
                              </TextField>
                            </InputAdornment>
                        ),
                        type: 'text'
                      }}
                      size={'small'}
                      style={{ width: '100%', marginRight: 20 }}
                  />
                </Box>
                <br />
                <Grid container>
                  <Grid item xs={6}>
                    <FormControlLabel
                        style={{ marginLeft: '0px' }}
                        control={
                          <Checkbox
                              color={'primary'}
                              checked={jsScroll}
                              // value={params.js_scroll}
                              name={"js_scroll"}
                              // disabled={!jsRendering}


                              onChange={(e) => {
                                e.persist()
                                let {name,value,checked}=e.target
                                setJsScroll(!jsScroll)
                                if(jsRendering){
                                      dispatch(getReqCostPrice({
                                        "url":urlForTesting,
                                        "js_rendering":jsRendering,
                                        "js_scroll":checked
                                      }))
                                    }
                                    else{
                                      dispatch(getReqCostPrice({
                                        "url":urlForTesting,
                                        "js_scroll":checked
                                      }))}


                              }}
                          />
                        }
                        labelPlacement={'start'}
                        label={
                          <ToolTipContainer
                              label={'JS Scroll'}
                              tooltipText={
                                'Whether to take screenshot of full scrollable page in JS Rendering.'
                              }
                              classes={classes}
                          />
                        }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                        style={{ marginLeft: '0px' }}
                        disabled={pdf == true || jsScroll == true || screenshot==true ? true : false}
                        control={
                          <Checkbox
                              color={'primary'}
                              checked={jsScroll == true || pdf == true || screenshot==true ? true : jsRendering}
                              name={"js_rendering"}

                              onChange={(e) => {
                                e.persist()
                                let {name,checked}=e.target
                                setJsRendering(!jsRendering)
                                if(checked){
                                  dispatch(getReqCostPrice({
                                    "url":urlForTesting,
                                    "js_rendering":checked,

                                  }))
                                }
                                else{
                                  dispatch(getReqCostPrice({
                                    "url":urlForTesting,
                                  }))
                                }


                              }}
                          />
                        }
                        labelPlacement={'start'}
                        label={
                          <ToolTipContainer
                              label={'Javascript Rendering'}
                              tooltipText={
                                'Determines whether to use JS Rendering using headless browsers for remote requests rather than normal HTTP request. This is suitable for modern client side rendered web applications.'
                              }
                              classes={classes}
                          />
                        }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                        style={{ marginLeft: '0px' }}
                        control={
                          <Checkbox
                              color={'primary'}
                              checked={pdf}
                              name={"pdf"}

                              // disabled={!jsRendering}
                              onChange={(e) => {
                                e.persist()
                                let {name,checked}=e.target
                                setPdf(!pdf)
                                if(e.target.checked===false){
                                  if(jsRendering){
                                    dispatch(getReqCostPrice({
                                      "url":urlForTesting,
                                      "js_rendering":jsRendering,
                                      "pdf":checked
                                    }))
                                  }
                                  else{
                                    dispatch(getReqCostPrice({
                                      "url":urlForTesting,
                                      "pdf":checked
                                    }))
                                  }

                                }


                              }}
                          />
                        }
                        label={
                          <ToolTipContainer
                              label={'PDF'}
                              tooltipText={
                                'Use JS Rendering and render PDF of the page.'
                              }
                              classes={classes}
                          />
                        }
                        labelPlacement={'start'}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <FormControlLabel
                        style={{ marginLeft: '0px' }}
                        control={
                          <Checkbox
                              color={'primary'}
                              checked={forwardHeader}
                              name={"forward_headers"}


                              // disabled={!jsRendering}
                              onChange={(e) =>{
                                  let {value,checked}=e.target
                                setForwardHeader(!forwardHeader)
                                if(jsRendering){
                                  dispatch(getReqCostPrice({
                                    "url":urlForTesting,
                                    "js_rendering":jsRendering,
                                    "forward_headers":checked
                                  }))
                                }
                                else{
                                  dispatch(getReqCostPrice({
                                    "url":urlForTesting,
                                    "forward_headers":checked
                                  }))
                                }

                              }}
                          />
                        }
                        label={
                          <ToolTipContainer
                              label={'Forward Headers'}
                              tooltipText={
                                'Whether to forward request headers to remote.'
                              }
                              classes={classes}
                          />
                        }
                        labelPlacement={'start'}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                        style={{ marginLeft: '0px' }}
                        control={
                          <Checkbox
                              color={'primary'}
                              checked={transparentMode}
                              name={"transparent_mode"}
                              onFocus={()=>{


                              }}
                              // disabled={!jsRendering}
                              onChange={(e) => {
                                e.persist()
                                let{name,checked}=e.target

                                setTransparentMode(!transparentMode)
                                if(jsRendering){
                                  dispatch(getReqCostPrice({
                                    "url":urlForTesting,
                                    "js_rendering":jsRendering,
                                    "transparent_mode":checked
                                  }))
                                }
                                else{
                                  dispatch(getReqCostPrice({
                                    "url":urlForTesting,
                                    "transparent_mode":checked
                                  }))
                                }

                              }}
                          />
                        }
                        label={
                          <ToolTipContainer
                              label={'Transparent Mode'}
                              tooltipText={
                                'Whether to keep API response headers, status code, etc transparent with remote.'
                              }
                              classes={classes}
                          />
                        }
                        labelPlacement={'start'}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <FormControlLabel
                        style={{ marginLeft: '0px' }}
                        control={
                          <Checkbox
                              color={'primary'}
                              checked={jsonResponse}
                              name={"json"}
                              onFocus={()=>{


                              }}
                              // disabled={!jsRendering}
                              onChange={(e) => {
                                e.persist()
                                let {name,checked}=e.target

                                setJsonResponse(!jsonResponse)
                                if(jsRendering){
                                  dispatch(getReqCostPrice({
                                    "url":urlForTesting,
                                    "js_rendering":jsRendering,
                                    "json":checked
                                  }))
                                }
                                else{
                                  dispatch(getReqCostPrice({
                                    "url":urlForTesting,
                                    "json":checked
                                  }))
                                }

                              }}
                          />
                        }
                        label={
                          <ToolTipContainer
                              label={'JSON Response'}
                              tooltipText={
                                'Whether to return JSON response wrapping remote response entities.'
                              }
                              classes={classes}
                          />
                        }
                        labelPlacement={'start'}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                        style={{ marginLeft: '0px' }}
                        control={
                          <Checkbox
                              color={'primary'}
                              checked={screenshot}
                              name={"screenshot"}
                              onFocus={()=>{


                              }}
                              // disabled={!jsRendering}
                              onChange={(e) => {
                                e.persist()
                                let {name,checked}=e.target

                                setScreenshot(!screenshot)
                                if(jsRendering){

                                    dispatch(getReqCostPrice({
                                      "url":urlForTesting,
                                      "js_rendering":jsRendering,
                                      "screenshot":checked
                                    }))
                                }
                                else{

                                  dispatch(getReqCostPrice({
                                    "url":urlForTesting,
                                    "screenshot":checked
                                  }))
                                }
                              }}
                          />
                        }
                        label={
                          <ToolTipContainer
                              label={'Screenshot'}
                              tooltipText={
                                'Use JS Rendering and render screenshot of the page.'
                              }
                              classes={classes}
                          />
                        }
                        labelPlacement={'start'}
                    />
                  </Grid>



                  <Grid item xs={6}>
                    <FormControlLabel
                        style={{ marginLeft: '0px' }}
                        control={
                          <Checkbox
                              color={'primary'}
                              checked={forwardUserAgent}
                              // value={params.js_scroll}
                              name={"forward_user_agent"}
                              // disabled={!jsRendering}


                              onChange={(e) => {
                                e.persist()
                                let {name,value,checked}=e.target

                                setForwardUserAgent(!forwardUserAgent)
                                if(jsRendering){
                                  dispatch(getReqCostPrice({
                                    "url":urlForTesting,
                                    "js_rendering":jsRendering,
                                    "forward_user_agent":checked
                                  }))
                                }
                                else{
                                  dispatch(getReqCostPrice({
                                    "url":urlForTesting,
                                    "forward_user_agent":checked
                                  }))
                                }
                              }}
                          />
                        }
                        labelPlacement={'start'}
                        label={
                          <ToolTipContainer
                              label={'Forward User Agent'}
                              tooltipText={
                                'Whether to forward user agent as received.'
                              }
                              classes={classes}
                          />
                        }
                    />
                  </Grid>
                  {/*<Grid item xs={6}>*/}
                  {/*    <FormControlLabel*/}
                  {/*        style={{marginLeft:"0px"}}*/}
                  {/*        control={<Checkbox  color={"primary"} checked={returnPageSource}*/}
                  {/*                            // disabled={!jsRendering}*/}
                  {/*                            onChange={()=>{setReturnPageSource(!returnPageSource)*/}
                  {/*            setJsRendering(true)*/}
                  {/*                            }}/>}*/}
                  {/*        label={*/}
                  {/*            <ToolTipContainer*/}
                  {/*                label={"Return Page Source"}*/}
                  {/*                tooltipText={"If you want tp have the HTML returned by the server and unaltered by the browser. Only available with Javascript rendering"}*/}
                  {/*                classes={classes}*/}
                  {/*            />*/}
                  {/*        }*/}
                  {/*        labelPlacement={"start"}*/}
                  {/*    />*/}
                  {/*</Grid>*/}
                </Grid>
                <br />
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <ToolTipContainer
                        label={'Proxy Type'}
                        tooltipText={
                          'Type of device to pretend while making remote request.'
                        }
                        classes={classes}
                    />
                    <MuiThemeProvider theme={getMuiTheme()} >

                      <TextField
                          select
                          variant='outlined'
                          size='small'
                          // defaultValue={proxyTypeValue.Auto}
                          name={"proxy_type"}
                          value={proxyValue}
                          // onFocus={()=>{
                          // }
                          onChange={(e) => {
                            e.persist()
                            let{name,value}=e.target
console.log("Value",value)
                            setProxyValue(value)

  if(jsRendering){
    dispatch(getReqCostPrice({
      "url":urlForTesting,
      "js_rendering":jsRendering,
      "proxy_type":proxyTypeValue[proxyValue]
    }))
  }
  else{
    dispatch(getReqCostPrice({
      "url":urlForTesting,
      "proxy_type":proxyValue
    }))
  }}
                          }
                          fullWidth
                          SelectProps={{
                            native: false
                          }}
                      >
                        {Object.keys(proxyTypeValue).map((option, index) => (
                            <option key={index} value={option}>
                              {option}
                            </option>
                        ))}
                      </TextField>
                    </MuiThemeProvider>
                  </Grid>

                  <Grid item xs={6}>
                    <ToolTipContainer
                        label={'Wait (in ms)'}
                        tooltipText={
                          'The amount of ms our server will wait for before returning your the result. Useful for heavy websites. Only works with JavaScript rendering.'
                        }
                        classes={classes}
                    />
                    <TextField
                        type={'number'}
                        value={waitInMs}
                        name={"js_wait"}
                        error={waitInMs<0}
                        variant={'outlined'}
onBlur={()=>{
  if(jsRendering){
    dispatch(getReqCostPrice({
      "url":urlForTesting,
      "js_rendering":jsRendering,
      "js_wait":waitInMs
    }))
  }
  else{
    dispatch(getReqCostPrice({
      "url":urlForTesting,

      "js_wait":waitInMs
    }))
  }
}}
                        onChange={(e) => {
                          e.persist()
                          let {name,value}=e.target

                          setWaitInMs(value)
                          if(jsRendering==false){
                            setJsRendering(true)
                          }



                        }}
                        fullWidth
                        InputProps={{
                          inputProps: { min: 0 }
                        }}
                        size={'small'}
                        style={{ width: '100%', marginRight: 20 }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <ToolTipContainer
                        label={'Window Height'}
                        tooltipText={
                          'Height of the browser viewport in case of JS Rendering.'
                        }
                        classes={classes}
                    />
                    <TextField
                        type={'number'}
                        value={windowHeight}
                        name={"window_height"}
                        variant={'outlined'}
                        error={!!windowHeightError}
onBlur={()=>{
  if(jsRendering){
    dispatch(getReqCostPrice({
      "url":urlForTesting,
      "js_rendering":jsRendering,
      "window_height":windowHeight
    }))
  }
  else{
    dispatch(getReqCostPrice({
      "url":urlForTesting,
      "window_height":windowHeight
    }))
  }
}}
                        onChange={(e) => {
                          e.persist()
                          let {name,value}=e.target

                          if(value<1 || value==='-'){
                            setWindowHeightError(true)
                          }
                          else{
                            setWindowHeightError(false)
                          }
                          setWindowHeight(value)




                        }}
                        fullWidth
                        InputProps={{
                          inputProps: { min: 1 }
                        }}
                        size={'small'}
                        style={{ width: '100%', marginRight: 20 }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <ToolTipContainer
                        label={'Window Width'}
                        tooltipText={
                          'Width of the browser viewport in case of JS Rendering.'
                        }
                        classes={classes}
                    />
                    <TextField
                        type={'number'}
                        value={windowWidth}
                        name={"window_width"}
                        variant={'outlined'}
                        error={!!windowWidthError}
                        onBlur={()=>{
                          if(jsRendering){
                            dispatch(getReqCostPrice({
                              "url":urlForTesting,
                              "window_width":windowWidth
                            }))
                          }
                          else{
                            dispatch(getReqCostPrice({
                              "url":urlForTesting,
                              "js_rendering":jsRendering,
                              "window_width":windowWidth
                            }))
                          }
                        }}

                        onChange={(e) => {
                          e.persist()
                          let {name,value}=e.target
                          if(value<1 || value==='-'){
                            setWindowWidthError(true)
                          }
                          else{
                            setWindowWidthError(false)
                          }

                          setWindowWidth(value)
                        }}
                        fullWidth
                        InputProps={{
                          inputProps: { min: 1 }
                        }}
                        size={'small'}
                        style={{ width: '100%', marginRight: 20 }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Grid item xs={6}>
                      <ToolTipContainer
                          label={'Device'}
                          tooltipText={
                            'Type of device to pretend while making remote request.'
                          }
                          classes={classes}
                      />

                      <TextField
                          select
                          variant='outlined'
                          size='small'
                          // defaultValue={proxyTypeValue.Auto}
                          value={device}
                          name={"device"}


                          onChange={(e) => {
                            e.persist()
                            let {name,value}=e.target

                            setDevice(value)
                            if(jsRendering){
                              dispatch(getReqCostPrice({
                                "url":urlForTesting,
                                "js_rendering":jsRendering,
                                "device":deviceValue[value]
                              }))

                            }
                            else{
                              dispatch(getReqCostPrice({
                                "url":urlForTesting,
                                "device":deviceValue[value]
                              }))
                            }
                          }}
                          fullWidth
                          SelectProps={{
                            native: true
                          }}
                      >
                        {Object.keys(deviceValue).map((option, index) => (
                            <option key={index} value={option}>
                              {option}
                            </option>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
                <br />
                <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
                  <ToolTipContainer
                      label={'Cookies'}
                      tooltipText={'Forward custom cookies to the website you want to scrape.'}
                      classes={classes}
                  />
                  <TextField
                      value={cookie}
                      name={"cookies"}
                      error={!!cookieError}
                      variant={'outlined'}
                      onFocus={()=>{
                        if(jsRendering){
                          if(cookie_regex.test(cookie)){
                            dispatch(getReqCostPrice({
                              "url":urlForTesting,
                              "js_rendering":jsRendering,
                              "cookies":cookie
                            }))
                          }
                        }
                        else{
                          if(cookie_regex.test(cookie)){
                            dispatch(getReqCostPrice({
                              "url":urlForTesting,
                              "cookies":cookie
                            }))
                          }
                        }


                      }}
                      disabled={props.demoAPILoading}
                      onChange={(e) =>{
                        e.persist()

                        let{name,value}=e.target


                        setCookie(value)

                        // console.log(value.length)

                        if(cookie_regex.test(value)){

                          setCookieError(false)
                        }
                        else if(!cookie_regex.test(value)){
                          setCookieError(true)
                        }
                        if(value.length==0){
                          setCookieError(false)
                        }



                      }}
                      placeholder={'cookie_name_1=cookie_value1;'}
                      fullWidth
                      InputProps={{
                        type: 'text'
                      }}
                      size={'small'}
                      style={{ width: '100%', marginRight: 20 }}
                  />
                </Box>
                <br />
                <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
                  <ToolTipContainer
                      label={'Custom Headers'}
                      tooltipText={'Forward custom headers to the website you want to scrape.'}
                      classes={classes}
                  />
                  {headers.map((item, index) => (
                      <>
                        <Grid container spacing={2} alignItems={"center"}>
                          <Grid item xs={5}>
                            <TextField
                                value={item.key}
                                variant={'outlined'}
                                name={`key-${index}`}
                                onFocus={()=>{
                                  if(jsRendering){
                                    if(checkIfHeaderObjectHasValues){
                                      dispatch(getReqCostPrice({
                                        "url":urlForTesting,
                                        "js_rendering":jsRendering,
                                        "headers":headers
                                      }))
                                    }
                                  }
                                  else{
                                    if(checkIfHeaderObjectHasValues){
                                      dispatch(getReqCostPrice({
                                        "url":urlForTesting,
                                        "headers":headers
                                      }))
                                    }
                                  }




                                }}
                                disabled={props.demoAPILoading}
                                onChange={(e) => onChangeValue(e, index)}
                                placeholder={'Name'}
                                fullWidth
                                InputProps={{
                                  type: 'text'
                                }}
                                size={'small'}
                                style={{ width: '100%', marginRight: 20 }}
                            />
                          </Grid>
                          <Grid item xs={1} style={{textAlign:"center"}}>
                            -
                          </Grid>
                          <Grid item xs={5}>
                            <TextField
                                value={item.value}
                                variant={'outlined'}
                                name={`value-${index}`}
                                disabled={props.demoAPILoading}
                                onChange={(e) => onChangeValue(e, index)}
                                placeholder={'Value'}
                                fullWidth
                                InputProps={{
                                  type: 'text'
                                }}
                                size={'small'}
                                style={{ width: '100%', marginRight: 20 }}
                            />
                          </Grid>

                          <Grid item xs={1}>
                            <IconButton onClick={()=>handleDelete(index)}>
                              <DeleteOutline color={"error"} fontSize={"medium"} />
                            </IconButton>
                          </Grid>



                        </Grid>

                      </>
                  ))}
                  <br />
                  <Button color={'primary'} className={classes.addHeaderBtn} endIcon={<Add />} onClick={addFields} variant={'contained'}>
                    Add Headers
                  </Button>
                </Box>
                <br />
              </Grid>
              <Grid
                  item
                  xs={6}
                  style={{
                    paddingLeft: 30,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%'
                  }}
              >
                <paper style={{ width: '100%', overflow: 'hidden' }}>
                  <Typography style={{ color: '#000', fontWeight: 'bold' }} variant={'body1'}>
                    Request cost {costPrice}
                  </Typography>
                  <Divider
                      style={{
                        background: '#000',
                        width: '5%',
                        margin: '10px 0px 20px 0px',
                        height: '2px'
                      }}
                  />
                  <Grid direction={'column'} className={classes.response}>

                    <div>
                      <CustomTabs onChange={handleCodeChange} value={langValue}>
                        <CustomItemTab label={'cURL'}></CustomItemTab>
                        <CustomItemTab label={'Node'}></CustomItemTab>
                        <CustomItemTab label={'Python'}></CustomItemTab>
                        {/*<CustomItemTab label={'PHP'}></CustomItemTab>*/}

                        <CustomItemTab label={'Java'}></CustomItemTab>
                        {/*<CustomItemTab label={"Ruby"}></CustomItemTab>*/}
                        <CustomItemTab label={'Proxy Mode'}></CustomItemTab>
                      </CustomTabs>
                      <TabPanel1 value={langValue} index={0}>
                        <SyntaxHighlighter language='bash' style={dracula} wrapLines={true}>
                          {getAPICode(langValue)}
                        </SyntaxHighlighter>
                      </TabPanel1>
                      <TabPanel1 value={langValue} index={1}>
                        <SyntaxHighlighter language='javascript' style={dracula}>
                          {getAPICode(langValue)}
                        </SyntaxHighlighter>
                      </TabPanel1>
                      <TabPanel1 value={langValue} index={2}>
                        <SyntaxHighlighter language='python' style={dracula}>
                          {getAPICode(langValue)}
                        </SyntaxHighlighter>
                      </TabPanel1>
                      {/*<TabPanel1 value={langValue} index={3}>*/}
                      {/*  <SyntaxHighlighter language='php' style={dracula}>*/}
                      {/*    {getAPICode(langValue)}*/}
                      {/*  </SyntaxHighlighter>*/}
                      {/*</TabPanel1>*/}
                      <TabPanel1 value={langValue} index={3}>
                        <SyntaxHighlighter language='java' style={dracula}>
                          {getAPICode(langValue)}
                        </SyntaxHighlighter>
                      </TabPanel1>
                      <TabPanel1 value={langValue} index={4}>
                        <SyntaxHighlighter language='proxyMode' style={dracula}>
                          {getAPICode(langValue)}
                        </SyntaxHighlighter>
                      </TabPanel1>
                      {/*<TabPanel value={langValue} index={4}>*/}
                      {/*    <SyntaxHighlighter language="ruby" style={dracula}>*/}
                      {/*        {getAPICode(langValue)}*/}
                      {/*    </SyntaxHighlighter>*/}
                      {/*</TabPanel>*/}
                    </div>
                  </Grid>
                </paper>
                <br />
                <Box display={'flex'} flexDirection={'row'}>
                  <Button variant={'contained'} disabled={props.demoAPILoading || langValue==4} onClick={sendDemoAPIRequest} color={'primary'} className={classes.sendBtn}>
                    {props.demoAPILoading ? <CircularProgress size={24} style={{ color: '#fff' }} /> : 'Send request'}
                  </Button>
                  <Button
                      style={{ marginLeft: '10px', textTransform: 'capitalize' }}
                      color={'primary'}
                      onClick={copyToClipboard}
                      startIcon={<FileCopy />}
                      variant={'outlined'}
                  >
                    Copy to clipboard
                  </Button>
                </Box>
                <Collapse in={tempResponse}>
                  <paper style={{ width: '100%', overflow: 'hidden' }}>
                    <br />
                    <Typography style={{ color: '#000', fontWeight: 'bold' }} variant={'body1'}>
                      Response
                    </Typography>
                    <Typography style={{ fontWeight: 'normal' }} color={'primary'} variant={'body1'}>
                      Status:{' '}
                      <span style={{ fontSize: '14px' }}>
                          {responseCode}&nbsp;{responseStatus}
                        </span>
                    </Typography>
                    <Divider
                        style={{
                          background: '#000',
                          width: '5%',
                          margin: '10px 0px 20px 0px',
                          height: '2px'
                        }}
                    />

                    { bodyData instanceof Blob ? null: (
                        <>
                          {responseValue==0 &&(
                              <Grid container justifyContent={'flex-end'}>
                                <Button
                                    style={{ marginLeft: '10px', textTransform: 'capitalize' }}
                                    color={'primary'}
                                    onClick={copyResponseToClipboard}
                                    startIcon={<FileCopy />}
                                    variant={'outlined'}
                                >
                                  Copy to clipboard
                                </Button>
                              </Grid>
                          )}

                        </>

                    )}

                    <div>
                      <CustomTabs      onChange={(event,newValue)=>handleResponseChange(event,newValue)} value={responseValue}>
                        <CustomItemTab  label={'Body'} value={0}></CustomItemTab>
                        {/*<CustomItemTab  label={'Cookies'} value={1}></CustomItemTab>*/}
                        <CustomItemTab  label={'Headers'} value={2}></CustomItemTab>
                      </CustomTabs>
                      <TabPanel2 value={responseValue} index={0}>
                        {showBody(responseValue)}
                      </TabPanel2>
                      {/*<TabPanel2 value={responseValue} index={1}>*/}
                      {/*  /!*<SyntaxHighlighter language="cookies" style={dracula}>*!/*/}
                      {/*  {getResponseCode(responseValue)}*/}
                      {/*  /!*</SyntaxHighlighter>*!/*/}
                      {/*</TabPanel2>*/}
                      <TabPanel2 value={responseValue} index={2}>
                        {/*<SyntaxHighlighter language="headers" style={dracula}>*/}
                        {getResponseCode(responseValue)}
                        {/*</SyntaxHighlighter>*/}
                      </TabPanel2>
                    </div>

                    {/*<Grid direction={"column"} className={classes.response}>*/}
                    {/*    <SyntaxHighlighter language="bash" style={dracula} wrapLines={true}>*/}
                    {/*        {'{\n' +*/}
                    {/*        '\t"args": {\n' +*/}
                    {/*        '\t\t"json": ""\n' +*/}
                    {/*        '\t},\n' +*/}
                    {/*        '\t"data": "",\n' +*/}
                    {/*        '\t"files": {},\n' +*/}
                    {/*        '\t"form": {},\n' +*/}
                    {/*        '\t"headers": {\n' +*/}

                    {/*        '\t\t"Accept-Encoding": "gzip, deflate",\n' +*/}
                    {/*        '\t\t"Accept-Language": "en-US,en;q=0.9",\n' +*/}
                    {/*        '\t\t"Host": "httpbin.org",\n' +*/}
                    {/*        '\t\t"Upgrade-Insecure-Requests": "1",\n' +*/}
                    {/*        '\t\t"User-Agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/93.0.4577.0 Safari/537.36",\n' +*/}
                    {/*        '\t\t"X-Amzn-Trace-Id": "Root=1-61792370-4f4e239a259be7e071f90548"\n' +*/}
                    {/*        '\t},\n' +*/}
                    {/*        '\t"json": null,\n' +*/}
                    {/*        '\t"method": "GET",\n' +*/}
                    {/*        '\t"origin": "209.127.118.181",\n' +*/}
                    {/*        '\t"url": "http://httpbin.org/anything?json"\n' +*/}
                    {/*        '}'}*/}
                    {/*    </SyntaxHighlighter>*/}
                    {/*</Grid>*/}
                  </paper>
                </Collapse>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    </Grid>
    <br />
  </Grid>

</>

  )
}

export default React.memo(Generator)
