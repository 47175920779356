import { Box, Button, Divider, FormControlLabel, InputLabel, makeStyles, Snackbar, Switch, TextField, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { UiActions } from 'globalUI'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(2),
    textTransform: 'none'
  },
  darkText: {
    color: theme.palette.grey[700]
  }
}))

const Document = () => {
  const classes = useStyles()

  const [successAlert, setSuccessAlert] = useState(false)

  const { handleSubmit, watch, control, setValue } = useForm({
    defaultValues: {},
    mode: 'onBlur'
  })

  const onSubmit = (data) => {
    // console.log(data);
    setSuccessAlert(true)
  }

  return (
    <>
      <Snackbar open={successAlert} autoHideDuration={3000} onClose={() => setSuccessAlert(false)}>
        <Alert onClose={() => setSuccessAlert(false)} severity='success'>
          User info updated successfully.
        </Alert>
      </Snackbar>
      <Box mt={3} mb={2}>
        <Typography variant='body2' color='textSecondary' gutterBottom>
          Configure your Documents here!
        </Typography>
      </Box>
      <Divider light={true} />
      <Box my={4} mx={1} width={'50%'} display='flex' flexDirection='column' style={{ gap: 10 }}>
        <InputLabel className={classes.darkText} htmlFor='expiryDays'>
          {' '}
          Enforce Invitation Expiry Days
        </InputLabel>
        <Controller
          render={({ onChange, onBlur, value, ref }) => (
            <TextField
              size='small'
              variant='outlined'
              type='number'
              id='expiryDays'
              onBlur={onBlur}
              onChange={(e) => onChange(e.target.value)}
              value={value}
              inputRef={ref}
              placeholder='Invitation Expiry Days'
              InputProps={{ inputProps: { min: 1 } }}
            />
          )}
          control={control}
          name={`expiryDays`}
          defaultValue={''}
        />
        <Controller
          render={({ onChange, onBlur, value, ref }) => (
            <FormControlLabel
              control={<Switch onBlur={onBlur} onChange={(e) => onChange(e.target.checked)} checked={value} inputRef={ref} />}
              label={<Typography>Auto-save all invitations send to me in My Account by Default &nbsp; </Typography>}
            />
          )}
          control={control}
          name={`saveInvitation`}
          defaultValue={false}
        />
        <Controller
          render={({ onChange, onBlur, value, ref }) => (
            <FormControlLabel
              control={<Switch onBlur={onBlur} onChange={(e) => onChange(e.target.checked)} checked={value} inputRef={ref} />}
              label={<Typography> Don't Send Invite and Reminder Notifications to Recipients &nbsp; </Typography>}
            />
          )}
          control={control}
          name={`notification`}
          defaultValue={false}
        />
      </Box>
      <Divider />
      <Button variant='contained' color='primary' className={classes.button} onClick={handleSubmit(onSubmit)}>
        Update
      </Button>
    </>
  )
}

const mapStateToProps = (state) => ({
  createData: state.ui.createData,
  inviteeData: state.ui.inviteData
})

const mapDispatchToProps = {
  setCreateData: UiActions.setCreateData,
  setInviteeData: UiActions.setInviteData
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Document))
