import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Typography,
  CircularProgress,
  Modal,
} from "@material-ui/core";
// import {
//   CardComponent,
//   CardNumber,
//   CardExpiry,
//   CardCVV,
//   Provider,
// } from "@chargebee/chargebee-js-react-wrapper";

import {
  createTheme,
  MuiThemeProvider,
  withStyles,
} from "@material-ui/core/styles";

import {
  AssignmentTurnedInOutlined,
  CallMissedOutgoing,
  DoneRounded,
  Sync,
} from "@material-ui/icons";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import {chargeebeeUrlApi} from "auth/manager"
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { compose } from "redux";
import { clientActions } from "getDetails";
import { authActions, CANCEL_SCHEDULE_MODAL } from "auth";
import CodeIcon from "@material-ui/icons/Code";

import DatePicker from "views/components/date-picker";
import StatusTag from "views/components/StatusTags";
import ErrorPage from "../Error/error";
import Dashboard from "./dashboard";

import Plan from "../account/Plan";
import Pricing from "./PlanCard";
import { checkOut } from "getDetails/actions";
import {sendDemoApiRequest} from "../../../auth/actions";

const styles = (theme) => ({
  customBtn: {
    justifyContent: "space-between",
    height: "100%",
    borderRadius: 0,
    textTransform: "none",
  },
  viewLogsButton: {
    margin: "25px 10%",
    backgroundColor: "#0084ec",
    borderRadius: "8px",
    color: "#fff",
    boxShadow: "0px 0px 15px 0px #ccc",
    border: "none",
    textTransform: "none",
  },
  circularProgress: {
    paddingTop: "10px",
  },
  loadingData: {
    height: "60%",
  },
  paper1: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    outline: "none",
    boxShadow: theme.shadows[5],
    padding: "24px 25px 20px 24px",
    borderRadius:"10px"
  },
  modal: {
    backdropFilter: "blur(1px)",
  },
  paper: {
    position: "absolute",
    // width: "90%",
    width: "600px",
    backgroundColor: "#ffff",
    // overflowY: "scroll",
    outline: "none",
    padding: "10px",

    borderRadius: "10px",

    boxShadow: theme.shadows[5],
    // "& .focus-visible": {
    //   outline: "none",
    // },

    // padding: "1px 19px 1px",
  },
});

const infoBar = [
  {
    title: "Invitations made",
    value: "6",
    icon: <CallMissedOutgoing fontSize={"large"} />,
    color: "#6c63ff",
  },
  {
    title: "Onboarding completed",
    value: "1",
    icon: <DoneRounded fontSize={"large"} />,
    color: "#19da69",
  },
  {
    title: "Documents collected",
    value: "5",
    icon: <AssignmentTurnedInOutlined fontSize={"large"} />,
    color: "#FF9800",
  },
];

class Home extends React.Component {
  constructor(props) {
    super(props);
    // console.log(this.props);
    this.state = {
      planData: [],
      checkoutData: {},
      currentPlan: {},
      authenticated: true,
      timer: "",
      open: false,
      modalOpen: false,
      planId: "",

      modalStyle: this.getModalStyle(),

      handleClose: this.handleClose.bind(this),
      handleOpenModal: this.handleOpenModal.bind(this),
      // callData: this.callData.bind(this),
      data: [
        {
          client_id: "aksjdjasdklom",
          name: "Akram singh",
          email: "ak23476@gmail.com",
          phone: "8368621097",
          date: "2021-05-03T18:07:01.560000",
          status: "completed",
        },

        // { client_id:"aksjdjasdklom", name : "Munna Bhaiya",email:"munna@gmail.com" , phone: "9863256898",date: "2020-11-18T18:07:01.560000",  status: "pending" },
        // { client_id:"aksjdjasdklom", name: "Tony Stark", email:"tony@gmail.com" ,phone: "9863256898",date: "2020-11-18T18:07:01.560000",  status: "completed" },
        // { client_id:"aksjdjasdklom", name: "Natasha",email:"nat@gmail.com" , phone: "9863256898",date: "2020-11-18T18:07:01.560000",  status: "pending" },
      ],
    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    // do things with nextProps.someProp and prevState.cachedSomeProp
    var timer;

    // console.log(nextProps);
    // console.log("PrevState", prevState);
    // console.log("nextProps", nextProps);

    // if (nextProps.planDetail !== prevState.planData) {
    //   // console.log("planData " + prevState.planData);
    //   // console.log("PlanId", nextProps.planId);
    //   // const plan = nextProps.planDetail?.plans?.filter((plan) =>
    //   //   plan?.id === nextProps.planId ? plan : null
    //   // );

    //   // console.log("CheckoutPlan", nextProps.planId);

    //   // nextProps.checkoutPlan(nextProps.planId);

    //   var newState = prevState;
    //   newState.planData = nextProps.planDetail;
    // console.log("Length", prevState.checkoutData);

    // console.log("CheckoutPlanACtion", nextProps.initialCheckOut);
    //   return newState;

    // }
    if (nextProps.planId) {
      if (nextProps.checkout?.data?.hosted_page !== prevState.checkoutData) {
        // console.log("CheckoutPlanACtion", nextProps.initialCheckOut);

        if (nextProps.checkout) {
          if (!nextProps.initialCheckOut) {
            nextProps.setInitialCheckout(true);
            nextProps.getPlanId("");
            // console.log("CheckoutPlanACtion", nextProps.initialCheckOut);
            // console.log("CheckoutData", nextProps.checkout);
            var chargebeeInstance = window.Chargebee.getInstance();
            chargebeeInstance.openCheckout({
              hostedPage: async () => {
                // console.log("Url", nextProps.checkout?.data?.hosted_page?.url);
                // We will discuss on how to implement this end point in the next step.

                return await nextProps.checkout?.data?.hosted_page;
              },
              success: function (hostedPageId) {
                // timer = setInterval(prevState.callData, 3000);
                // prevState.timer = timer;
                // console.log(prevState.timer);
                nextProps.currentPlanDetail();
                nextProps.successModalFn(true);

                // console.log("Timer", timer);
                setTimeout(() => {
                  chargebeeInstance.closeAll();
                }, 2000);
              },
              error: function (error) {
                // console.log("error", error);
              },
            });
            var newState = prevState;
            newState.checkoutData = nextProps.checkout?.data?.hosted_page;
            // console.log("New", newState);

            return newState;
          }
        }
      }
    }
    // console.log("NextProps", nextProps);
    // console.log("Prev", prevState);

    if (nextProps.currentPlan !== prevState.currentPlan) {
      // nextProps.currentPlanDetail();
      if (nextProps.currentPlan?.data?.plan_id) {
        // console.log("Timer", prevState.timer);
        // clearInterval(prevState.timer);
        // setTimeout(() => nextProps.currentPlanDetail(), 2000);
        // clearInterval(prevState.timer);
        nextProps.successModalFn(false);
      }
      if (nextProps.currentPlan?.data?.cancelled === true) {
        nextProps.cancelModalFn(false);
      }

      // if (
      //   nextProps.currentPlan?.data?.plan_id ===
      //   prevState.currentPlan?.data?.plan_id
      // ) {
      //   console.log("Props Tiner", timer);
      //   console.log("Heading", nextProps.currentPlan);
      // }
      if (!nextProps.firstPop) {
        if (nextProps.currentPlan) {
          if (
            nextProps.currentPlan?.data?.plan_id === null &&
            nextProps.planId === null
          ) {
            // console.log("TRue open Popup");
            prevState.handleOpenModal();
            nextProps.firstPopShow(true);
          }
        }
      }

      var newState = prevState;
      newState.currentPlan = nextProps.currentPlan;
      return newState;
    }

    return prevState;
    // console.log(nextProps.checkout?.data?.hosted_page);
  }
  // callData() {
  //   this.props.currentPlanDetail();
  // }
  handleOpenModal = () => {
    this.setState({ modalOpen: true });
  };

  handleClose = () => {
    this.setState({ modalOpen: false });
  };
  componentDidMount() {
    // console.log("Authenticated", this.props.authenticated);
    // console.log("Hello");
    console.log(this.props.planDetail);
    // if (!this.props.planDetail) {
    //   this.props.getPlanList();
    // }

    const el = document.createElement('script');
    el.onload = () => {
      chargeebeeUrlApi().then(res=>{
        window.Chargebee.init({
          site: res.data?.data?.chargebee_site,
          // domain: this.props.chargebeeDetails?.data?.chargebee_domain,
          publishableKey: res.data?.data?.chargebee_key,
        });

      }).catch(err=>console.log(err))

      // window.Chargebee.init({
      //   site: this.props.chargebeeDetails?.data?.chargebee_site,
      //   // domain: this.props.chargebeeDetails?.data?.chargebee_domain,
      //   publishableKey: this.props.chargebeeDetails?.data?.chargebee_key,
      // });
      // window.Chargebee.registerAgain();
    }
    el.setAttribute('src', 'https://js.chargebee.com/v2/chargebee.js');

    document.body.appendChild(el);
    this.props.getPlanList();
    this.props.currentPlanDetail();
    if (!this.props.profileData) this.props.getProfileData();
    // if (!this.props.currentPlan) this.props.currentPlanDetail();
    if (!this.props.tokenList) this.props.tokenListCall();
    if (!this.props.apiUsageData) this.props.getApiUsageData();

    if (this.props.planId) {
      this.props.checkoutPlan(this.props.planId);
    }
    // console.log("Plan I", this.props.currentPlan);
    // if (this.props.currentPlan?.data?.plan_id === null) {
    //   console.log("True");
    //   this.setState({ modalOpen: true });
    // }
    // if (!this.props.currentPlan) {
    //   this.setState({ open: true });
    // }

  }
  getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  changeTableStyle = () =>
    createTheme({
      palette: this.props.theme.palette,
      overrides: {
        MUIDataTableBodyCell: {
          root: {
            // textAlign: "center",
            color: this.props.theme.palette.primary.main,
            fontSize: "16px",
          },
        },
        MUIDataTableHeadCell: {
          root: {
            // paddingLeft: "50px",
            // textAlign: "center",
            marginLeft: "auto",
            borderTop: `1px solid ${this.props.theme.palette.grey[300]}`,
            backgroundColor: "#f7f8fb !important",
            color: this.props.theme.palette.primary.main,
            fontSize: "18px",
            fontWeight: 600,
          },
        },
        MuiTableRow: {
          root: {
            "&:nth-child(even)": {
              backgroundColor: "#fafbfe !important",
            },
            "&:nth-child(odd)": {
              backgroundColor: "#ffffff !important",
            },
          },
          hover: {
            "&:hover": {
              backgroundColor: "rgba(122, 139, 237, 0.1)!important",
              cursor: "pointer",
            },
          },
        },
        MuiPaper: {
          root: {
            border: `1px solid ${this.props.theme.palette.grey[300]}`,
            borderBottom: 0,
          },
        },
        MuiToolbar: {
          root: {
            position: "static",
            paddingLeft: "15px !important",
          },
        },
        MuiTableCell: {
          paddingCheckbox: {
            display: "none",
          },
          head: {
            padding: "14px !important",
          },
          root: {
            "&:not(:nth-last-child(1))": {
              borderRight: `1px solid ${this.props.theme.palette.grey[300]}`,
            },
            padding: "14px !important",
          },
        },
      },
    });

  render() {
    const { classes } = this.props;
    // console.log("Classes", classes);
    const { data, open, hideHeading } = this.state;

    // this.props.checkoutPlan(this.state.planId);
    // console.log("planId", this.state.planId);

    // const options = {
    //   pagination: false,
    //   elevation: 0,
    //   filter: false,
    //   selectableRows: "single",
    //   responsive: "vertical",
    //   count: 5,
    //   sort: false,
    //   onRowClick: (rowData) => {
    //     let client_id = rowData[0];
    //     this.props.history.push(`/applicant/${client_id}`);
    //     // const company_id = rowData[0].props.children;
    //     // this.props.userClick(company_id)
    //   },
    //   print: false,
    //   search: false,
    //   viewColumns: false,
    //   download: false,
    //   customToolbar: () => (
    //     <Box display="flex" justifyContent="flex-end" alignItems="center">
    //       {/*<Button component={Link} to="/account/activity-logs" variant="contained"*/}
    //       {/*        color="primary" size="small"*/}
    //       {/*        style={{ textTransform: "none" }}> View all Applicants</Button>*/}
    //       {/*&nbsp;&nbsp;&nbsp;*/}
    //       <IconButton variant="contained" color="primary">
    //         <Sync />
    //       </IconButton>
    //     </Box>
    //   ),
    // };

    return (
      <>
        <Dashboard handleOpenModal={this.handleOpenModal} props={this.props} />
        {this.props.successModal && (
          <Modal open={this.props.successModal} className={classes.modal}>
            <div style={this.state.modalStyle} className={classes.paper1}>
              <Grid container justifyContent="space-between">
                <Grid item xs={2}>
                  <CircularProgress color="primary" />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="body2">
                    Please wait while your payment is being processed
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Modal>
        )}

        {this.state.modalOpen && (
          <Modal
            open={this.state.modalOpen}
            onClose={this.handleClose}
            // className={classes.modal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div style={this.state.modalStyle} className={classes.paper}>
              <Pricing
                handleClose={this.handleClose}
                open={this.state.modalOpen}
                // open={true}
              />
            </div>
          </Modal>
        )}

        {/* <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={11}>
            <Box mt={3} mb={2}>
              {/* <marquee scrollamount="8"> 
              <Typography
                variant="subtitle1"
                style={{
                  marginBottom: 20,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <b>Welcome to</b>
                <b style={{ marginLeft: 5 }}>No</b>
                <CodeIcon style={{ fontSize: "20" }} color={"primary"} />
                <b>Pdf</b>
              </Typography>
              {/* </marquee> 
            </Box>
          </Grid>
          <>
            <Grid container item sm={11} xs={12}>
              {/* <Box display="flex" width="100%" my={2}></Box> 
              <Grid container item xs={12} justifyContent="center">
                {infoBar.map((info, k) => (
                  <Grid item xs={12} md={4} key={k}>
                    <Paper
                      style={{
                        marginRight: `${k !== infoBar.length - 1 && "1rem"}`,
                        boxShadow: "0px 0px 30px -8px #ccc",
                        background: info.color,
                        color: "#fff",
                      }}
                    >
                      <Box p={2}>
                        <Typography variant="h6" gutterBottom>
                          {info.title}
                        </Typography>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography variant="h3">{info.value}</Typography>
                          <IconButton
                            onClick={this.toogleOpenDoc}
                            style={{ color: "#fff" }}
                          >
                            {info.icon}
                          </IconButton>
                        </Box>
                      </Box>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <Box my={2}>
                    <MuiThemeProvider theme={this.changeTableStyle()}>
                      <MUIDataTable
                        title={`Applicants List`}
                        data={data}
                        columns={columns}
                        options={options}
                      />
                    </MuiThemeProvider>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </>
        </Grid> */}
      </>
    );
  }
}

// const columns = [
//   {
//     name: "client_id",
//     options: {
//       display: false,
//     },
//   },
//   {
//     name: "S.no",
//     label: "#",
//     options: {
//       filter: false,
//       sort: false,
//       empty: true,
//       customBodyRender: (d, tableMeta) => {
//         return (
//           <Box display="flex" justifyContent="center">
//             <Typography variant="subtitle2" color="textPrimary">
//               {tableMeta.rowIndex + 1}.{" "}
//             </Typography>
//           </Box>
//         );
//       },
//       customHeadLabelRender: (cm) => (
//         <Box display="flex" justifyContent="center">
//           {cm.label}
//         </Box>
//       ),
//     },
//   },
//   {
//     name: "name",
//     label: "Name",
//     options: {
//       customBodyRender: (value) => (
//         <Typography variant="body2" color={"textPrimary"}>
//           {value}
//         </Typography>
//       ),
//     },
//   },
//   {
//     name: "email",
//     label: "Email",
//     options: {
//       customBodyRender: (value) => (
//         <Typography variant="body2" color={"textPrimary"}>
//           {value}
//         </Typography>
//       ),
//     },
//   },
//   {
//     name: "phone",
//     label: "Phone Number",
//     options: {
//       customBodyRender: (value) => (
//         <Typography variant="body2" color={"textPrimary"}>
//           {value}
//         </Typography>
//       ),
//     },
//   },
//   {
//     name: "date",
//     label: "Date",
//     options: {
//       customBodyRender: (d) => (
//         <>
//           <Typography variant="subtitle2" color="textPrimary">
//             {moment(d).format("ll")}
//           </Typography>
//           <Typography variant="caption" color="textSecondary">
//             {moment(d).format("LTS")}
//           </Typography>
//         </>
//       ),
//     },
//   },
//   {
//     name: "status",
//     label: "Status",
//     options: {
//       customBodyRender: (val) => <StatusTag value={val} />,
//     },
//   },
// ];

//=====================================
//  CONNECT
//-------------------------------------

const mapStateToProps = (state) => {
  return {
    planDetail: state.clientData.planDetail,
    authenticated: state.auth.authenticated,
    checkout: state.clientData.checkout,
    initialCheckOut: state.clientData.initialCheckOut,
    planId: state.clientData.planId,
    currentPlan: state.clientData.currentPlan,
    profileData: state.clientData.profileData,
    tokenList: state.auth.tokenLists,
    apiUsageData: state.clientData.apiUsage,
    demoAPILoading: state.auth.demoAPILoading,
    responseFromDemoAPI: state.auth.responseFromDemoAPI,
    statusCode:state.auth.statusCode,
    errorMsg:state.auth.errorMsg,
    firstPop: state.planPopRed.first,
    successModal: state.modalRed.successModalOpen,
    cancelModal: state.modalRed.cancelModalState,
  };
};
const mapDispatchToProps = {
  getPlanList: clientActions.getPlanList,
  sendDemoApiRequest: authActions.sendDemoApiRequest,
  checkoutPlan: clientActions.checkOut,
  setInitialCheckout: clientActions.setInitialCheckout,
  getPlanId: clientActions.getPlanID,
  tokenListCall: authActions.tokenList,
  currentPlanDetail: clientActions.getCurrentPlanDetails,
  firstPopShow: clientActions.firstShow,
  successModalFn: authActions.successModal,
  cancelModalFn: authActions.cancelModal,
  getProfileData: clientActions.getProfileDetails,
  getApiUsageData: clientActions.getApiUsageDetail,
  cancelScheduleFn: () => (dispatch) =>
    dispatch({ type: CANCEL_SCHEDULE_MODAL, payload: false }),
};

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Home);
