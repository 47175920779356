import {
  Box,
  Button,
  InputLabel,
  makeStyles,
  Paper,
  Snackbar,
  TextField,
  Typography,
  Grid,
  createMuiTheme,
  MuiThemeProvider,
  Divider
} from '@material-ui/core'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { getCountryCallingCode, isValidPhoneNumber } from 'react-phone-number-input'
import { Alert } from '@material-ui/lab'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import LoginSVG from 'assets/signupbackgroundsvg.svg'
// import BackgroundSVG from "assets/bacground.svh.svg";
import { authActions } from 'auth'
import { UiActions } from 'globalUI'
import React, { useRef, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useHistory, withRouter } from 'react-router'
import { isValid } from 'date-fns'
import classNames from 'classnames'
import BackgroundHexagon from 'assets/background-hexagon.svg'
import BackgroundBlocks from 'assets/background-blocks.svg'
import CodeIcon from '@material-ui/icons/Code'
import LoginGraphic from 'assets/home-graphic.svg'
import Logo from '../../../assets/logo.png'

const useStyles = makeStyles((theme) => ({
  fancyBg: {
    backgroundColor: '#edf2fe'
    // backgroundImage: " linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)",
    // backgroundImage: "linear-gradient(135deg, #000046 0%, #1CB5E0 100%)",
    // backgroundImage: `url("${BackgroundHexagon}")`,
    // backgroundSize: "cover",
    // backgroundRepeat: "no-repeat",
    // backgroundPosition: "center",
  },
  paperRoot: {
    position: 'relative',
    width: '70%',
    minHeight: '80vh',
    // padding:"50px",
    margin: '20px 0px',
    display: 'flex',
    // padding: "4rem",
    // backgroundColor: "rgba( 255, 255, 255, 0.4 )",
    boxShadow: '0 8px 32px 0 rgba( 31, 38, 135, 0.37 )',
    backdropFilter: 'blur(10px)',
    '-webkit-backdrop-filter': 'blur( 10.0px )',
    borderRadius: '10px',
    // border: "1px solid rgba( 255, 255, 255, 0.18 )",
    zIndex: 100
  },
  tokenInput: {
    width: '100%',
    margin: '10px 0 25px',
    borderRadius: 5,
    backgroundColor: '#ffffff66'
  },
  imageBackground: {
    background: '#4263eb',
    borderBottomLeftRadius: '10px',
    borderTopLeftRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  customPhoneInput: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #ccc',
    padding: '6px 0px',
    '& .PhoneInputInput': {
      flex: '1 1',
      minWidth: 0,
      background: 'hsla(0,0%,100%,.4)',
      outline: 'none',
      border: 'none',
      padding: '11px 0'
    },
    '& .PhoneInputCountry': {
      paddingLeft: 10
    },
    '&:hover': {
      border: '1px solid #111'
    },
    '&:focus-within': {
      border: '1.5px solid #000046;'
    }
  },
  buttons: {
    width: '60%',
    padding: '12px 32px',
    fontFamily: 'AvertaRegular',
    // backgroundImage: "linear-gradient(135deg, #000046 0%, #1CB5E0 100%)",
    backgroundColor: '#4263eb',
    borderRadius: '8px',
    fontWeight: '600',
    boxShadow: '0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)'
  },
  whiteColor: {
    color: theme.palette.secondary.contrastText
  },
  leftBox: {
    position: 'relative',
    background: theme.palette.primary.light,
    borderBottomLeftRadius: '10px',
    borderTopLeftRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))
const theme = createMuiTheme({
  overrides: {
    MuiInputBase: {
      root: {
        padding: '6px 0'
      }
    }
  }
})

function SignUp(props) {
  const classes = useStyles()
  const [country, setCountry] = useState('')
  const [region, setRegion] = useState('')
  const [phone, setPhone] = useState('')
  const [callingCode, setCallingCode] = useState('')

  const fNameInput = useRef(null)
  const cNameInput = useRef(null)
  const phoneInput = useRef(null)
  const emailInput = useRef(null)

  const [error, setError] = useState(null)
  // console.log("Phone", phone);
  // console.log("Country", country);
  // console.log("Calling", callingCode);

  const handlePhone = (val) => {
    setPhone(val)
  }

  const handleOnboard = async (e) => {
    e.preventDefault()

    props.clearError()
    const { submitUserDetails } = props

    // const mobile = phoneInput.current.value;
    // const email = emailInput.current.value;

    // const validEmail =
    //   /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    //     email
    //   );
    // if (!validEmail && email.trim() !== "") {
    //   emailInput.current.focus();
    //   setError("Enter a valid email address");
    //   return;
    // }

    // const validPhone =
    //   /^\+?([0-9]{2})\)?[-. ]?([0-9]{5})[-. ]?([0-9]{5})$/.test(phone) ||
    //   /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(phone);
    // if (!validPhone && phone.trim() !== "") {
    //   // phoneInput.current.focus();
    //   setError("Enter a valid phone number");
    //   return;
    // }
    const mobileNumber = phone.replace(`+${callingCode}`, '')
    // console.log("Phone", phone);
    if (isValidPhoneNumber(phone)) {
      let data
      data = {
        name: fNameInput.current.value,
        company: cNameInput.current.value,
        // email,
        mobile: mobileNumber,
        calling_code: `+${callingCode}`,
        country_code: country
      }
      await submitUserDetails(data)
    } else {
      setError('Please enter an valid mobile number')
      return
    }
  }

  useEffect(() => {
    setError(props.errormsg)
  }, [props.errormsg])

  return (
    <Box display='flex' justifyContent='center' alignItems='center' minHeight='100vh' className={classes.fancyBg}>
      <Snackbar open={!!error} autoHideDuration={3000} onClose={() => setError(null)}>
        <Alert onClose={() => setError(null)} severity='error'>
          {error}
        </Alert>
      </Snackbar>
      <Paper className={classes.paperRoot}>
        <Box
          display='flex'
          justifyContent='center'
          width='50%'
          // p={1}
          className={classes.leftBox}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 40
            }}
          >
            <Typography
              className={classes.whiteColor}
              variant={'h5'}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              Welcome to
              <img src={Logo} style={{ padding: 4, height: '3.2rem' }} />
            </Typography>

            <br />
            <Divider
              style={{
                height: '3px',
                background: '#000',
                width: '30%'
              }}
            />
            <br />
            <Typography variant={'subtitle1'}>Scrape any website and extract data easily</Typography>
            <br />
            <img style={{ width: '70%' }} src={'https://scrapingpass.com/wp-content/uploads/2020/10/Asset-8@4x-1.png'} />
            {/*<Typography variant={"h5"}*/}
            {/*            style={{fontWeight:"bold"}}*/}
            {/*            align={"center"}>Scrape any website*/}
            {/*  and extract data*/}
            {/*  easily with us.</Typography><br/>*/}
            {/*<Typography variant={"body1"} align={"center"}>*/}
            {/*  We provide you with powerful web scraping and robotic process automation (RPA) tools to make your work easy for you. Check out our services below.*/}
            {/*</Typography>*/}
            <br />
          </Box>
        </Box>
        <Box width='50%' py={5} px={5}>
          <Typography
            variant='h4'
            style={{
              // paddingTop: "40px",
              marginBottom: 40,
              display: 'flex',
              alignItems: 'center',
              fontWeight: '600',
              letterSpacing: '0px',
              color: '#000000',
              opacity: '1'
            }}
          >
            Let's Get Started!
          </Typography>
          <form onSubmit={handleOnboard}>
            <MuiThemeProvider theme={theme}>
              <InputLabel htmlFor='fullName' style={{ fontWeight: '400', color: '#000' }}>
                Full Name
              </InputLabel>
              <TextField
                size='small'
                variant='outlined'
                placeholder='ex: John Doe'
                id='fullName'
                className={classes.tokenInput}
                required
                autoComplete='on'
                inputRef={fNameInput}
              />

              <InputLabel htmlFor='companyName' style={{ fontWeight: '400', color: '#000' }}>
                Company Name
              </InputLabel>
              <TextField
                size='small'
                variant='outlined'
                id='companyName'
                placeholder='ex: Surepass technologies pvt ltd'
                className={classes.tokenInput}
                autoComplete='on'
                inputRef={cNameInput}
              />
            </MuiThemeProvider>

            {/* <InputLabel htmlFor="email">Email</InputLabel>
            <TextField size="small" variant="outlined" id="email"
              className={classes.tokenInput} autoComplete="on"
              inputRef={emailInput}
            /> */}

            {/* <CountryDropdown
              className={classes.tokenInput}
              style={{ padding: "11px 0px", outline: "none" }}
              value={country}
              onChange={(val) => setCountry(val)}
            /> */}

            <InputLabel htmlFor='phone' style={{ fontWeight: '400', color: '#000' }}>
              Phone Number
            </InputLabel>
            <PhoneInput
              // size="small"
              // variant="outlined"
              // id="phone"
              international
              onCountryChange={(countryCode) => {
                setCountry(countryCode)
                setCallingCode(getCountryCallingCode(countryCode))
              }}
              defaultCountry='US'
              className={classNames(classes.tokenInput, classes.customPhoneInput)}
              autoComplete='on'
              value={phone}
              countryCallingCodeEditable={false}
              // type="tel" inputProps={{
              //   pattern: "[0-9]{10,14}",
              // }}

              onChange={(val) => handlePhone(val)}
              error={phone ? (isValidPhoneNumber(phone) ? undefined : 'Invalid Phone Number') : ``}
            />
            <br />

            <Grid container justifyContent='center'>
              <Button variant='contained' color='primary' type='submit' className={classes.buttons}>
                Create Account
              </Button>
            </Grid>
          </form>
        </Box>
      </Paper>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  // createData: state.ui.createData,
  // inviteeData: state.ui.inviteData,
  errormsg: state.auth.errorMsg
})

const mapDispatchToProps = {
  submitUserDetails: authActions.submitUserDetails,
  clearError: authActions.clearError
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUp))
