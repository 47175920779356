import React, { useState, useEffect } from 'react'
import { Line, Bar, Pie, Doughnut, Radar } from 'react-chartjs-2'
import { Grid } from '@material-ui/core'
import moment from 'moment'

function LineChart({ apiUsage }) {
  let label = []
  let data = []
  let currentDate = new Date()

  let dataValue = 0
  let a = 1
  let array = {
    date: [],
    amount: []
  }
  // console.log(apiUsage);
  // const duplarr = apiUsage?.data?.map((d, index) => {
  //   // console.log("D", d);
  //   let date = d?.created_at?.slice(5, 10);
  //   // console.log(date)

  //   // console.log("first time");
  //   if (d?.created_at === currentDate) {
  //     dataValue += d?.amount;
  //     // label.push(moment(currentDate).format("MMMM Do"));
  //     data.push(dataValue);
  //     if (apiUsage?.data?.length - 1 < index + 1) {
  //       label.push(moment(currentDate).format("MMMM Do"));
  //       data.push(dataValue);
  //       // console.log("if Statememnt:");
  //       // console.log("Data", data);
  //       // console.log("Label", label);
  //     }
  //   } else {
  //     if (apiUsage?.data?.length <= 1) {
  //       // console.log("D amount", d?.amount);
  //       dataValue += d?.amount;
  //       // console.log("Data", dataValue);
  //       // console.log(d?.created_at);
  //       data.push(dataValue);
  //       label.push(moment(d?.created_at).format("MMMM Do"));
  //     }

  //     // console.log(currentDate);
  //     // console.log(d);

  //     // console.log(label);
  //     else {
  //       data.push(dataValue);
  //       label.push(moment(currentDate).format("MMMM Do"));
  //       currentDate = date;
  //       dataValue = 0;

  //       // console.log("Else Statememnt:");
  //       // console.log("Data", data);
  //       // console.log("Label", label);
  //     }
  //     // console.log("Else Statememnt:");
  //     // console.log("Data", data);
  //     // console.log("Label", label);
  //   }
  // });

  const duplarr = apiUsage?.data?.map((d, index) => {
    let date = d?.created_at?.slice(5, 10)

    if (currentDate === date) {
      if (d?.created_at?.slice(5, 10) === currentDate) {
        dataValue += d?.amount
        if (apiUsage?.data?.length - 1 < index + 1) {
          label.push(moment(currentDate).format('MMMM Do'))
          data.push(dataValue)
        }
      }
    } else {
      if (apiUsage?.data?.length <= 1) {
        // console.log("D amount", d?.amount);
        dataValue += d?.amount
        // console.log("Data", dataValue);
        // console.log(d?.created_at);
        data.push(dataValue)
        label.push(moment(d?.created_at).format('MMMM Do'))
      } else {
        data.push(dataValue)
        label.push(moment(currentDate).format('MMMM Do'))
        currentDate = date
        dataValue = 0
      }
    }
  })
  // console.log(label, data);
  const chartData = {
    labels: label.reverse(),
    datasets: [
      {
        label: 'Credits',
        data: data.reverse(),
        fill: false,
        borderColor: 'rgb(75,192,192)',
        tension: 0.1
      }
    ],
    options: {
      title: {
        display: true,
        text: 'No<>Pdf',
        fontSize: 24
      },
      plugins: {
        legend: {
          display: false
        }
      },
      scales: {
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: 'Credits'
            }
          }
        ],
        xAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: 'Days'
            }
          }
        ]
      },
      layout: {
        padding: {
          left: 10,
          right: 0,
          bottom: 0,
          top: 0
        }
      },
      tooltips: {
        enabled: false
      }
    }
  }
  // console.log(chartData?.options);

  return (
    <Grid container style={{ paddingLeft: '20px' }}>
      <Line data={chartData} height={80} options={chartData.options} />
    </Grid>
  )
}

export default React.memo(LineChart)
