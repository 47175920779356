import React, { useState, useEffect } from 'react'
import { Grid, Button, Typography, Modal, CircularProgress, makeStyles, Snackbar } from '@material-ui/core'

import { useDispatch, useSelector } from 'react-redux'
import { cancelModal, clearError, successModal, tokenList } from 'auth/actions'

import { cancelSubscriptionPlan, getCurrentPlanDetails } from 'getDetails/actions'
import { Alert } from '@material-ui/lab'
import { CANCEL_SUBSCRIPTION } from 'getDetails'

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  }
}

{
  /* <div>
    <ModalBody
      classes={classes}
      handleClose={handleClose}
      handleCancelation={handleCancelation}
    />
  </div> */
}
{
  /* <Body openModal={openModal} closeModal={this.closeModal} /> */
}

const useStyles = makeStyles((theme) => ({
  paper1: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    outline: 'none',
    boxShadow: theme.shadows[5],
    borderRadius: '10px',
    padding: '24px 25px 20px 24px'
  },
  modal: {
    backdropFilter: 'blur(1px)'
  },
  paper: {
    position: 'absolute',
    width: 450,
    backgroundColor: '#ffff',
    overflowY: 'auto',
    outline: 'none',
    borderRadius: '10px',
    padding: '24px 30px 20px',
    boxShadow: theme.shadows[5]
    // "& .focus-visible": {
    //   outline: "none",
    // },

    // padding: "1px 19px 1px",
  },
  text: {
    paddingBottom: '32px',
    fontSize: '25px',
    fontWeight: '600',
    lineHeight: '1.5'
  },
  danger: {
    background: 'red',
    color: 'rgb(255, 255, 255)',
    marginRight: '10px',
    fontSize: '12px',
    fontWeight: '600',
    '&:hover': {
      background: 'red'
    }
  }
}))

const ModalBody = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [modalStyle] = useState(getModalStyle)
  const [error, setError] = useState('')
  const { errormesg, status_code, cancelSubscription } = useSelector((state) => state.planReducer)
  const { cancelModalState } = useSelector((state) => state.modalRed)
  const { currentPlan } = useSelector((state) => state.clientData)
  const handleCancelation = (e) => {
    e.preventDefault()
    // dispatch(cancelModal(true));
    setOpen(true)
    dispatch({
      type: CANCEL_SUBSCRIPTION,
      payload: null
    })
    dispatch(cancelSubscriptionPlan())
    dispatch(getCurrentPlanDetails())
    props.setOpenModal(false)
    // setTimeout(() => {
    //   dispatch(getCurrentPlanDetails());
    // }, 400);
  }
  // console.log("Cancel Subscription", cancelSubscription);

  const handleClose = () => {
    props.setOpenModal(false)
    // props.setOpen(false);
  }

  useEffect(() => {
    let ignore = false
    if (!ignore) {
      if (open) {
        setTimeout(() => dispatch(getCurrentPlanDetails()), 3000)
      }
    }

    if (currentPlan?.data?.cancelled === true && cancelSubscription?.data?.success === true) {
      setOpen(false)
      ignore = true
    }
  }, [cancelSubscription, currentPlan])
  //   const handleModal = (e) => {
  //     e.preventDefault();
  //     props.setOpenModal(true);
  //     // dispatch(cancelModal(true));
  //   };
  useEffect(() => {
    setError(errormesg)
    if (errormesg) {
      setOpen(false)
    }
  }, [errormesg])
  return (
    <>
      <Modal
        open={props.openModal}
        onClose={(event, reason) => handleClose(event, reason)}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
      >
        <div style={modalStyle} className={classes.paper}>
          <Grid container>
            <Grid container item justifyContent='center'>
              <Typography variant='subtitle1' color='textPrimary' align='center' className={classes.text}>
                Do you really want to cancel this plan?
              </Typography>
            </Grid>
            <Grid container item justifyContent='center'>
              <Button
                variant='text'
                style={{
                  marginRight: '10px',
                  color: '#111',
                  /* font-size: 12px; */
                  fontWeight: '600'
                }}
                onClick={handleClose}
              >
                Dismiss
              </Button>
              <Button variant='contained' className={classes.danger} onClick={(e) => handleCancelation(e)}>
                Schedule Cancellation
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
      {errormesg ? (
        <Snackbar
          open={!!error}
          autoHideDuration={3000}
          onClose={() => {
            setError(null)
            // dispatch(clearError());
          }}
        >
          <Alert
            onClose={() => {
              setError(null)
              // dispatch(clearError());
            }}
            severity='error'
          >
            {status_code}&nbsp;
            {error}
          </Alert>
        </Snackbar>
      ) : (
        <Modal open={open} className={classes.modal}>
          <div style={modalStyle} className={classes.paper1}>
            <Grid container justifyContent='space-between'>
              <Grid item xs={1}>
                <CircularProgress color='primary' />
              </Grid>
              <Grid item xs></Grid>
              <Grid item xs={10}>
                <Typography variant='body2'>Please wait while your subscription is being cancelled</Typography>
              </Grid>
            </Grid>
          </div>
        </Modal>
      )}
    </>
  )
}

export default ModalBody
