import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import React from 'react'
import {
  AttachMoneyRounded,
  BlurCircularOutlined,
  DashboardOutlined,
  DescriptionOutlined,
  DesktopWindows,
  FolderOpenRounded,
  HistoryRounded,
  NaturePeopleOutlined,
  PermIdentity,
  SettingsRounded,
  LockOpen,
  LocalOffer,
  DonutLarge
} from '@material-ui/icons'
import DataUsageIcon from '@material-ui/icons/DataUsage'
import { makeStyles } from '@material-ui/core/styles'
import SupervisorAccountRoundedIcon from '@material-ui/icons/SupervisorAccountRounded'
import { Link, Route } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  listColor: {
    // backgroundColor: "#7A8BED",
    // backgroundColor: "#00bcd429",
    backgroundColor: 'rgb(66 99 235 / 9%)',
    padding: '.8rem 1rem 0.8rem 22px',
    borderLeft: `5px solid ${theme.palette.primary.main}`
  },
  listDefault: {
    padding: '.8rem 1rem 0.8rem 22px',
    '&:hover': {
      backgroundColor: '#cccccc5e'
    }
  },
  listItemActiveText: {
    fontSize: '1rem',
    // color: "#ffffff"
    color: theme.palette.primary.main,
    fontWeight: 600
  },
  listItemDefaultText: {
    fontSize: '1rem',
    color: '#707070'
  },
  listItemIconActive: {
    // color: "#fff"
    color: theme.palette.primary.main
  },
  listItemIconDefault: {
    color: theme.palette.primary.main
  }
}))

const Data = ({ home, props }) => {
  const classes = useStyles()

  //default styles
  let listDefault = classes.listDefault
  let listItemIconDefault = classes.listItemIconDefault
  let listItemDefaultText = classes.listItemDefaultText

  //active color
  let listItemStyles = classes.listColor
  let listItemIcon = classes.listItemIconActive
  let listItemText = classes.listItemActiveText

  const NavLink = ({ to, children, className, activeClassName, ...rest }) => {
    const path = typeof to === 'object' ? to.pathname : to
    return (
      <Route
        path={path}
        children={({ match }) => {
          const isActive = match
          return (
            <Link {...rest} to={to}>
              {typeof children === 'function' ? children(isActive) : children}
            </Link>
          )
        }}
      />
    )
  }

  return (
    <div>
      {/* For Dashboard */}
      {home &&
        HomeArray.map((item, k) => {
          return (
            <NavLink to={`${item.name === 'billing' ? `/${item.name}` : `/${item.name}`}`} key={k}>
              {(isActive) => (
                <ListItem button className={isActive ? listItemStyles : listDefault}>
                  <ListItemIcon className={isActive ? listItemIcon : listItemIconDefault}>{item.icon}</ListItemIcon>
                  <ListItemText disableTypography={true} className={isActive ? listItemText : listItemDefaultText}>
                    {item.title}
                  </ListItemText>
                </ListItem>
              )}
            </NavLink>
          )
        })}

      {!home &&
        AccountArray.map((item, k) => {
          return (
            <NavLink to={`/account/${item.name}`} key={k}>
              {(isActive) => (
                <ListItem button className={isActive ? listItemStyles : listDefault}>
                  <ListItemIcon className={isActive ? listItemIcon : listItemIconDefault}>{item.icon}</ListItemIcon>
                  <ListItemText disableTypography={true} className={isActive ? listItemText : listItemDefaultText}>
                    {item.title}
                  </ListItemText>
                </ListItem>
              )}
            </NavLink>
          )
        })}
    </div>
  )
}

const HomeArray = [
  { name: 'home', title: 'API Usage', icon: <DataUsageIcon /> },
  // { name: "documents", title: "Documents", icon: <DescriptionOutlined /> },
  // { name: "profile", title: "Profile", icon: <PermIdentity /> },
  { name: 'billing', title: 'Billing', icon: <AttachMoneyRounded /> },
  { name: 'plan', title: 'Subscriptions', icon: <LocalOffer /> },
  {
    name: 'authentication_logs',
    title: 'Authentication Logs',
    icon: <LockOpen />
  },
  {
    name: 'api-keys',
    title: 'API Keys',
    icon: <DonutLarge />
  }

  // { name: "templates", title: "Templates", icon: <DashboardOutlined /> },
  // { name: "stamps", title: "Stamps", icon: <BlurCircularOutlined /> },
  // { name: "folders", title: "Folders", icon: <FolderOpenRounded /> },
]

const AccountArray = [
  { name: 'profile', title: 'Profile', icon: <PermIdentity /> },
  { name: 'settings', title: 'Settings', icon: <SettingsRounded /> },
  { name: 'billing', title: 'Billing', icon: <AttachMoneyRounded /> },
  { name: 'department', title: 'Department', icon: <NaturePeopleOutlined /> },
  { name: 'activity-logs', title: 'Activity Logs', icon: <HistoryRounded /> }
]

export default React.memo(Data)
