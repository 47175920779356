import { Box, FormControlLabel, makeStyles, Switch, Typography, useTheme } from '@material-ui/core'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { UiActions } from 'globalUI'
import MUIDataTable from 'mui-datatables'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { DatePicker } from 'views/components/date-picker/DatePicker'

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(2),
    textTransform: 'none'
  },
  darkText: {
    color: theme.palette.grey[700]
  }
}))

const Invoices = () => {
  const classes = useStyles()
  const theme = useTheme()

  const [proformaInvoices, setProformaInvoices] = useState(false)

  const changeTableStyle = () =>
    createMuiTheme({
      palette: theme.palette,
      overrides: {
        MUIDataTableToolbar: {
          root: {
            display: 'none'
          }
        },
        MUIDataTableBodyCell: {
          root: {
            color: theme.palette.primary.main,
            fontSize: '14px'
          }
        },
        MUIDataTableHeadCell: {
          root: {
            marginLeft: 'auto',
            // borderTop: "1px solid #ccc",
            backgroundColor: '#f7f8fb !important',
            color: theme.palette.primary.main,
            fontSize: '14px',
            fontWeight: 600
          }
        },
        MuiTableRow: {
          root: {
            '&:nth-child(even)': {
              backgroundColor: '#fafbfe !important'
            },
            '&:nth-child(odd)': {
              backgroundColor: '#ffffff !important'
            }
          },
          hover: {
            '&:hover': {
              backgroundColor: 'rgba(122, 139, 237, 0.1)!important'
            }
          }
        },
        MuiPaper: {
          root: {
            border: `1px solid ${theme.palette.grey[300]}`
          },
          rounded: {
            borderRadius: 0
          }
        },
        MuiToolbar: {
          root: {
            position: 'static',
            paddingLeft: '15px !important'
          }
        },
        MuiTableCell: {
          paddingCheckbox: {
            display: 'none'
          },
          root: {
            '&:not(:nth-last-child(1))': {
              borderRight: `1px solid ${theme.palette.grey[300]}`
            },
            padding: '10px !important'
          },
          head: {
            padding: '10px !important'
          },
          footer: {
            border: 0
          }
        },
        MUIDataTablePagination: {
          tableCellContainer: {
            paddingTop: '0 !important',
            paddingBottom: '0 !important'
          }
        }
      }
    })

  const options = {
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 20, 50],
    jumpToPage: true,
    pagination: true,
    elevation: 0,
    selectableRows: 'none',
    responsive: 'simple',
    sort: false
  }

  const columns = [
    {
      name: 'id',
      label: '#',
      options: {
        empty: true,
        customBodyRender: (d, tableMeta) => (
          <Typography variant='subtitle2' color='textPrimary'>
            {tableMeta.rowIndex + 1}.{' '}
          </Typography>
        )
      }
    },
    {
      name: 'denomination',
      label: 'Invoice No',
      options: {
        customBodyRender: (d) => (
          <>
            <Typography variant='body1' color='textPrimary'>
              {d || '-'}
            </Typography>
          </>
        )
      }
    },
    {
      name: 'state',
      label: 'Invoice Type',
      options: {
        display: !proformaInvoices,
        customBodyRender: (d) => (
          <>
            <Typography variant='body1' color='textPrimary'>
              {d || '-'}
            </Typography>
          </>
        )
      }
    },
    {
      name: 'total',
      label: 'Created At',
      options: {
        customBodyRender: (d) => (
          <>
            <Typography variant='body1' color='textPrimary'>
              {d || '-'}
            </Typography>
          </>
        )
      }
    }
  ]

  return (
    <>
      <Box my={3} display='flex' justifyContent='space-between'>
        <FormControlLabel
          control={<Switch onChange={(e) => setProformaInvoices(e.target.checked)} checked={proformaInvoices ?? false} />}
          label={<Typography variant='body2'>Show Proforma Invoices</Typography>}
        />
        <DatePicker rightAlign={true} />
      </Box>
      <Box my={2}>
        <Typography variant='subtitle1' gutterBottom>
          {proformaInvoices ? 'Proforma Invoices' : 'Invoices'}
        </Typography>
        <MuiThemeProvider theme={changeTableStyle()}>
          <MUIDataTable data={[]} columns={columns} options={options} />
        </MuiThemeProvider>
      </Box>
    </>
  )
}

const mapStateToProps = (state) => ({
  createData: state.ui.createData,
  inviteeData: state.ui.inviteData
})

const mapDispatchToProps = {
  setCreateData: UiActions.setCreateData,
  setInviteeData: UiActions.setInviteData
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Invoices))
