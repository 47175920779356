import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'
import { authReducer, SIGN_OUT_SUCCESS, modalReducer } from './auth'
import { loadingBarReducer } from 'react-redux-loading-bar'
import history from './history'
import { clientsReducer, planReducer, planPopReducer } from 'getDetails/reducer'

const appReducers = combineReducers({
  auth: authReducer,
  modalRed: modalReducer,
  clientData: clientsReducer,
  planPopRed: planPopReducer,
  router: connectRouter(history),
  planReducer: planReducer,
  loadingBar: loadingBarReducer
})
const rootReducer = (state, action) => {
  if (action.type === SIGN_OUT_SUCCESS) {
    return appReducers(undefined, action)
  }

  return appReducers(state, action)
}

export default rootReducer
