import { Box, Grid, IconButton, MenuItem, TextField, Typography, useTheme } from '@material-ui/core'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { HistoryRounded, Sync } from '@material-ui/icons'
import { withStyles } from '@material-ui/styles'
import moment from 'moment'
import MUIDataTable from 'mui-datatables'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import DatePicker from 'views/components/date-picker'
import { debounceSearchRender } from 'views/components/DebounceSearchRender'
import StatusTag from 'views/components/StatusTags'

const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: `0`
      }
    },
    '& .MuiInputBase-root': {
      fontSize: '0.85rem'
    },
    '& .MuiFormLabel-root': {
      fontSize: '0.9rem'
    }
  }
})(TextField)

function Logs() {
  const AllData = [
    {
      date: '2020-11-18T18:07:01.560000',
      remarks: 'Sign-in attempted using PASSWORD on email (sayefe8121@sofiarae.com). Status: Success.',
      reportType: 'signIn'
    },
    {
      date: '2021-11-18T18:07:01.560000',
      remarks: 'User signed out. Status: Success.',
      reportType: 'signOut'
    },
    {
      date: '2020-11-18T18:07:01.560000',
      remarks: 'Sign-in attempted using PASSWORD on email (sayefe8121@sofiarae.com). Status: Success.',
      reportType: 'signIn'
    },
    {
      date: '2021-11-18T18:07:01.560000',
      remarks: 'User signed out. Status: Success.',
      reportType: 'signOut'
    },
    {
      date: '2020-11-18T18:07:01.560000',
      remarks: 'Sign-in attempted using PASSWORD on email (sayefe8121@sofiarae.com). Status: Success.',
      reportType: 'signIn'
    },
    {
      date: '2021-11-18T18:07:01.560000',
      remarks: 'User signed out. Status: Success.',
      reportType: 'signOut'
    },
    {
      date: '2020-11-18T18:07:01.560000',
      remarks: 'Sign-in attempted using PASSWORD on email (sayefe8121@sofiarae.com). Status: Success.',
      reportType: 'signIn'
    }
  ]

  const [data, setData] = useState(AllData)
  const [status, setStatus] = useState('all')
  const theme = useTheme()

  useEffect(() => {
    const filterData = () => {
      switch (status) {
        case 'signIn':
          setData(AllData.filter((d) => d.reportType === 'signIn'))
          break
        case 'signOut':
          setData(AllData.filter((d) => d.reportType === 'signOut'))
          break
        default:
          setData(AllData)
      }
    }

    setTimeout(filterData, 0)
  }, [status])

  const changeTableStyle = () =>
    createMuiTheme({
      palette: theme.palette,
      overrides: {
        MUIDataTableBodyCell: {
          root: {
            color: theme.palette.primary.main
          }
        },
        MUIDataTableHeadCell: {
          root: {
            marginLeft: 'auto',
            borderTop: `1px solid ${theme.palette.grey[300]}`,
            backgroundColor: '#f7f8fb !important',
            color: theme.palette.primary.main,
            fontSize: '14px',
            fontWeight: 600
          }
        },
        MuiTableRow: {
          root: {
            '&:nth-child(even)': {
              backgroundColor: '#fafbfe !important'
            },
            '&:nth-child(odd)': {
              backgroundColor: '#ffffff !important'
            }
          },
          hover: {
            '&:hover': {
              backgroundColor: 'rgba(122, 139, 237, 0.1)!important'
            }
          }
        },
        MuiPaper: {
          root: {
            border: `1px solid ${theme.palette.grey[300]}`
          }
        },
        MuiToolbar: {
          root: {
            position: 'static',
            paddingLeft: '15px !important'
          }
        },
        MuiTableCell: {
          paddingCheckbox: {
            display: 'none'
          },
          root: {
            '&:not(:nth-last-child(1))': {
              borderRight: `1px solid ${theme.palette.grey[300]}`
            },
            padding: '14px !important'
          },
          head: {
            padding: '10px !important'
          },
          footer: {
            border: 0
          }
        },
        MUIDataTablePagination: {
          tableCellContainer: {
            paddingTop: '0 !important',
            paddingBottom: '0 !important'
          }
        }
      }
    })

  const options = {
    rowsPerPage: 6,
    rowsPerPageOptions: [6, 10, 20, 50],
    jumpToPage: true,
    pagination: true,
    elevation: 0,
    filter: false,
    selectableRows: 'none',
    filterType: 'multiselect',
    responsive: 'simple',
    sort: true,
    print: false,
    search: true,
    viewColumns: false,
    download: false,
    customSearchRender: debounceSearchRender(100),
    customToolbar: () => (
      <Box display='inline' ml={2}>
        <IconButton variant='contained'>
          <Sync />
        </IconButton>
      </Box>
    )
  }

  const columns = [
    {
      name: 'date',
      label: 'Date',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (d) => (
          <>
            <Typography variant='subtitle2' color='textPrimary'>
              {moment(d).format('ll')}
            </Typography>
            <Typography variant='caption' color='textSecondary'>
              {moment(d).format('LTS')}
            </Typography>
          </>
        )
      }
    },
    {
      name: 'remarks',
      label: 'Remarks',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <Typography variant='body2' color={'textPrimary'}>
            {value}
          </Typography>
        )
      }
    },
    {
      name: 'reportType',
      label: 'Report Type',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (val) => <StatusTag value={val} />
      }
    }
  ]

  return (
    <Grid container justifyContent='center'>
      <Grid item xs={11}>
        <Box display='flex' my={4} alignItems='flex-end'>
          <DatePicker /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Typography variant='caption' color='textSecondary' gutterBottom>
            View all Activity Logs here!
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={11}>
        <Box my={1} mt={-5} display='flex' justifyContent='flex-end' alignItems='flex-end' style={{ gap: 20 }}>
          <Typography variant='caption' color='textSecondary'>
            {' '}
            &#9783; &nbsp; Filter By:{' '}
          </Typography>
          <CustomTextField
            variant='outlined'
            size='small'
            style={{ width: 200 }}
            select
            label='Report Type'
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <MenuItem dense={true} value='all'>
              All
            </MenuItem>
            <MenuItem dense={true} value='signIn'>
              Sign In
            </MenuItem>
            <MenuItem dense={true} value='signOut'>
              Sign Out
            </MenuItem>
          </CustomTextField>
        </Box>
      </Grid>
      <Grid item xs={12} sm={11}>
        <MuiThemeProvider theme={changeTableStyle()}>
          <MUIDataTable
            title={
              <Box display='flex'>
                <HistoryRounded />
                <Typography variant='inherit'>&nbsp;&nbsp;Activity Logs</Typography>
              </Box>
            }
            data={data}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => ({
  createData: state.ui.createData,
  inviteeData: state.ui.inviteData
})

const mapDispatchToProps = {
  // setCreateData: UiActions.setCreateData,
  // setInviteeData: UiActions.setInviteData,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Logs))
