import {
  Backdrop,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  InputLabel,
  makeStyles,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { CloseRounded, CreateRounded } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import { UiActions } from 'globalUI'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer - 1,
    color: '#fff'
  },
  button: {
    textTransform: 'none'
  },
  darkText: {
    marginTop: theme.spacing(1),
    color: theme.palette.grey[700]
  }
}))

const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: `0`
      }
    }
  }
})(TextField)

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#f7f8fb',
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.grey[300]}`,
    fontSize: '1rem'
  },
  body: {
    border: `1px solid ${theme.palette.grey[300]}`
  }
}))(TableCell)

const PurchaseData = [
  { item: 'Aadhaar Transactions', name: 'aadhaarTransaction' },
  { item: 'Virtual Sign  Transactions', name: 'virtualSignTransaction' },
  { item: 'DSC Transactions', name: 'dscTransaction' },
  { item: 'Doc Signer Transactions', name: 'docTransaction' },
  { item: 'Automated Sign Transactions', name: 'autpmatedSignTransaction' },
  { item: 'Subscription Months', name: 'subscriptionMonts' }
]

const StampData = [
  { id: '01', stampSeries: '01', state: 'Haryana', denomination: '100' },
  { id: '02', stampSeries: '02', state: 'Punjab', denomination: '300' },
  { id: '03', stampSeries: '03', state: 'Haryana', denomination: '500' }
]

const Purchase = () => {
  const classes = useStyles()

  const [successAlert, setSuccessAlert] = useState(false)
  const [billingDrawer, setBillingDrawer] = useState(false)
  const [isError, setIsError] = useState(null)

  const { handleSubmit, watch, control, clearErrors, getValues, setValue, setError, errors } = useForm({
    defaultValues: {},
    mode: 'onBlur'
  })

  const onSubmit = (data) => {
    const isEmpty = Object.values(data.order).every((item) => item === '')
    if (isEmpty) {
      setIsError('Create atleast one request')
    } else {
      // console.log(data);
      setSuccessAlert(true)
    }
  }

  const onError = (err) => {
    // console.log(err);
    if (err?.billingInfo) {
      setBillingDrawer(true)
      setIsError('Please update billing info first.')
    } else {
      setIsError('Some error occured')
    }
  }

  return (
    <>
      <Snackbar open={successAlert} autoHideDuration={3000} onClose={() => setSuccessAlert(false)}>
        <Alert onClose={() => setSuccessAlert(false)} severity='success'>
          Purchased successfully.
        </Alert>
      </Snackbar>
      <Snackbar open={!!isError} autoHideDuration={3000} onClose={() => setIsError(null)}>
        <Alert onClose={() => setIsError(null)} severity='error' style={{ minWidth: 300 }}>
          {isError}
        </Alert>
      </Snackbar>
      <Backdrop className={classes.backdrop} open={billingDrawer} onClick={() => setBillingDrawer(false)} />
      <Drawer
        anchor='right'
        variant='persistent'
        classes={{ paperAnchorDockedRight: 'no-border-drawer' }}
        open={billingDrawer}
        onClose={() => setBillingDrawer(false)}
      >
        <Box width={500} role='presentation'>
          <Box
            position='sticky'
            top={0}
            zIndex={10}
            display='flex'
            py={1}
            px={2}
            justifyContent='space-between'
            alignItems='center'
            bgcolor='primary.main'
            color='primary.contrastText'
          >
            <Typography variant='h5'>Billing Information</Typography>
            <IconButton onClick={() => setBillingDrawer(false)} style={{ color: 'white' }}>
              <CloseRounded />
            </IconButton>
          </Box>
          <Divider />
          <Box p={2} display='flex' flexDirection='column' style={{ gap: 10 }}>
            <InputLabel className={classes.darkText} htmlFor='name'>
              Name
            </InputLabel>
            <Controller
              render={({ onChange, onBlur, value, ref }) => (
                <TextField
                  size='small'
                  variant='outlined'
                  fullWidth
                  onBlur={onBlur}
                  id='name'
                  autoComplete='name'
                  error={!!errors?.billingInfo?.name}
                  onChange={(e) => onChange(e.target.value)}
                  value={value}
                  inputRef={ref}
                />
              )}
              control={control}
              name='billingInfo.name'
              rules={{ required: true }}
              defaultValue={''}
            />
            <InputLabel className={classes.darkText} htmlFor='companyName'>
              Company Name <small>(Optional)</small>
            </InputLabel>
            <Controller
              render={({ onChange, onBlur, value, ref }) => (
                <TextField
                  size='small'
                  variant='outlined'
                  fullWidth
                  id='companyName'
                  autoComplete='organization'
                  error={!!errors?.billingInfo?.companyName}
                  onBlur={onBlur}
                  onChange={(e) => onChange(e.target.value)}
                  value={value}
                  inputRef={ref}
                />
              )}
              control={control}
              name='billingInfo.companyName'
              defaultValue={''}
            />
            <InputLabel className={classes.darkText} htmlFor='billingAddress'>
              Billing Address
            </InputLabel>
            <Controller
              render={({ onChange, onBlur, value, ref }) => (
                <TextField
                  size='small'
                  variant='outlined'
                  fullWidth
                  id='billingAddress'
                  error={!!errors?.billingInfo?.billingAddress}
                  onBlur={onBlur}
                  onChange={(e) => onChange(e.target.value)}
                  value={value}
                  inputRef={ref}
                  multiline
                  rows={3}
                />
              )}
              control={control}
              name='billingInfo.billingAddress'
              rules={{ required: true }}
              defaultValue={''}
            />
            <InputLabel className={classes.darkText} htmlFor='state'>
              State
            </InputLabel>
            <Controller
              render={({ onChange, onBlur, value, ref }) => (
                <TextField
                  size='small'
                  variant='outlined'
                  fullWidth
                  id='state'
                  error={!!errors?.billingInfo?.state}
                  onBlur={onBlur}
                  onChange={(e) => onChange(e.target.value)}
                  value={value}
                  inputRef={ref}
                />
              )}
              control={control}
              name='billingInfo.state'
              rules={{ required: true }}
              defaultValue={''}
            />
            <InputLabel className={classes.darkText} htmlFor='gstNumber'>
              GST number
            </InputLabel>
            <Controller
              render={({ onChange, onBlur, value, ref }) => (
                <TextField
                  size='small'
                  variant='outlined'
                  fullWidth
                  id='gstNumber'
                  autoComplete='off'
                  error={!!errors?.billingInfo?.gstNumber}
                  onBlur={onBlur}
                  onChange={(e) => onChange(e.target.value)}
                  value={value}
                  inputRef={ref}
                />
              )}
              control={control}
              name='billingInfo.gstNumber'
              rules={{ required: true }}
              defaultValue={''}
            />
            <InputLabel className={classes.darkText} htmlFor='panNumber'>
              PAN number
            </InputLabel>
            <Controller
              render={({ onChange, onBlur, value, ref }) => (
                <TextField
                  size='small'
                  variant='outlined'
                  fullWidth
                  id='panNumber'
                  autoComplete='off'
                  error={!!errors?.billingInfo?.panNumber}
                  onBlur={onBlur}
                  onChange={(e) => onChange(e.target.value)}
                  value={value}
                  inputRef={ref}
                />
              )}
              control={control}
              name='billingInfo.panNumber'
              rules={{ required: true }}
              defaultValue={''}
            />
          </Box>
        </Box>
      </Drawer>
      <Box mt={2} display='flex' justifyContent='space-between'>
        <Button className={classes.button} size='large' color='primary' endIcon={<CreateRounded />} onClick={() => setBillingDrawer(true)}>
          Billing Information &nbsp;
        </Button>
        <Box mt={1} mr={2} display='flex' alignItems='center'>
          <Typography variant='subtitle1'>Plan: &nbsp;&nbsp;&nbsp;</Typography>
          <Typography variant='body2'>Personal (Monthly)</Typography>
        </Box>
      </Box>
      <Divider light={true} />
      <Box my={3}>
        <TableContainer>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <StyledTableCell>Item</StyledTableCell>
                <StyledTableCell>Details</StyledTableCell>
                <StyledTableCell>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {PurchaseData.map((row) => (
                <TableRow key={row.name}>
                  <StyledTableCell component='th' scope='row'>
                    {row.item} <br />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Box display='flex' alignItems='center'>
                      <InputLabel htmlFor={row.name} shrink={true}>
                        Quantity &nbsp;&nbsp;
                      </InputLabel>
                      <Controller
                        render={({ onChange, onBlur, value, ref }) => (
                          <CustomTextField
                            size='small'
                            margin='dense'
                            variant='outlined'
                            type='number'
                            id={row.name}
                            onBlur={onBlur}
                            onChange={(e) => onChange(e.target.value)}
                            value={value}
                            inputRef={ref}
                            onKeyDown={(evt) => ['-', '+', 'e', 'E', '.'].includes(evt.key) && evt.preventDefault()}
                            InputProps={{ inputProps: { min: 1 } }}
                          />
                        )}
                        control={control}
                        name={`order.${row.name}`}
                        defaultValue={''}
                      />
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Button
                      size='small'
                      variant='outlined'
                      style={{ textTransform: 'none', fontSize: 12 }}
                      onClick={() => setValue(`order.${row.name}`, '')}
                    >
                      Clear
                    </Button>
                  </StyledTableCell>
                </TableRow>
              ))}

              {StampData.map((row) => (
                <TableRow key={row.id}>
                  <StyledTableCell component='th' scope='row'>
                    <Typography component='span' variant='subtitle2'>
                      Stamp Series:{' '}
                    </Typography>
                    <Typography variant='caption'> {row.stampSeries}</Typography> <br />
                    <Typography component='span' variant='subtitle2'>
                      State:{' '}
                    </Typography>
                    <Typography variant='caption'> {row.state}</Typography> <br />
                    <Typography component='span' variant='subtitle2'>
                      Denomination:{' '}
                    </Typography>
                    <Typography variant='caption'> {row.denomination}</Typography> <br />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Box display='flex' alignItems='center'>
                      <InputLabel htmlFor={row.id} shrink={true}>
                        Quantity &nbsp;&nbsp;
                      </InputLabel>
                      <Controller
                        render={({ onChange, onBlur, value, ref }) => (
                          <CustomTextField
                            size='small'
                            margin='dense'
                            variant='outlined'
                            type='number'
                            id={row.id}
                            onBlur={onBlur}
                            onChange={(e) => onChange(e.target.value)}
                            value={value}
                            inputRef={ref}
                            onKeyDown={(evt) => ['-', '+', 'e', 'E', '.'].includes(evt.key) && evt.preventDefault()}
                            InputProps={{ inputProps: { min: 1 } }}
                          />
                        )}
                        control={control}
                        name={`order.stampSeries_${row.id}`}
                        defaultValue={''}
                      />
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Button
                      size='small'
                      variant='outlined'
                      style={{ textTransform: 'none', fontSize: 12 }}
                      onClick={() => setValue(`order.stampSeries_${row.id}`, '')}
                    >
                      Clear
                    </Button>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box display='flex' justifyContent='flex-end'>
        <Button variant='contained' color='primary' className={classes.button} onClick={handleSubmit(onSubmit, onError)}>
          Create Request
        </Button>
      </Box>
    </>
  )
}

const mapStateToProps = (state) => ({
  createData: state.ui.createData,
  inviteeData: state.ui.inviteData
})

const mapDispatchToProps = {
  setCreateData: UiActions.setCreateData,
  setInviteeData: UiActions.setInviteData
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Purchase))
