import { IS_LOADER, SUCCESS_MODAL_STATE } from 'auth'
import {
    API_USAGE_FAILURE,
    CANCEL_CHANGE_PLAN,
    CANCEL_CHANGE_PLAN_FAILURE,
    CANCEL_SUBSCRIPTION,
    CANCEL_SUBSCRIPTION_FAILURE,
    CHANGE_PLAN,
    CHANGE_PLAN_FAILURE,
    CHECKOUT_DETAIL_FAILURE,
    CURRENT_PLAN_FAILURE, ESTIMATE_REQ_COST_PRICE, ESTIMATE_REQ_COST_PRICE_FAILURE,
    FIRST_POPUP,
    PLANDETAIL_FAILURE,
    PROFILE_DETAIL_FAILURE,
    REMOVE_CANCEL_SCHEDULE,
    REMOVE_CANCEL_SCHEDULE_FAILURE
} from 'getDetails'
import {
  BILLING_DETAIL,
  CHECKOUT_DETAIL,
  CLIENTS_LIST,
  CLIENT_DETAILS,
  INITIAL_CHECKOUT,
  PLANDETAIL,
  PLAN_ID,
  AUTH_LOG,
  AUTH_LOG_FAILURE,
  BILLING_DETAIL_FAILURE,
  PROFILE_DETAIL,
  CURRENT_PLAN,
  API_USAGE
} from './action-types'
import {
    getClientsListCall,
    getClientDetailsCall,
    getPlanListDetails,
    checkOutPlan,
    getBillingDetail,
    getApiUsage,
    getClientInfo,
    getAuthLogs,
    getCurrentPlan,
    cancelSubscription,
    changePlanAPI,
    cancelScheduleChangePlanAPI,
    removeCancelScheduleAPI, estimatePriceApi
} from './manager'

export function getClientsList() {
  return (dispatch) =>
    getClientsListCall()
      .then((result) => {
        dispatch({
          type: CLIENTS_LIST,
          payload: result.data
        })
      })
      .catch((e) => {
        // console.log("error");
      })
}

export function getClientDetails(data) {
  return (dispatch) =>
    getClientDetailsCall(data)
      .then((result) => {
        dispatch({
          type: CLIENT_DETAILS,
          payload: result.data
        })
      })
      .catch((e) => {
        // console.log(e);
      })
}

export function getPlanList() {
  return (dispatch) => {
    // console.log("Called");
    dispatch({
      type: IS_LOADER,
      payload: false
    })
    getPlanListDetails()
      .then((result) => {
        dispatch(setPlanDetails(result.data))
        dispatch({
          type: IS_LOADER,
          payload: true
        })
      })
      .catch((e) => {
        dispatch({
          type: PLANDETAIL_FAILURE,
          payload: e.response
        })
        dispatch({
          type: IS_LOADER,
          payload: true
        })
      })
  }
}

export function checkOut(id) {
  // console.log("ID", id);
  return (dispatch) => {
    checkOutPlan(id)
      .then((result) => dispatch(setCheckout(result)))
      .catch((e) =>
        dispatch({
          type: CHECKOUT_DETAIL_FAILURE,
          payload: e.response
        })
      )
  }
}
export const setPlanDetails = (payload) => {
  return {
    type: PLANDETAIL,
    payload: payload.data
  }
}
export const setCheckout = (payload) => {
  // console.log("Action", payload);
  return {
    type: CHECKOUT_DETAIL,
    payload: payload.data
  }
}

export function setInitialCheckout(payload) {
  // console.log("Initial Payload", payload);
  return (dispatch) =>
    dispatch({
      type: INITIAL_CHECKOUT,
      payload
    })
}
export function getPlanID(payload) {
  // console.log("Chexckout id", payload);
  return (dispatch) => {
    dispatch({
      type: PLAN_ID,
      payload
    })
  }
}
export function getBilling() {
  return (dispatch) => {
    dispatch({
      type: IS_LOADER,
      payload: false
    })
    getBillingDetail()
      .then((result) => {
        dispatch({
          type: BILLING_DETAIL,
          payload: result.data
        })
        dispatch({
          type: IS_LOADER,
          payload: true
        })
      })
      .catch((e) => {
        dispatch({
          type: BILLING_DETAIL_FAILURE,
          payload: e.response
        })
        dispatch({
          type: IS_LOADER,
          payload: true
        })
      })
  }
}

export function getAuthLogData() {
  return (dispatch) => {
    getAuthLogs()
      .then((result) => {
        // console.log("AUTHLOG", result);
        dispatch({
          type: AUTH_LOG,
          payload: result.data
        })
      })
      .catch((e) =>
        dispatch({
          type: AUTH_LOG_FAILURE,
          payload: e.response
        })
      )
  }
}

export const getProfileDetails = () => (dispatch) => {
  getClientInfo()
    .then((result) =>
      dispatch({
        type: PROFILE_DETAIL,
        payload: result.data
      })
    )
    .catch((e) =>
      dispatch({
        type: PROFILE_DETAIL_FAILURE,
        payload: e.response
      })
    )
}

export const getCurrentPlanDetails = (a) => (dispatch) => {
  getCurrentPlan()
    .then((result) => {
      dispatch({
        type: CURRENT_PLAN,
        payload: result.data
      })
    })
    .catch((e) =>
      dispatch({
        type: CURRENT_PLAN_FAILURE,
        payload: e.response
      })
    )
}
export const getApiUsageDetail = () => (dispatch) => {
  getApiUsage()
    .then((result) =>
      dispatch({
        type: API_USAGE,
        payload: result.data
      })
    )
    .catch((e) =>
      dispatch({
        type: API_USAGE_FAILURE,
        payload: e.response
      })
    )
}

export const cancelSubscriptionPlan = () => (dispatch) => {
  cancelSubscription()
    .then((result) =>
      dispatch({
        type: CANCEL_SUBSCRIPTION,
        payload: result.data
      })
    )
    .catch((e) =>
      dispatch({
        type: CANCEL_SUBSCRIPTION_FAILURE,
        payload: e.response
      })
    )
}

export function firstShow(value) {
  return (dispatch) => {
    dispatch({
      type: FIRST_POPUP,
      payload: value
    })
  }
}

export function changePlan(value) {
  return (dispatch) => {
    dispatch({
      type: IS_LOADER,
      payload: false
    })
    changePlanAPI(value)
      .then((res) => {
        dispatch({
          type: CHANGE_PLAN,
          payload: res.data
        })
        dispatch({
          type: IS_LOADER,
          payload: true
        })
      })
      .catch((err) => {
        dispatch({
          type: CHANGE_PLAN_FAILURE,
          payload: err.response
        })
        dispatch({
          type: IS_LOADER,
          payload: true
        })
      })
  }
}

export function removeCancelSchedule(value) {
  return (dispatch) => {
    dispatch({
      type: IS_LOADER,
      payload: false
    })
    removeCancelScheduleAPI(value)
      .then((res) => {
        dispatch({
          type: REMOVE_CANCEL_SCHEDULE,
          payload: res.data
        })
        dispatch({
          type: IS_LOADER,
          payload: true
        })
      })
      .catch((err) => {
        dispatch({
          type: REMOVE_CANCEL_SCHEDULE_FAILURE,
          payload: err.response
        })
        dispatch({
          type: IS_LOADER,
          payload: true
        })
      })
  }
}

export function removeChangePlan(value) {
  return (dispatch) => {
    dispatch({
      type: IS_LOADER,
      payload: false
    })
    cancelScheduleChangePlanAPI(value)
      .then((res) => {
        dispatch({
          type: CANCEL_CHANGE_PLAN,
          payload: res.data
        })
        dispatch({
          type: IS_LOADER,
          payload: true
        })
      })
      .catch((err) => {
        dispatch({
          type: CANCEL_CHANGE_PLAN_FAILURE,
          payload: err.response
        })
        dispatch({
          type: IS_LOADER,
          payload: true
        })
      })
  }
}


export function getReqCostPrice(value){
    return dispatch=>{
        estimatePriceApi(value).then(res=>{
            dispatch({
                type:ESTIMATE_REQ_COST_PRICE,
                payload:res.data
            })
        }).catch(err=>{
            dispatch({
                type:ESTIMATE_REQ_COST_PRICE_FAILURE,
                payload:err.response
            })
            // console.log(err.response)
        })
    }
}

