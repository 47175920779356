import { Box, Button, Collapse, Divider, Grid, makeStyles, Paper, Typography } from '@material-ui/core'
import { SettingsRounded } from '@material-ui/icons'
import React, { useState } from 'react'
import API from './apiTab'
import DocumentTab from './document'
import SignatureTab from './eSignature'

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(4),
    textTransform: 'none'
  },
  navButton: {
    marginRight: theme.spacing(3),
    borderRadius: 0,
    textTransform: 'none',
    borderBottom: `2px solid #fff `,
    color: theme.palette.grey[700],
    '&:hover': {
      color: theme.palette.grey[900],
      borderBottom: `2px solid ${theme.palette.grey[700]}`
    }
  },
  navButtonActive: {
    marginRight: theme.spacing(3),
    borderRadius: 0,
    textTransform: 'none',
    color: theme.palette.primary.main,
    borderBottom: `2px solid ${theme.palette.primary.main}`
  }
}))

const getTab = (idx) => {
  return (
    <>
      <Collapse in={idx === 0}>
        <API />
      </Collapse>

      <Collapse in={idx === 1}>
        <SignatureTab />
      </Collapse>

      <Collapse in={idx === 2}>
        <DocumentTab />
      </Collapse>
    </>
  )
}

export default function Settings() {
  const classes = useStyles()
  const [activeTab, setActiveTab] = useState(0)

  return (
    <Grid container justifyContent='center'>
      <Grid item xs={11}>
        <Box my={4} display='flex' alignItems='flex-end'>
          <SettingsRounded /> &nbsp;&nbsp;&nbsp;
          <Typography variant='caption'>Manage your Personal Settings here!</Typography>
        </Box>
      </Grid>
      <Grid
        component={Paper}
        variant='outlined'
        container
        item
        xs={11}
        justifyContent='center'
        style={{ padding: '20px 0', marginBottom: 10 }}
      >
        <Grid item xs={11}>
          <Button className={activeTab === 0 ? classes.navButtonActive : classes.navButton} onClick={() => setActiveTab(0)}>
            API
          </Button>
          <Button className={activeTab === 1 ? classes.navButtonActive : classes.navButton} onClick={() => setActiveTab(1)}>
            eSignature
          </Button>
          <Button className={activeTab === 2 ? classes.navButtonActive : classes.navButton} onClick={() => setActiveTab(2)}>
            Document
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={11}>
          {getTab(activeTab)}
        </Grid>
      </Grid>
    </Grid>
  )
}
