import axios from 'axios'

export const axiosinstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 15000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
})

export const demoAPIRequest = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 15000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
})

export function setDemoAPIToken(token) {
  if (token) {
    // console.log("Revoke Auth", token);
    demoAPIRequest.defaults.headers.common['Authorization'] = `Bearer ${token}`
    return true
  } else {
    delete axios.defaults.headers.common['Authorization']
    return false
  }
}

export default function setAuthorizationToken(token) {
  if (token) {
    // console.log("Revoke Auth", token);
    axiosinstance.defaults.headers.common['Authorization'] = `Bearer ${token}`
    return true
  } else {
    delete axios.defaults.headers.common['Authorization']
    return false
  }
}
