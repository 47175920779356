import { Box, Grid, MenuItem, TextField, Typography } from '@material-ui/core'
import { DescriptionOutlined } from '@material-ui/icons'
import { withStyles } from '@material-ui/styles'
import { clientActions } from 'getDetails'
import { UiActions } from 'globalUI'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import DatePicker from 'views/components/date-picker'
import DocTable from 'views/components/docTable'

const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: `0`
      }
    },
    '& .MuiInputBase-root': {
      fontSize: '0.85rem'
    },
    '& .MuiFormLabel-root': {
      fontSize: '0.9rem'
    }
  }
})(TextField)

function MyDocuments(props) {
  // const AllData = [
  //     { id: "341512", name: "My_document_for_eSign.pdf", docType: "draft", eSignStatus: "actionRequired", folder: null, owner: "Me", createdAt: "2020-06-18T18:07:01.560000" },
  //     { id: "341513", name: "My_document_for_eSign.pdf", docType: "received", eSignStatus: "failed", folder: "Imp. ", owner: "Me", createdAt: "2020-01-15T18:08:01.560000" },
  //     { id: "341515", name: "My_document_for_eSign.pdf", docType: "expired", eSignStatus: "waitingForOthers", folder: "forEmpl", owner: "Me", createdAt: "2020-12-18T18:07:01.560000" },
  //     { id: "341514", name: "My_document_for_eSign.pdf", docType: "sent", eSignStatus: "actionRequired", folder: null, owner: "Me", createdAt: "2019-11-28T18:09:01.560000" },
  //     { id: "341516", name: "My_document_for_eSign.pdf", docType: "completed", eSignStatus: "failed", folder: "office", owner: "Me", createdAt: "2020-07-15T18:07:01.560000" },
  //     { id: "341517", name: "My_document_for_eSign.pdf", docType: "sent", eSignStatus: "actionRequired", folder: null, owner: "Me", createdAt: "2010-02-18T18:07:01.560000" },
  //     { id: "341519", name: "My_document_for_eSign.pdf", docType: "completed", eSignStatus: "waitingForOthers", folder: "office", owner: "Me", createdAt: "2020-10-18T20:07:01.560000" },
  //     { id: "341518", name: "My_document_for_eSign.pdf", docType: "draft", eSignStatus: "failed", folder: null, owner: "Me", createdAt: "2020-11-18T18:07:01.560000" },
  //     { id: "341510", name: "My_document_for_eSign.pdf", docType: "sent", eSignStatus: "actionRequired", folder: "office", owner: "Me", createdAt: "2020-11-18T18:02:01.560000" },
  //     { id: "341521", name: "My_document_for_eSign.pdf", docType: "draft", eSignStatus: "expiringSoon", folder: "forEmpl", owner: "Me", createdAt: "2020-11-12T18:28:01.560000" },
  //     { id: "341522", name: "My_document_for_eSign.pdf", docType: "draft", eSignStatus: "waitingForOthers", folder: null, owner: "Me", createdAt: "2020-11-18T04:07:01.560000" },
  //     { id: "341523", name: "My_document_for_eSign.pdf", docType: "expired", eSignStatus: "failed", folder: "forEmpl", owner: "Me", createdAt: "2020-01-23T18:07:01.560000" },
  //     { id: "341524", name: "My_document_for_eSign.pdf", docType: "received", eSignStatus: "waitingForOthers", folder: null, owner: "Me", createdAt: "2020-06-18T18:02:01.560000" },
  //     { id: "341525", name: "My_document_for_eSign.pdf", docType: "completed", eSignStatus: "failed", folder: "office", owner: "Me", createdAt: "2020-11-11T18:07:01.560000" },
  // ];

  const [docData, setDocData] = useState([])
  const [AllData, setAllData] = useState([])

  const [status, setStatus] = useState('all')
  const [docType, setDocType] = useState('all')

  useEffect(() => {
    props.getClientsList()
  }, [])

  useEffect(() => {
    if (props.clientsList) {
      setAllData(props.clientsList)
      setDocData(props.clientsList)
    }
  }, [props])

  useEffect(() => {
    const filterData = () => {
      if (AllData) {
        switch (status) {
          case 'all':
            setDocData(AllData.filter((d) => d.doctype === docType || docType === 'all'))
            break
          case 'actionRequired':
            setDocData(AllData.filter((d) => d.status === 'actionRequired' && (d.doctype === docType || docType === 'all')))
            break
          case 'waitingForOthers':
            setDocData(AllData.filter((d) => d.status === 'waitingForOthers' && (d.doctype === docType || docType === 'all')))
            break
          case 'expiringSoon':
            setDocData(AllData.filter((d) => d.status === 'expiringSoon' && (d.doctype === docType || docType === 'all')))
            break
          case 'failed':
            setDocData(AllData.filter((d) => d.status === 'failed' && (d.doctype === docType || docType === 'all')))
            break
          default:
            setDocData(AllData)
        }

        switch (docType) {
          case 'all':
            setDocData(AllData.filter((d) => d.status === status || status === 'all'))
            break
          case 'draft':
            setDocData(AllData.filter((d) => d.docType === 'draft' && (d.status === status || status === 'all')))
            break
          case 'sent':
            setDocData(AllData.filter((d) => d.docType === 'sent' && (d.status === status || status === 'all')))
            break
          case 'received':
            setDocData(AllData.filter((d) => d.docType === 'received' && (d.status === status || status === 'all')))
            break
          case 'completed':
            setDocData(AllData.filter((d) => d.docType === 'completed' && (d.status === status || status === 'all')))
            break
          case 'expired':
            setDocData(AllData.filter((d) => d.docType === 'expired' && (d.status === status || status === 'all')))
            break
          default:
            setDocData(AllData)
        }
      }
    }
    filterData()
  }, [status, docType])

  return (
    <div>
      <Grid container justifyContent='center' alignItems='center'>
        <Grid item xs={11}>
          <Box mt={2}>
            <Typography variant='caption'>&nbsp; Keep Track of all your documents here!</Typography>
          </Box>
        </Grid>
        <Grid container item xs={11}>
          <Grid item xs={12}>
            <Box my={2.5}>
              <DatePicker />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box my={1} mt={-3} display='flex' justifyContent='flex-end' alignItems='flex-end' style={{ gap: 20 }}>
              <Typography variant='caption' color='textSecondary'>
                {' '}
                &#9783; &nbsp; Filter By:{' '}
              </Typography>
              <CustomTextField
                variant='outlined'
                size='small'
                style={{ width: 200 }}
                select
                label='eSign Status'
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <MenuItem dense={true} value='all'>
                  All
                </MenuItem>
                <MenuItem dense={true} value='actionRequired'>
                  Action Required
                </MenuItem>
                <MenuItem dense={true} value='waitingForOthers'>
                  Wating for Others
                </MenuItem>
                <MenuItem dense={true} value='expiringSoon'>
                  Expiring Soon
                </MenuItem>
                <MenuItem dense={true} value='failed'>
                  Failed
                </MenuItem>
              </CustomTextField>
              <CustomTextField
                variant='outlined'
                size='small'
                style={{ width: 200 }}
                select
                label='Document Type'
                value={docType}
                onChange={(e) => setDocType(e.target.value)}
              >
                <MenuItem dense={true} value='all'>
                  All
                </MenuItem>
                <MenuItem dense={true} value='draft'>
                  Draft
                </MenuItem>
                <MenuItem dense={true} value='sent'>
                  Sent
                </MenuItem>
                <MenuItem dense={true} value='received'>
                  Received
                </MenuItem>
                <MenuItem dense={true} value='completed'>
                  Completed
                </MenuItem>
                <MenuItem dense={true} value='expired'>
                  Expired
                </MenuItem>
              </CustomTextField>
            </Box>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <DocTable
                title={
                  <Box display='flex' alignItems='center'>
                    <DescriptionOutlined fontSize='small' />
                    <Typography variant='inherit'>&nbsp;&nbsp;&nbsp; eSign Documents</Typography>
                  </Box>
                }
                data={docData}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state) => ({
  createData: state.ui.createData,
  inviteeData: state.ui.inviteData,
  clientsList: state.clientData.clientsList
})

const mapDispatchToProps = {
  setCreateData: UiActions.setCreateData,
  setInviteeData: UiActions.setInviteData,
  getClientsList: clientActions.getClientsList
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyDocuments))
