import {
  Box,
  Button,
  Collapse,
  IconButton,
  Grid,
  Avatar,
  InputLabel,
  Link as LinkUI,
  makeStyles,
  Paper,
  Snackbar,
  TextField,
  createMuiTheme,
  MuiThemeProvider,
  Typography,
    CircularProgress,
    LinearProgress,
  Divider
} from '@material-ui/core'
import { Home } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import LoginSVG from 'assets/login_creative.svg'
import { authActions } from 'auth'
import { clientActions } from 'getDetails'

import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory, withRouter } from 'react-router'
import { Link, useLocation } from 'react-router-dom'

import Logo from '../../../assets/logo.png'
import CodeIcon from '@material-ui/icons/Code'
import GoogleLogin from 'assets/google_login.png'
import { googleLogin } from '../../../auth/actions'
import BackgroundHexagon from 'assets/background-hexagon.svg'
import LoginGraphic from 'assets/home-graphic.svg'





const useStyles = makeStyles((theme) => ({
  googleLogin: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ccc',
    boxShadow: ' 0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)',
    display: 'flex',
    '&:hover': {
      backgroundColor: theme.palette.background.paper
    }
  },
  rootLinear: {
    width: "100%",

    margin: "0 auto",
    "& > * + *": {
      marginLeft: theme.spacing(1),
    },
  },
  fancyBg: {
    backgroundColor: '#edf2fe'
    // backgroundImage: " linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%)",
    // backgroundImage: " linear-gradient(135deg, #000046 0%, #1CB5E0 100%)",
    // can add custom background for glassmorphism
    // backgroundImage: `url("${BackgroundHexagon}")`,
    // backgroundSize: "cover",
    // backgroundRepeat: "no-repeat",
    // backgroundPosition: "center",
  },
  buttons: {
    // width: '60%',
    padding: '10px 32px',
    fontFamily: 'AvetaBold',
    // backgroundImage:"linear-gradient(135deg, #000046 0%, #1CB5E0 100%)"
    backgroundColor: theme.palette.primary.main,
    borderRadius: '8px',
    fontWeight: '600',
    boxShadow: '0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)',
    "&.MuiButton-containedPrimary:hover":{
      color:"#221c53",
      backgroundColor:"#fff",
      border:"1px solid #221c53",
      fontWeight:"600"

    }
  },
  homeBtn: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    margin: 10
  },
  paperRoot: {
    position: 'relative',
    width: '70%',
    minHeight: '80vh',
    margin: '20px 0px',
    display: 'flex',
    // padding: "4rem",
    // backgroundColor: "rgba( 255, 255, 255, 0.4 )",
    boxShadow: '0 8px 32px 0 rgba( 31, 38, 135, 0.37 )',
    backdropFilter: 'blur(10px)',
    '-webkit-backdrop-filter': 'blur( 10.0px )',
    borderRadius: '10px',
    // border: "1px solid rgba( 255, 255, 255, 0.18 )",
    zIndex: 100
  },
  onboardRoot: {
    width: '40%',
    minHeight: '70vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '4rem',
    marginLeft: '-4px'
  },
  tokenInput: {
    width: '100%',
    margin: '10px 0 25px',
    borderRadius: 5,
    backgroundColor: '#ffffff66'
  },
  cowinBold: {
    color: '#3C4AE4'
  },
  resendButton: {
    marginLeft: "16px",

    "& .MuiButton-root":{
      "& ..Mui-disabled":{
        color:"#221c53",
      }

    }
  },
  linearGradient: {
    width: '100%',
    position: 'absolute',
    height: '100%'
  },
  whiteColor: {
    color: '#000'
  },
  leftBox: {
    position: 'relative',
    background: theme.palette.primary.light,
    borderBottomLeftRadius: '10px',
    borderTopLeftRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

const theme = createMuiTheme({
  palette: {

    action: {

      disabled: '#221c53'
    }
  },
  overrides: {
    MuiInputBase: {
      root: {
        padding: '6px 0'
      }
    }
  }
})

function Login(props) {
  const { getProductList } = props
  const classes = useStyles()
  const loginInput = useRef(null)
  const otpInput = useRef(null)
  const [error, setError] = useState(null)
  const [show, setShow] = useState(false);
  const [number, setNumber] = useState(30);
  const [resendNumber, setResendNumber] = useState(30);
  const [otpTimer, setOtpTimer] = useState(false);
  const search = useLocation().search
  // const name = new URLSearchParams(search).get('plan_id')
  // props.getPlanId(name)
const [successMessage,setSuccessMessage]=useState()

  const history = useHistory()

  useEffect(() => {
    setError(props.errorMsg)
    setShow(false)
    setOtpTimer(false)
    clearInterval(counterId)
    setResendNumber(30)
  }, [props.errorMsg])




  useEffect(() => {
    if (props.onboardRequired && props.otpVerified) {
      history.push('get-onboard')
    }
  }, [props.onboardRequired, props.otpVerified])

  let resendCounter;
  let otpCounter;
  const handleSendOTP = async () => {
    const { sentOtp,clearOtp } = props
    const inp = loginInput.current.value
    const validEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(inp)
    // const validPhone = /^\+?([0-9]{2})\)?[-. ]?([0-9]{5})[-. ]?([0-9]{5})$/.test(inp) || /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(inp)

    let data = validEmail
      ? { email: inp,
          product:"scrapingpass"
        }
      : //   : validPhone
        //   ? { mobile: inp }
        null
    data ? await sentOtp(data) : setError('Please add a valid Email')
    setShow(true)


    setOtpTimer(true)

    return
  }


  const handleResendOTP = async (resend) => {
    props.clearOtp()




    const { sentOtp } = props;

    await sentOtp({
      email:loginInput.current.value,
      product:"scrapingpass"
    })

    setOtpTimer(true)

    return;
  };
  const [counterId,setCounterId]=useState()


  const handleSubmitOTP = async () => {
    const { submitOtp } = props
    const otp = otpInput.current.value

    await submitOtp({
      otp,
      client_id: props.clientId,
      product:"scrapingpass"
    })

    return
  }

  const onGoogleLogin = () => {
    window.auth2.grantOfflineAccess().then(signInCallback)
  }

  function signInCallback(authResult) {
    let out = ''

    if (authResult['code']) {
      out = authResult['code']
    } else {
      out = 'Error, check console'
    }
    sendGoogleLogin(out)
  }
  const sendGoogleLogin = async (code) => {
    const { googleLogin } = props
    let payload = {
      code,
      product:"scrapingpass"
    }
    await googleLogin(payload)
    return
  }
function startTimer(){
  resendCounter = setInterval(() => {

      setResendNumber((prevState) => prevState<=10?`0${prevState-1}`:prevState - 1);

    setCounterId(resendCounter)

  }, 1000);
}

  useEffect(()=>{
    console.log("OtpSent",props.otpSent)

      if(props.otpSent){
console.log("OtpSent",props.otpSent)
        setSuccessMessage('OTP has sent in your mail, Please check your mail.')
        startTimer()
        // setOtpTimer(true)
      }

  },[props.otpSent])

  useEffect(() => {

    console.log("Otp Timer true",)
    console.log("resendNumber",resendNumber)


    if (resendNumber === '00') {
      console.log("resendNumber",resendNumber)
      clearInterval(counterId)
      resendCounter=0
      setResendNumber(30)

      setOtpTimer(false);

      // setResendNumber(30)

    }


  }, [resendNumber]);
  useEffect(() => {
    if (loginInput.current.value === "") {
      // dispatch({
      //   type: SET_CLIENT_ID,
      //   payload: "",
      // });

      props.clearOtp()
    }
    return ()=>{
      const name = new URLSearchParams(search).get('plan_id')
      props.getPlanId(name)
    }
  }, []);
  return (
    <Box display='flex' justifyContent='center' alignItems='center' minHeight='100vh' className={classes.fancyBg}>
      <Snackbar open={!!error} autoHideDuration={3000} onClose={() => {setError(null)
        props.clearError()

      }}>
        <Alert onClose={() => {setError(null)
          props.clearError()
        }} severity='error'>
          {error}
        </Alert>
      </Snackbar>

      <Snackbar open={!!successMessage} autoHideDuration={3000} onClose={() => {setSuccessMessage(null)

        props.clearOtp()

      }}>
        <Alert onClose={() => {setSuccessMessage(null)
          props.clearError()
        }} severity='success'>
          {successMessage}
        </Alert>
      </Snackbar>

      <Paper className={classes.paperRoot}>
        {/* <IconButton
          color="secondary"
          className={classes.homeBtn}
          component={Link}
          to="/"
        >
          <Home />
        </IconButton> */}
        <Box
          display='flex'
          justifyContent='center'
          width='50%'
          // p={1}
          className={classes.leftBox}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 40
            }}
          >
            <Typography
              className={classes.whiteColor}
              variant={'h5'}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              Welcome to
              <img src={Logo} style={{ padding: 4, height: '3.2rem' }} />
            </Typography>

            <br />
            <Divider
              style={{
                height: '3px',
                background: '#000',
                width: '30%'
              }}
            />
            <br />
            <Typography variant={'subtitle1'}>Scrape any website and extract data easily</Typography>
            <br />
            <img style={{ width: '70%' }} src={'https://scrapingpass.com/wp-content/uploads/2020/10/Asset-8@4x-1.png'} />
            {/*<Typography variant={"h5"}*/}
            {/*            style={{fontWeight:"bold"}}*/}
            {/*            align={"center"}>Scrape any website*/}
            {/*  and extract data*/}
            {/*  easily with us.</Typography><br/>*/}
            {/*<Typography variant={"body1"} align={"center"}>*/}
            {/*  We provide you with powerful web scraping and robotic process automation (RPA) tools to make your work easy for you. Check out our services below.*/}
            {/*</Typography>*/}
            <br />
          </Box>
        </Box>
<Box width={"50%"}>
  {props.sendOtpLoader && (
      <div className={classes.rootLinear}>
        <LinearProgress />
      </div>
  )}
  <Box
      width='100%'
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      py={5}
      px={4}
  >



    <Typography
        variant='h4'
        style={{
          marginBottom: 40,
          display: 'flex',
          alignItems: 'center',
          fontWeight: '600',
          letterSpacing: '0px',
          color: '#000000',
          opacity: '1'
        }}
    >
      {/*Welcome to <b style={{ marginLeft: 10 }}>No</b>*/}
      {/*<CodeIcon style={{ fontSize: "60" }} color={"primary"} />*/}
      {/*<b>Pdf</b>*/}
      Sign in to Account
    </Typography>

    <form onSubmit={(e) => e.preventDefault()}>
      <InputLabel htmlFor='phoneNo' style={{ fontWeight: '400', color: '#000' }}>
        Enter your Email
      </InputLabel>
      <MuiThemeProvider theme={theme}>
        <TextField
            size='small'
            variant='outlined'
            id='phoneNo'
            className={classes.tokenInput}
            inputRef={loginInput}
            error={!!error}
            placeholder={'ex:abc@nocodepdf.com'}
            autoComplete='on'
            required
        />
        <Collapse in={!!props.clientId} unmountOnExit>
          <InputLabel htmlFor='otp'>Enter OTP</InputLabel>
          <TextField
              size='small'
              variant='outlined'
              id='otp'
              className={classes.tokenInput}
              autoComplete='off'
              inputRef={otpInput}
              error={!!error}
              autoFocus
              required
          />
          {/* <Typography variant="caption" style={{ color: "#444" }}>
                Otp sent! Check your Inbox
              </Typography> */}
        </Collapse>
      </MuiThemeProvider>
      <br />
      {!props.clientId ? (
          <Grid container justifyContent='center'>
            <Button
                variant='contained'
                size={'large'}
                color='primary'
                onClick={handleSendOTP}
                className={classes.buttons}
                disabled={props.sendOtpLoader}
                type='submit'
            >
              Proceed
            </Button>
          </Grid>
      ) : (
          <Grid container justifyContent='center'>
            <Grid item>
              <Button
                  variant='contained'
                  size={'large'}
                  disabled={props.sendOtpLoader}
                  // fullWidth
                  color='primary'
                  className={classes.buttons}
                  onClick={handleSubmitOTP}
                  type='submit'
              >
                Log in
              </Button>
            </Grid>


            <Grid item>
              <MuiThemeProvider theme={otpTimer?theme:''}>
              <Button
                  variant="text"
                  size={"large"}
                  color="primary"
                  onClick={() => {
                    let resend = true;
                    handleResendOTP(resend);
                  }}
                  disabled={otpTimer}
                  className={classes.resendButton}

                  // type="submit"
              >
                {otpTimer?`00:${resendNumber}`: `Resend OTP`}
              </Button>
              </MuiThemeProvider>
              {/*{otpTimer &&(<CircularProgress value={resendNumber} />)}*/}
            </Grid>


          </Grid>
      )}

      <Grid container justifyContent='center' alignItems='center' style={{ margin: '2px 0' }}>
        <Grid
            item
            style={{
              width: '100px',
              height: '2px',
              background: '#111111bf'
            }}
        ></Grid>
        <Grid item>
          <Typography
              style={{
                marginTop: 10,
                marginBottom: 10,
                padding: '0px 4px'
              }}
              align={'center'}
          >
            OR
          </Typography>
        </Grid>
        <Grid
            item
            style={{
              width: '100px',
              height: '2px',
              background: '#111111bf'
            }}
        ></Grid>
      </Grid>

      <Grid container justifyContent='center'>
        <Button
            variant={'contained'}
            className={classes.googleLogin}
            // fullWidth
            style={{
              width: '100%',
              padding: '10px 32px',
              fontFamily: 'AvertaRegular',
              fontWeight: '600'
            }}
            onClick={onGoogleLogin}
            startIcon={<Avatar src={GoogleLogin} />}
        >
          Sign in with Google
        </Button>
      </Grid>
      <br />
      <Grid container justifyContent='center'>
        <Typography variant='caption' style={{ color: '#444' }}>
          <em>
            By clicking proceed account I agree to &nbsp;
            <LinkUI href={"https://scrapingpass.com/terms-of-service/"} target={"_blank"}>Terms & Conditions</LinkUI>
          </em>
        </Typography>
      </Grid>
    </form>
  </Box>
</Box>

      </Paper>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  authenticated: state.auth.authenticated,
  id: state.auth.id,
  otpSent: state.auth.otpSent,
  statusCode: state.auth.statusCode,
  otpVerified: state.auth.otpVerified,
  onboardRequired: state.auth.onboardRequired,
  phone_number: state.auth.phone_number,
  email: state.auth.email,
  sendOtpLoader:state.auth.sendOtpLoader,
  errorMsg: state.auth.errorMsg,
  fullname: state.auth.fullname,
  clientId: state.auth.clientId
})

const mapDispatchToProps = {
  sentOtp: authActions.sentOtp,
  submitOtp: authActions.submitOtp,
  googleLogin: authActions.googleLogin,
  getPlanId: clientActions.getPlanID,
  checkOut: clientActions.checkOut,
  clearError:authActions.clearError,
  clearOtp:authActions.clearOtp,
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))
