import {
  Box,
  Button,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useTheme
} from '@material-ui/core'
import { brown } from '@material-ui/core/colors'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import {
  ArrowDropDownCircleOutlined,
  AssignmentOutlined,
  AttachFileRounded,
  DeleteOutlineRounded,
  FileCopyOutlined,
  FolderOpenRounded,
  GetAppRounded,
  LaunchRounded,
  MoreHorizRounded,
  PermIdentity,
  PersonRounded,
  TrackChangesRounded,
  VisibilityOutlined
} from '@material-ui/icons'
import { withStyles } from '@material-ui/styles'
import moment from 'moment'
import MUIDataTable from 'mui-datatables'
import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { debounceSearchRender } from '../DebounceSearchRender'
import StatusTag from '../StatusTags'

const styles = {
  actionBtns: {
    display: 'flex',
    justifyContent: 'flex-start',
    textTransform: 'none'
  }
}

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    padding: '0.5rem'
  }
}))(Tooltip)

const ActionDrawer = ({ id }) => (
  <List dense>
    <ListItem button component={Link} to={`/documents/${id}`}>
      <ListItemIcon>
        <VisibilityOutlined fontSize='small' />
      </ListItemIcon>
      <ListItemText primary='Preview' />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentOutlined fontSize='small' />
      </ListItemIcon>
      <ListItemText primary='Details' />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <LaunchRounded fontSize='small' />
      </ListItemIcon>
      <ListItemText primary='Open' />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <GetAppRounded fontSize='small' />
      </ListItemIcon>
      <ListItemText primary='Download' />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <DeleteOutlineRounded fontSize='small' />
      </ListItemIcon>
      <ListItemText primary='Delete' />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AttachFileRounded fontSize='small' />
      </ListItemIcon>
      <ListItemText primary='Attachments' />
    </ListItem>
  </List>
)

export default function DocTable(props) {
  const theme = useTheme()

  const columns = [
    {
      name: 'client_id',
      label: '#',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (d, tableMeta) => {
          return (
            <Box display='flex' justifyContent='center'>
              <Typography variant='subtitle2' color='textPrimary'>
                {tableMeta.rowIndex + 1}.{' '}
              </Typography>
            </Box>
          )
        },
        customHeadLabelRender: (cm) => (
          <Box display='flex' justifyContent='center'>
            {cm.label}
          </Box>
        )
      }
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (d) => (
          <>
            <Typography variant='body1' color='textPrimary'>
              {d}
            </Typography>
          </>
        )
      }
    },
    {
      name: 'status',
      label: 'eSign Status',
      options: {
        sort: false,
        filter: true,
        customBodyRender: (d) => (
          <>
            <StatusTag value={d} />
          </>
        )
      }
    },
    {
      name: 'status',
      label: 'Document Type',
      options: {
        sort: false,
        filter: true,
        customBodyRender: (d) => (
          <>
            <StatusTag value={d} />
          </>
        )
      }
    },
    {
      name: 'client_id',
      label: 'ID',
      options: {
        sort: false,
        filter: false,
        display: false,
        customBodyRender: (d) => (
          <Box display='flex' alignItems='center'>
            <FileCopyOutlined fontSize='small' style={{ color: `${brown[500]}` }} /> &nbsp;&nbsp;&nbsp;
            <Typography variant='body2' color='textPrimary'>
              {d}
            </Typography>
          </Box>
        )
      }
    },
    {
      name: 'folder',
      label: 'Folder',
      options: {
        sort: false,
        filter: true,
        customBodyRender: (d) => (
          <>
            <Typography variant='body2' color='textPrimary'>
              {d || '-'}
            </Typography>
          </>
        ),
        customHeadLabelRender: (cm) => (
          <Box display='flex' alignItems='center'>
            <FolderOpenRounded style={{ fontSize: '1rem' }} />
            &nbsp;
            {cm.label}
          </Box>
        )
      }
    },
    {
      name: 'owner',
      label: 'Owner',
      options: {
        sort: false,
        filter: true,
        display: false,
        customBodyRender: (d) => (
          <>
            <Typography variant='body2' color='textPrimary'>
              {d}
            </Typography>
          </>
        ),
        customHeadLabelRender: (cm) => (
          <Box display='flex' alignItems='center'>
            <PersonRounded style={{ fontSize: '1rem' }} />
            &nbsp;
            {cm.label}
          </Box>
        )
      }
    },
    {
      name: 'created_at',
      label: 'Created At',
      options: {
        sort: true,
        filter: false,
        searchable: false,
        sortThirdClickReset: true,
        customBodyRender: (d) => (
          <LightTooltip placement='left' enterDelay={300} title={moment(d).format('dddd')}>
            <Box>
              <Typography variant='subtitle2' component='h4' color='textPrimary' gutterBottom>
                <>
                  {moment(d).format('MMM DD')},{' '}
                  <small>
                    <b>{moment(d).format('YYYY')}</b>
                  </small>
                </>
              </Typography>
              <Typography variant='caption' color='textSecondary'>
                {moment(d).format('LTS')}
              </Typography>
            </Box>
          </LightTooltip>
        )
      }
    },
    {
      name: 'client_id',
      label: 'Actions',
      options: {
        sort: false,
        filter: false,
        searchable: false,
        customBodyRender: (value) => (
          <Box display='flex' justifyContent='center'>
            <MuiThemeProvider theme={TooltipTheme()}>
              <Tooltip
                arrow={true}
                placement='left'
                enterTouchDelay={0}
                leaveTouchDelay={3000}
                leaveDelay={100}
                interactive
                title={<ActionDrawer id={value} />}
              >
                <IconButton color='primary' size='medium'>
                  <MoreHorizRounded />
                </IconButton>
              </Tooltip>
            </MuiThemeProvider>
          </Box>
        ),
        customHeadLabelRender: (cm) => (
          <Box display='flex' justifyContent='center'>
            {cm.label}
          </Box>
        )
      }
    }
  ]

  const changeTableStyle = () =>
    createMuiTheme({
      palette: theme.palette,
      overrides: {
        MuiPaper: {
          root: {
            border: `1px solid ${theme.palette.grey[300]}`
          },
          rounded: {
            borderRadius: 0
          }
        },
        MUIDataTableBodyCell: {
          root: {
            color: theme.palette.primary.main,
            fontSize: '14px'
          }
        },
        MUIDataTableHeadCell: {
          root: {
            marginLeft: 'auto',
            borderTop: `1px solid ${theme.palette.grey[300]}`,
            backgroundColor: '#f7f8fb !important',
            color: theme.palette.primary.main,
            fontSize: '14px',
            fontWeight: 600
          },
          sortAction: {
            color: theme.palette.primary.main,
            fontWeight: 700
          }
        },
        MuiTableRow: {
          root: {
            '&:nth-child(even)': {
              backgroundColor: '#fafbfe !important'
            },
            '&:nth-child(odd)': {
              backgroundColor: '#ffffff !important'
            }
          },
          hover: {
            '&:hover': {
              backgroundColor: 'rgba(122, 139, 237, 0.1)!important'
            }
          }
        },
        MuiToolbar: {
          root: {
            position: 'static',
            paddingLeft: '15px !important'
          }
        },
        MuiTableCell: {
          root: {
            '&:not(:nth-last-child(1))': {
              // borderRight: `1px solid ${ theme.palette.grey[300] }`,
            },
            '&:first-child': {
              borderRight: `1px solid ${theme.palette.grey[300]}`
            },
            padding: '10px !important'
          },
          head: {
            padding: '10px !important'
          },
          footer: {
            '&:first-child': {
              borderRight: 0
            }
          }
        },
        MUIDataTablePagination: {
          tableCellContainer: {
            paddingTop: '0 !important',
            paddingBottom: '0 !important',
            border: 0
          }
        }
      }
    })

  const TooltipTheme = () =>
    createMuiTheme({
      palette: theme.palette,
      overrides: {
        MuiTooltip: {
          tooltip: {
            backgroundColor: '#fff',
            color: '#222',
            boxShadow: 'rgba(62, 62, 119, 0.25) 0px 13px 27px -5px,rgba(0, 0, 0, 0.3) 0px 8px 16px -8px !important'
          },
          arrow: {
            color: '#fff'
          }
        }
      }
    })

  const history = useHistory()

  const options = {
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 20, 50],
    jumpToPage: true,
    pagination: true,
    elevation: 0,
    filter: false,
    selectableRows: 'none',
    filterType: 'multiselect',
    responsive: 'simple',
    sort: true,
    print: false,
    search: true,
    viewColumns: true,
    download: false,
    customSearchRender: debounceSearchRender(100)
  }

  return (
    <>
      <MuiThemeProvider theme={changeTableStyle()}>
        <MUIDataTable title={props.title} data={props.data} columns={columns} options={options} />
      </MuiThemeProvider>
    </>
  )
}
