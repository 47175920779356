import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { authActions } from 'auth';
import { withStyles } from '@material-ui/core/styles';
import Background from './assets/main-background.png'
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Paper } from "@material-ui/core";
import { Link } from "react-router-dom";
import classNames from 'classnames'

const styles = {
    root: {
        position: 'relative',
    },
    topbar: {
        display: 'flex',
        justifyContent: 'space-between',
        position: "absolute", width: "100%",
        fontSize: "2rem", color: "white",
        padding: "15px 40px",
        backgroundColor: "rgba( 255, 255, 255, 0.25 )",
        boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
        backdropFilter: "blur(10px)",
        border: "1px solid rgba( 255, 255, 255, 0.18 )",
    },
    contentDiv: {
        backgroundImage: `url(${Background})`,
        height: "100vh",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        textAlign: "center",
        display: "flex",
        // height:"80%",
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    contentWrapper: {
        marginTop: "100px",
        marginBottom: "20px",
        color: "white",
        lineHeight: "2rem"
    },
    stepsDiv: {},
    stepsInnerWrapper: {
        textAlign: "center",
        color: "#002e6e",
        padding: "40px 20px",
        position: "relative",
        backgroundColor: "rgba( 255, 255, 255, 0.5 )",
        boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
        backdropFilter: "blur(10px)",
        "-webkit-backdrop-filter": "blur( 10.0px )",
        borderRadius: "10px",
        border: "1px solid rgba( 255, 255, 255, 0.18 )",
    },
    stepsBoldText: {
        fontWeight: "600",
        fontStyle: "normal"
    },

    stepsWrapper: {
        display: "flex",
        alignItems: "center",
        position: "relative"
    },

    stepsTitle: {
        fontSize: "22px",
        fontWeight: "600",
        marginTop: "30px",
        marginBottom: "30px",
        color: "#002e6e",
    },
    stepsDesc: {
        color: "#002e6e",
        fontSize: "16px",
    },
    stepLabel: {
        padding: "25px 30px",
        borderRadius: "50%",
        width: "80px",
        height: "80px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#002e6e",
        color: "#fff",
        margin: "0px auto",
        fontSize: "32px"
    },
    outlineLabel: {
        padding: "25px 30px",
        borderRadius: "50%",
        width: "80px",
        height: "80px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "#ebeaff",
        border: "4px solid #002e6e",
        color: "#002e6e",
        margin: "0px auto",
        fontSize: "32px"
    },
    step: {
        flex: 1,
        zIndex: 1
    },
    hr: {
        position: "absolute",
        top: "38px",
        width: "70%",
        left: "16%",
        background: "#002e6e",
        height: "4px"
    }
};

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            submitted: false
        };
    }

    getStepIcon = (count) => (
        <div className={this.props.classes.outlineLabel}>
            {"0" + count}
        </div>
    )


    render() {
        const { username, password, submitted } = this.state;

        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <div className={classes.topbar}>
                    <p>SignE</p>
                    <Button variant="contained" color="secondary"
                        onClick={() => this.props.history.push('/sign-in')}>Get Started</Button>
                </div>
                <div className={classNames(classes.contentDiv, "contentDiv")}>
                    <Grid container className={classes.stepsDiv}>
                        <Grid item md={2} />

                        {/*step component*/}
                        <Grid item md={8}>
                            <div className={classes.contentWrapper}>
                                <p >The easiest & fastest way to sign a </p>
                                <b>Non-Disclosure Agreement</b>
                                <br />
                                <br />
                                <em >Prepare • Sign • Share</em>
                            </div>
                            <br />
                            <br />
                            <Paper className={classes.stepsInnerWrapper}>
                                <p className={"stepsItalicText"}>
                                    3 easy steps to <span className={classes.stepsBoldText}>Sign an Agreement</span>
                                </p>
                                <br />
                                <br />
                                <div className={classes.stepsWrapper}>
                                    <div className={classes.step}>
                                        {this.getStepIcon(1)}
                                        <p className={classes.stepsTitle}>Prepare <br />your agreement</p>
                                        <p className={classes.stepsDesc}>
                                            Answer a few simple<br /> questions to make<br /> your agreement
                                    </p>
                                    </div>
                                    <div className={classes.step}>
                                        {this.getStepIcon(2)}
                                        <p className={classes.stepsTitle}>Save, Print <br />
                                        and Share</p>
                                        <p className={classes.stepsDesc}>
                                            Save progress and finish <br />on any device; download<br /> & print anytime
                                    </p>
                                    </div>
                                    <div className={classes.step}>
                                        {this.getStepIcon(3)}
                                        <p className={classes.stepsTitle}>Sign and <br /> Legalize</p>
                                        <p className={classes.stepsDesc}>
                                            Securely sign online<br /> and invite others<br /> to sign
                                    </p>
                                    </div>
                                    <hr className={classes.hr} />
                                </div>
                            </Paper>
                        </Grid>


                        <Grid item md={2} />
                    </Grid>
                </div>
            </div>
        );
    }
}

Home.propTypes = {

};


//=====================================
//  CONNECT
//-------------------------------------

const mapDispatchToProps = {

};


export default withRouter(
    connect(
        null,
        mapDispatchToProps)(withStyles(styles)(
            (Home)))
);

