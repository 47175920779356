import setAuthorizationToken from '../utils/axios-instance'
import { getDetailsSuccessfully, refreshTokenFailed, refreshTokenSuccessfully, signOutSuccess } from './actions'
import { refreshTokenCall, getDetailsCall } from './manager'
import {getClientInfo} from "../getDetails/manager";

//  Verify Auth / presit login info on reload
export const initAuth = async (dispatch) => {
  try {
    const responseRefreshToken = await refreshTokenCall()
// console.log("RefreshToken",responseRefreshToken)
    dispatch(refreshTokenSuccessfully(responseRefreshToken.data))
    setAuthorizationToken(responseRefreshToken.data.data.access_token)

    const responseClientDetails = await getClientInfo()
    // console.log("Response",responseClientDetails)
    dispatch(getDetailsSuccessfully(responseClientDetails.data))

    return responseClientDetails
  } catch (e) {
      // console.log("Error",e.response)
    dispatch(refreshTokenFailed(e.response))
    dispatch(signOutSuccess())
    return e
  }

  // return dispatch(refreshToken())
  //     .then(result =>{
  //         dispatch(refreshTokenSuccessfully(result.data))
  //         setAuthorizationToken(result.data.data.access_token);
  //         dispatch(getDetails())
  //     })
  //     .catch(e=>{
  //         dispatch(refreshTokenFailed(e.response))
  //         dispatch(signOutSuccess())
  //     })
}
