import React, { useState, useEffect } from 'react'
import {
  AppBar,
  Box,
  Button,
  Card,
  Grid,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
  Link,
  Container,
  Toolbar,
  CssBaseline,
  Divider,
  makeStyles,
  IconButton
} from '@material-ui/core'
import { Clear } from '@material-ui/icons'

import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { checkOut, getCurrentPlanDetails, getPlanID, getPlanList, setInitialCheckout } from 'getDetails/actions'
import {clearCheckout} from "../../../auth/actions";

const useStyles = makeStyles((theme) => ({
  heroContent: {
    padding: theme.spacing(3, 0)
  },
  cardHeader: {
    // marginLeft: ".25rem",
    // marginRight: ".25rem",
    clipPath: 'ellipse(50% 94% at 50% 7%)',
    backgroundColor: '#221c53'
  },
  cardPricing: {
    paddingTop: '10px'
  },

  basicDetails: {
    padding: '20px 0px'
  },
  box: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: '8px'
  },
  card: {
    // padding: "10px",
    borderRadius: '10px',
    // boxShadow:
    //   "rgba(0, 0, 0, 0.16) 0px 3px 10px, rgba(0, 0, 0, 0.23) 0px 3px 10px",
    border: '0.5px solid rgb(34 28 83 / 37%)'
  },
  button: {
    borderRadius: '14px',
    textTransform: 'capitalize'
  },
  title: {
    fontWeight: '500',
    textAlign: 'center',
    color: '#fff',
    fontSize: '20px'
  },
  heading: {
    fontSize: '3.4rem',
    fontWeight: '500',
    fontFamily: 'Montserrat',
    color: 'rgb(60 58 58 / 87%)'
  },
  buyBtn: {
    textTransform: 'capitalize',
    borderRadius: '8px',
    boxShadow: '0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)',
    fontWeight: '500',
    "&:hover":{
      background:"#fff",
      color: theme.palette.primary.main,
      fontWeight:"600"
    }
  },

}))





function PricingContent(props) {
  // console.log("Props", props);
  const dispatch = useDispatch()
  const { planDetail, currentPlan, errormsg, statusCode } = useSelector((state) => state.clientData)
  // console.log("PlanDetail", planDetail);
  // const subscription = true;
  const classes = useStyles()
  const [errorMesg, setErrorMesg] = useState('')
  function priceManipulate(str) {
    let a = str.split('.')
    let b = a?.[0]
    return b
  }

  function stringManipulate(str) {
    let a = str.split(' ')
    let b = a?.[1]
    let c = a?.[2]
    let string = `${b}`

    // console.log(string);
    return string
  }

  function buyPlan(planId) {
    // console.log("Plan Id", planId);
    props.handleClose()
    dispatch(getPlanID(planId))
    dispatch(clearCheckout())
    dispatch(checkOut(planId))
    dispatch(setInitialCheckout(false))
  }
  const cancelPlan = () => {}
  // console.log("PlanDetail", planDetail);
  // console.log("Current", currentPlan);

  const buyPlanArray = planDetail?.plans?.filter((plan) => (currentPlan?.data?.plan_id === plan?.id ? plan : null))
  // console.log("BuyPlan", buyPlanArray);

  useEffect(() => {
    dispatch(getPlanList())
    dispatch(getCurrentPlanDetails())
  }, [dispatch])
  useEffect(() => {
    setErrorMesg(errormsg)
  }, [errormsg])
  return (
    <Container component='main'>
      <Grid container justifyContent='center'>
        <Grid container item justifyContent='center'>
          <Typography variant='h6' color='textPrimary' style={{ fontSize: '29px', fontWeight: '600' }}>
            Top Up Plan
          </Typography>
        </Grid>
      </Grid>
      <Box style={{ position: 'relative' }}>
        <IconButton
          color='secondary'
          variant='contained'
          style={{ position: 'absolute', right: '-29px', bottom: '11px' }}
          onClick={() => props.handleClose()}
        >
          <Clear style={{ color: '#000', fontWeight: '700' }} />
        </IconButton>
      </Box>

      <Grid container spacing={3} style={{ padding: '1rem',justifyContent:planDetail?.plans?.length < 2?'center':'flex-start' }} >
        {planDetail?.plans?.map((plan) => (
          // Enterprise card is full width at sm breakpoint
          <Grid item key={plan?.id} xs={12} md={planDetail?.plans?.length > 2 ? 4 : 6} lg={planDetail?.plans?.length >2 ? 4 : 6} >
            <Card
              className={classes.card}
              style={{
                backgroundColor: `${
                  plan?.id === currentPlan?.data?.plan_id && currentPlan?.data?.cancelled === false ? '#3C4AE4' : 'rgb(239 239 239 / 35%)'
                }`,
                color: `${plan?.id === currentPlan?.data?.plan_id && currentPlan?.data?.cancelled === false ? '#fff' : '#111'}`
              }}
            >
              {/* {console.log(
                "Check",
                plan?.id === currentPlan?.data?.plan_id &&
                  currentPlan?.data?.cancelled === false
              )} */}
              <CardContent className={classes.cardHeader}>
                <div>
                  <Typography className={classes.title} variant='h4'>
                    {stringManipulate(plan?.description)}
                  </Typography>
                </div>
              </CardContent>

              <Grid container item className={classes.box}>
                <Typography
                  variant='subtitle2'
                  color={plan?.id === currentPlan?.data?.plan_id && currentPlan?.data?.cancelled === false ? '#ffff' : '#2471A3'}
                >
                  Credits
                </Typography>
                <Typography
                  variant='caption'
                  component='h5'
                  color={plan?.id === currentPlan?.data?.plan_id && currentPlan?.data?.cancelled === false ? '#ffff' : '#2471A3'}
                  style={{
                    fontSize: '14px',

                    fontWeight: '600'
                  }}
                >
                  {plan?.credits}
                </Typography>
              </Grid>

              {/* <CardContent
              // style={{
              //   backgroundColor: "transparent",
              //   userSelect: "auto",
              //   padding: "0px 20px",
              //   marginTop: "-18px",
              // }}
              >
                {/* <div className={classes.cardPricing}>
                  {/* <Typography
                    component="h2"
                    variant="h3"
                    align="center"
                    style={{
                      color: `${
                        plan?.id === currentPlan?.data?.plan_id &&
                        currentPlan?.data?.cancelled === false
                          ? "#ffff"
                          : "#3C4AE4"
                      }`,
                      fontSize: "2.3rem",
                      fontWeight: "500",
                    }}
                  >
                    $&nbsp;{plan?.price}
                  </Typography> */}
              {/* <Typography
                    variant="h6"
                    align="center"
                    style={{
                      color: `${
                        plan?.id === currentPlan?.data?.plan_id &&
                        currentPlan?.data?.cancelled === false
                          ? "#ffff"
                          : "rgb(78, 78, 78)"
                      }`,
                      marginTop: "-4px",
                    }}
                  >
                    {/* per {plan?.period} */}
              {/* </Typography> */}
              {/* </div> */}

              {/* <Grid container xs={12} className={classes.basicDetails}>
               
                  <Grid
                    container
                    item
                    className={classes.box}
                    style={{
                      backgroundColor: `${
                        plan?.id === currentPlan?.data?.plan_id &&
                        currentPlan?.data?.cancelled === false
                          ? "#21329080"
                          : "#ECF0F1"
                      }`,
                      justifyContent: "center",
                    }}
                  >
                    {/* <Typography
                        variant="subtitle2"
                        color={
                          plan?.id === currentPlan?.data?.plan_id
                            ? "#ffff"
                            : "#2471A3"
                        }
                      >
                        Description
                      </Typography> */}
              {/* <Typography
                      variant="caption"
                      component="h5"
                      align="center"
                      style={{
                        fontSize: "14px",

                        fontWeight: "600",
                      }}
                      color={
                        plan?.id === currentPlan?.data?.plan_id &&
                        currentPlan?.data?.cancelled === false
                          ? "#ffff"
                          : "#2471A3"
                      }
                    >
                      {plan?.description}
                    </Typography>
                  </Grid> */}
              {/* <Grid
                    container
                    item
                    className={classes.box}
                    style={{
                      backgroundColor: `${
                        plan?.id === currentPlan?.data?.plan_id &&
                        currentPlan?.data?.cancelled === false
                          ? "#21329080"
                          : "#ECF0F1"
                      }`,
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      color={
                        plan?.id === currentPlan?.data?.plan_id &&
                        currentPlan?.data?.cancelled === false
                          ? "#ffff"
                          : "#2471A3"
                      }
                    >
                      Product
                    </Typography>
                    <Typography
                      variant="caption"
                      component="h5"
                      style={{
                        fontSize: "14px",

                        fontWeight: "600",
                      }}
                      color={
                        plan?.id === currentPlan?.data?.plan_id &&
                        currentPlan?.data?.cancelled === false
                          ? "#ffff"
                          : "#2471A3"
                      }
                    >
                      {plan?.product}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    className={classes.box}
                    style={{
                      backgroundColor: `${
                        plan?.id === currentPlan?.data?.plan_id &&
                        currentPlan?.data?.cancelled === false
                          ? "#21329080"
                          : "#ECF0F1"
                      }`,
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      color={
                        plan?.id === currentPlan?.data?.plan_id &&
                        currentPlan?.data?.cancelled === false
                          ? "#ffff"
                          : "#2471A3"
                      }
                    >
                      Credits
                    </Typography>
                    <Typography
                      variant="caption"
                      component="h5"
                      color={
                        plan?.id === currentPlan?.data?.plan_id &&
                        currentPlan?.data?.cancelled === false
                          ? "#ffff"
                          : "#2471A3"
                      }
                      style={{
                        fontSize: "14px",

                        fontWeight: "600",
                      }}
                    >
                      {plan?.credits}
                    </Typography>
                  </Grid>
                </Grid>
                <ul> */}
              {/* {plan?.features?.map((line) => (
                    <Typography
                      component="li"
                      variant="subtitle1"
                      align="left"
                      key={line}
                      style={{
                        color: `${
                          plan?.id === currentPlan?.data?.plan_id &&
                          currentPlan?.data?.cancelled === false
                            ? "#ffff"
                            : "rgb(78 78 78)"
                        }`,
                        fontSize: "14px",
                        fontWeight: "600",
                      }}
                    >
                      {line}
                    </Typography>
                  ))}
                </ul> */}

              {/* <Divider
                  style={{
                    backgroundColor: `${
                      plan?.id === currentPlan?.data?.plan_id &&
                      currentPlan?.data?.cancelled === false
                        ? "#ffffff59"
                        : "lightgray"
                    }`,
                    marginTop: "10px",
                  }}
                />
              </CardContent> */}

              <div
                style={{
                  paddingLeft: '10px',
                  paddingTop: '5px',
                  paddingBottom: '5px'
                }}
              >
                <Typography variant='subtitle2' component='h1'>
                  {/* {tier.footerDetails} */}
                </Typography>
              </div>
              <CardActions style={{ display: 'flex', justifyContent: 'center' }}>
                {plan?.id === currentPlan?.data?.plan_id ? (
                  <>
                    {currentPlan?.data?.cancelled === true ? (
                      <>
                        {moment(moment().format('DD MMM YYYY')) < moment(moment(currentPlan?.data?.expiry).format('DD MMM YYYY')) ? (
                          <Button color='primary' variant='contained' className={classes.removeCancel}>
                            Remove Cancellation Schedule
                          </Button>
                        ) : (
                          <Button color='primary' variant='contained' className={classes.buyBtn} onClick={() => buyPlan(plan?.id)}>
                            Buy Plan for ${plan?.price}
                          </Button>
                        )}
                      </>
                    ) : (
                      <Button color='secondary' variant='contained' className={classes.cancelBtn}>
                        Cancel Plan
                      </Button>
                    )}
                  </>
                ) : (
                  <Button color='primary' variant='contained' className={classes.buyBtn} onClick={() => buyPlan(plan?.id)}>
                    Buy for ${priceManipulate(plan?.price)}
                  </Button>
                )}
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

export default PricingContent
